import React, { useEffect, useState } from 'react';

import CheckMarkFilled from '../../icons/CheckMarkFilled';
import { useConfig } from '../../providers/ConfigProvider';

import styles from './SuccessNotification.module.scss';

const SuccessNotification = ({ type }) => {
  const [text, setText] = useState(null);
  const config = useConfig();
  useEffect(() => {
    switch (type) {
      case 'app_coupon_applied':
        setText(
          `$${config.APPLICATION_DISCOUNT_COUPON_AMOUNT} coupon applied!`,
        );
    }
  }, [type]);

  return (
    <div className={styles.container}>
      <CheckMarkFilled color={'#04BF8D'} />
      <p>{text}</p>
    </div>
  );
};

export default SuccessNotification;
