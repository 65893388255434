// use this as a root container
export { default as PageContainer } from './TopContainer';
export { default as TopContainer } from './TopContainer';
// A page handles the main layout and footer
export { default as PageSimple } from './PageSimple';
export { default as PageSimpleCenter } from './PageSimpleCenter';
export { default as PageCardCenter } from './PageCardCenter';
export { default as MediumCardCenter } from './MediumCardCenter';
export { default as LargeCardCenter } from './LargeCardCenter';
export { default as Footer } from '../Footer';
export { default as LayoutTwoColumns } from './LayoutTwoColumns';
// Components are reusable UI elements shared across the app
import * as Components from './components';

export default { Components };
