import React, { memo } from 'react';
import PropTypes from 'prop-types';

const UloopLogo = (props) => (
  <svg {...props} viewBox="0 0 120 47">
    <path
      fill="#7E7E7E"
      d="M41.3 1.3C39.4 1.2 36.4.4 36 3c-.2.8 0 1.5.4 2 1.4 1.9 2.9-.2 3.6.8.5.6.3 1.3.3 2.3V20c0 1.5 0 2.8-.4 3.2-1 1.3-2.5-.5-3.6.9-.4.5-.5 1.3-.4 2 .5 3.2 4.2 1.6 7.4 1.6 2.5 0 6.1 1.6 6.6-1.6.2-.8 0-1.5-.3-2-1.3-2-3.2.7-3.4-1.5-.1-1.1-.2-9.7-.2-14.6V1.8c-.2-1.6-2.1-.4-4.7-.5zm39.3 16.8c0-3.9 1.5-5.9 4.2-5.9s4.1 1.9 4.1 5.9c0 3.8-1.4 5.8-4.2 5.8-2.6-.1-4.1-2-4.1-5.8zm-6.2.1c0 6.5 3.9 10.3 10.3 10.3 6.5 0 10.4-3.9 10.4-10.4 0-6.7-3.8-10.5-10.2-10.5-6.8 0-10.5 3.9-10.5 10.6zm-16.6-.1c0-3.9 1.5-5.9 4.2-5.9s4.1 1.9 4.1 5.9c0 3.8-1.4 5.8-4.2 5.8-2.7-.1-4.1-2-4.1-5.8zm-6.3.1c0 6.5 3.9 10.3 10.3 10.3 6.5 0 10.4-3.9 10.4-10.4 0-6.7-3.8-10.5-10.2-10.5-6.7 0-10.5 3.9-10.5 10.6zm54.8-.3c0-4 1.2-6.1 3.5-6.1 2.2 0 3.4 2 3.4 6 0 3.9-1.2 5.9-3.4 5.9-2.4.1-3.5-1.9-3.5-5.8zm-5-9.6c-2-.1-4.6-.8-5.1 1.6-.2.8 0 1.5.4 2 1.4 1.9 2.6-.2 3.3.7.5.9.3 1.4.3 2.4v11.9c0 1.5 0 2.8-.4 3.2-1 1.3-2.2-.6-3.4.9-.4.5-.5 1.3-.4 2 .5 3.2 4.2 1.7 7.4 1.6 2.5 0 6.9 1.7 7.5-1.5.2-.8 0-1.5-.3-1.9-1.3-2-4.2 1-4.5-1.2v-3.8c1.3 1.5 3.1 2.3 5.2 2.3 4.9 0 7.8-4 7.8-10.6 0-6.8-2.8-10.6-7.6-10.6-3 0-5.2 1.4-6.6 4V8.9c.1-2.1-1.7-.5-3.6-.6z"
    />
    <path
      fill="#1C4483"
      d="M92.9 35.4c.5-1 1.1-3.6-.1-4.3-1.2-.7-2.6.2-3.2 1.2-1.9 2.9-4.2 5.2-6.7 6.7-2.8 1.7-5.9 2.5-9 2.5-4.1.1-8.1-1.2-11.6-3.9-1.8-1.4-3.5-3.2-4.8-5.3-.5-1-1.9-1.9-3.2-1.2-1.2.6-.6 3.3-.1 4.3 1.2 2.7 3 4.9 5.2 6.6 1.8 1.4 3.9 2.5 6.1 3.2 2.7.9 5.7 1.4 8.6 1.3 5.1-.1 10.1-1.7 13.9-4.8 2.1-1.7 3.8-3.8 4.9-6.3M17.4 29c6.9 0 11.3-3.1 11.3-10.2 0-3.2.1-6.3.1-9.5 0-1.1-.1-1.7.5-2.4 1.2-1.5 2.9.6 4.3-1.1.5-.5.5-1.5.4-2.3-.5-2.9-4-2.2-6.3-1.8-.5.1-1.2.1-1.9.1H25c-.5 0-1.1 0-1.5-.1-1.9-.4-4.9-1.1-5.3 1.8-.2.8 0 1.8.4 2.3 1.1 1.5 2.1-.4 3 1.1.5.7.3 1.3.3 2.4v9.6c0 3.1-1.8 4.5-4.5 4.5s-4.5-1.5-4.5-4.6V9.3c0-1.1-.2-1.7.3-2.4.9-1.5 1.9.5 3-1.1.4-.5.5-1.5.4-2.3-.5-2.9-3.3-2.2-5.3-1.8-.4.1-1 .1-1.5.1H9c-.7 0-1.4 0-1.9-.1C4.8 1.4 1.3.7.8 3.5c-.2.8 0 1.8.4 2.3 1.4 1.7 3.2-.4 4.3 1.1.5.7.5 1.3.5 2.4v9.6C6.1 25.8 10.4 29 17.4 29z"
    />
  </svg>
);

UloopLogo.defaultProps = {
  width: 81,
  height: 32,
};

UloopLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(UloopLogo);
