import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

let RemoveAddAdd1 = (props) => (
  <SvgIcon {...props}>
    <g
      stroke={props.color}
      strokeWidth={props.strokeWidth || 1.25}
      fillOpacity="0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    >
      <path d="M11.5.5v22M22.5 11.5h-22" />
    </g>
  </SvgIcon>
);
RemoveAddAdd1 = memo(RemoveAddAdd1);
RemoveAddAdd1.displayName = 'RemoveAddAdd1';
RemoveAddAdd1.muiName = 'SvgIcon';

export default RemoveAddAdd1;
