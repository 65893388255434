import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import useOutsideClick from '@rooks/use-outside-click';
import cx from 'classnames';
import chunk from 'lodash/chunk';
import PropTypes from 'prop-types';

import basicUserProfile from '../../../graphql/basicUserProfile.graphql';
import ownerSettingsQuery from '../../../graphql/queries/ownerSettingsQuery.graphql';
import { useRenderOutside } from '../../../helpers/render-outside';
import MoreIcon from '../../../icons/BurgerNav';
import CloseIcon from '../../../icons/Close';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';
import Badge from '../../Badge';
import GlobalBanner from '../../Banners/GlobalBanner';
import PremiumSubscriptionModal from '../../PremiumSubscription';
import { MODAL_LOCATIONS } from '../../PremiumSubscription/constants';
import MenuOfferTag from '../MenuOfferTag/MenuOfferTag';

import styles from './MobileNavigation.module.scss';

const OWNER_ITEMS_PER_ROW = 4;

const MobileNavigation = ({
  location,
  isHidden,
  isOwner,
  className,
  unreadMessagesCount,
  ownerNavItems,
  renterNavItems,
  ownerDrawerItems,
  user,
  userDataLoaded,
}) => {
  const renderOutside = useRenderOutside();
  const containerRef = useRef();
  const history = useHistory();
  const config = useConfig();
  const [isOpen, setIsOpen] = useState(false);

  const isIOSChrome =
    /CriOS/i.test(navigator?.userAgent) &&
    /iphone|ipod|ipad/i.test(navigator?.userAgent);

  useOutsideClick(containerRef, () => {
    setIsOpen(false);
  });

  if (isHidden) {
    return null;
  }

  const navItems = isOwner ? ownerNavItems : renterNavItems;

  const renderNavItem = ({
    Icon,
    to,
    href,
    onClick,
    label,
    id,
    isActiveFilter,
    isInactiveFilter,
  }) => {
    let active = location.pathname.indexOf(isActiveFilter) !== -1;
    if (
      isInactiveFilter &&
      isInactiveFilter
        .split(',')
        .some((route) => location.pathname.indexOf(route) !== -1)
    ) {
      active = false;
    }
    let Element;
    const linkProps = {};

    if (to) {
      Element = Link;
      linkProps.to = to;
    } else if (href) {
      Element = 'a';
      linkProps.href = href;
      linkProps.target = '_blank';
    } else {
      Element = 'span';
    }

    return (
      <Element
        to={to}
        key={`mobile-nav-item-${id}`}
        className={cx(styles.navItem, {
          [styles.active]: active,
        })}
        onClick={() => {
          onClick && onClick();
          setIsOpen(false);
        }}
        {...linkProps}
      >
        {id === 'messages' ? (
          <Badge counter={unreadMessagesCount} className={styles.iconWrap}>
            <Icon />
          </Badge>
        ) : (
          <div className={styles.iconWrap}>
            <Icon />
          </div>
        )}
        <div className={styles.label}>{label}</div>
      </Element>
    );
  };

  return (
    <>
      {isOwner && <GlobalBanner />}
      <nav className={cx(styles.container, className)} ref={containerRef}>
        <div
          className={cx(styles.navBar, {
            [styles.active]: true,
            [styles.iosChrome]: isIOSChrome,
          })}
        >
          {navItems.map(renderNavItem)}
          {isOwner && (
            <div
              className={cx(styles.navItem, {
                [styles.active]: isOpen,
              })}
              onClick={() => {
                setIsOpen((state) => !state);
              }}
            >
              <div className={styles.iconWrap}>
                <CloseIcon
                  className={cx(styles.moreIcon, { [styles.hidden]: !isOpen })}
                />
                <MoreIcon
                  className={cx(styles.moreIcon, { [styles.hidden]: isOpen })}
                />
              </div>
              <div className={styles.label}>{isOpen ? 'Close' : 'More'}</div>
            </div>
          )}
        </div>
        {isOwner && (
          <>
            <div
              className={cx(styles.drawer, {
                [styles.open]: isOpen,
              })}
            >
              {chunk(ownerDrawerItems, OWNER_ITEMS_PER_ROW).map((row, i) => (
                <div className={styles.row} key={`mobile_nav_drawer_row_${i}`}>
                  {row.map(renderNavItem)}
                </div>
              ))}
              <MenuOfferTag
                createdAt={user?.created_at}
                userDataLoaded={userDataLoaded}
                isOwner={isOwner}
                isPremiumUser={user.premium_subscription_subscribed}
                onClick={() => {
                  segmentTracking(
                    `${
                      user.premium_subscription_subscribed
                        ? 'give_25 clicked'
                        : 'upgrade clicked'
                    }`,
                    {
                      location: 'Nav Bar',
                    },
                  );

                  user.premium_subscription_subscribed
                    ? history.push(`${config.PRIVATE_BASE_PATH}referrals`)
                    : renderOutside((done) => (
                        <PremiumSubscriptionModal
                          onClose={() => {
                            done();
                          }}
                          onSuccess={() => {
                            done();
                          }}
                          openLocation={MODAL_LOCATIONS.SIDEBAR_NAVIGATION_TAG}
                          refetchQueries={[
                            basicUserProfile,
                            ownerSettingsQuery,
                          ]}
                        />
                      ));
                }}
              />
            </div>
          </>
        )}
      </nav>
    </>
  );
};

MobileNavigation.propTypes = {
  location: PropTypes.object.isRequired,
  isHidden: PropTypes.bool,
  isOwner: PropTypes.bool,
  className: PropTypes.string,
  unreadMessagesCount: PropTypes.number,
  ownerNavItems: PropTypes.array,
  ownerDrawerItems: PropTypes.array,
  renterNavItems: PropTypes.array,
  user: PropTypes.object,
  userDataLoaded: PropTypes.bool,
};

export default MobileNavigation;
