import React from 'react';
import cx from 'classnames';

import { TextButton } from '../../../../../commons';
import Resend from '../../../../../icons/Resend';
import Button from '../../../../Button';

import styles from './Elements.module.scss';

export const IncomeInsightsIcon = ({
  children,
  okIcon,
  noDataIcon,
  mismatchIcon,
}) => {
  let className = '';
  if (okIcon) className = styles.okIconDiv;
  else if (noDataIcon) className = styles.noDataIconDiv;
  else if (mismatchIcon) className = styles.errorIconDiv;

  return (
    <>
      {children && (
        <div className={cx(styles.iconDiv, className, styles.hideMobile)}>
          {children}
        </div>
      )}
    </>
  );
};

export const IncomeInsightsContent = ({ children }) => (
  <div className={styles.content}>{children}</div>
);

export const IncomeInsightsTitle = ({
  children,
  biggerTitle,
  greenTitle,
  redTitle,
}) => {
  let className = '';
  if (greenTitle) className = styles.green;
  else if (redTitle) className = styles.red;

  return (
    <div
      className={cx(styles.title, biggerTitle && styles.biggerTitle, className)}
    >
      <span>{children}</span>
    </div>
  );
};

export const IncomeInsightsDescription = ({ children }) => (
  <div className={styles.description}>{children}</div>
);

export const IncomeInsightsButton = ({
  style,
  onClick,
  label,
  className,
  ...otherProps
}) => (
  <>
    <Button
      className={cx(styles.action, className)}
      onClick={onClick}
      {...otherProps}
    >
      {label}
    </Button>
  </>
);

export const IncomeInsightsReminderButton = ({ onClick }) => {
  return (
    <TextButton
      size="large"
      label="Send reminder"
      style={{ alignSelf: 'center' }}
      buttonStyle={{ width: 159 }}
      labelStyles={{ fontSize: 16 }}
      iconStyles={{ marginRight: 5, width: 22, height: 19 }}
      id="send_reminder"
      onClick={onClick}
      icon={Resend}
    />
  );
};
