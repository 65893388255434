export const REIHUB_PACKAGE_ITEMS_EXTENDED = [
  'Built for rental properties',
  'Integrated with TurboTenant',
  'Automated expense tracking',
  'Automated bookkeeping',
  '30+ downloadable reports (P&L, cash-on-cash, NOI, etc.)',
  'Tax-ready Schedule E',
  'Email and phone support',
  'Short, mid, & long term rentals',
];

export const REIHUB_PACKAGE_ITEMS = [
  'Built for rental properties',
  'Integrated with TurboTenant',
  'Automated expense tracking and bookkeeping',
  '30+ downloadable reports (P&L, cash-on-cash, NOI, etc.)',
  'Tax-ready Schedule E',
  'Email and phone support',
];

export const REIHUB_PACKAGE_ITEMS_RP = [
  'Seamlessly connect your TurboTenant payments and bank accounts',
  'Built for rental properties',
  'Automated expense tracking and bookkeeping',
  '30+ downloadable reports (P&L, cash-on-cash, NOI, etc.)',
  'Tax-ready Schedule E',
  'Email and phone support',
];
