import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import { css, withStyles } from '../styles/with-styles';

const ProfileLogo = ({
  size = 'large', // large, mediumLarge, medium, small
  firstName = '',
  lastName = '',
  type = 'renter', // renter, landlord
  img,
  styles,
  colors,
}) => {
  const background = get(colors, 'background', '');
  const color = get(colors, 'color', '');
  return (
    <React.Fragment>
      {img ? (
        <img
          {...css(styles.circle, styles[size])}
          alt="Profile logo!"
          src={img}
        />
      ) : (
        <div
          {...css(styles.circle, styles[size])}
          style={{
            backgroundColor: type === 'renter' ? background : '#00C8FF',
            color: type === 'renter' ? color : '#5DA7BC',
          }}
        >
          {firstName ? firstName[0] : ''}
          {lastName ? lastName[0] : ''}
        </div>
      )}
    </React.Fragment>
  );
};

ProfileLogo.propTypes = {
  size: PropTypes.oneOf(['large', 'mediumLarge', 'medium', 'small']),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  type: PropTypes.oneOf(['renter', 'landlord']),
  img: PropTypes.string,
  colors: PropTypes.object,
};

export default withStyles(({ media }) => ({
  large: {
    fontSize: '32px',
    lineHeight: '38px',
    letterSpacing: '-1.5px',
    width: 80,
    height: 80,
    ...media.smallScreen({
      fontSize: '28px',
      letterSpacing: '-1.35px',
      width: 64,
      height: 64,
    }),
  },
  mediumLarge: {
    width: 64,
    height: 64,
    minWidth: 64,
    minHeight: 64,
    fontSize: '28px',
    letterSpacing: '-1.35px',
  },
  medium: {
    width: 56,
    height: 56,
    minWidth: 56,
    minHeight: 56,
    fontSize: '28px',
    letterSpacing: '-1px',
  },
  small: {
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-1px',
    width: 40,
    height: 40,
    minWidth: 40,
    minHeight: 40,
  },
  circle: {
    borderRadius: '50%',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    objectFit: 'cover',
  },
}))(ProfileLogo);
