import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

let ArrowsArrowLeft7 = (props) => (
  <SvgIcon {...props}>
    <g
      stroke={props.color}
      strokeWidth={2}
      fillOpacity="0"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M.503 12.498h23M13.003 1.498l-12.5 11 12.5 11" />
    </g>
  </SvgIcon>
);
ArrowsArrowLeft7 = memo(ArrowsArrowLeft7);
ArrowsArrowLeft7.displayName = 'ArrowsArrowLeft7';
ArrowsArrowLeft7.muiName = 'SvgIcon';

export default ArrowsArrowLeft7;
