import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import { REIHUB_PACKAGE_ITEMS } from '../../../constants/reihub/reihubPackageItems';
import { useUserProfile } from '../../../core/TTgraphql';
import basicUserProfile from '../../../graphql/basicUserProfile.graphql';
import ownerSettingsQuery from '../../../graphql/queries/ownerSettingsQuery.graphql';
import getReiHubYearlyPrice from '../../../helpers/getReiHubYearlyPrice';
import openInNewTab from '../../../helpers/openInNewTab';
import CheckMarkFilled from '../../../icons/CheckMarkFilled';
import PremiumBadge from '../../../icons/PremiumBadge';
import { segmentTracking } from '../../../services/utilities';
import SuccessAnimation from '../../Animations/SuccessAnimation';
import Button from '../../Button';
import FlatButton from '../../FlatButton';
import HelpfulInfoList from '../../HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../HelpfulInfo/HelpfulInfoListItem';
import Modal from '../../Modal';
import ReiHubModalWrapped from '../../ReiHubSubscription/ReiHubModalWrapped';
import ReiHubSubscriptionSuccessModal from '../../ReiHubSubscription/ReiHubSubscriptionSuccessModal';
import Stepper from '../../Stepper';
import Tag from '../../Tag';
import { H2, H3, H4, Link, Paragraph } from '../../Typography';

import styles from './PremiumIsActiveModal.module.scss';

const PremiumIsActiveModal = ({
  onClose,
  segmentLocation,
  segmentProperties,
  segmentLoadEvent,
  showREIHubOption,
  privateBasePath,
  open,
  listingsCount,
  showPremiumIsNowActive,
  titlePrefix,
  text,
  listItems,
  mobileFullScreen,
}) => {
  const history = useHistory();
  const { user } = useUserProfile();
  const [unitCount, setUnitCount] = useState(listingsCount || 1);
  const [showReiHubPaymentModal, setShowReiHubPaymentModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    if (user?.onboarding_data?.properties > 0) {
      setUnitCount(
        Math.max(user.onboarding_data.properties, listingsCount || 1),
      );
    }
  }, [user?.onboarding_data?.properties]);

  useEffect(() => {
    if (segmentLoadEvent) {
      sendSegmentEvent(segmentLoadEvent);
    }
  }, []);

  const sendSegmentEvent = (event) => {
    segmentTracking(event, {
      location: segmentLocation,
      ...segmentProperties,
    });
  };

  const reiHubYearlyPrice = getReiHubYearlyPrice(
    user?.reihub_yearly_pricings,
    unitCount,
  );

  const onNotRightNow = () => {
    sendSegmentEvent('not_right_now clicked');
    onClose();
  };

  const onLearnMore = () => {
    sendSegmentEvent('learn_more_about_rental_accounting_link clicked');
    openInNewTab(`${privateBasePath}payments/accounting`);
  };

  const onClaimDiscount = () => {
    setShowReiHubPaymentModal(true);
    sendSegmentEvent('claim_my_discount clicked');
  };

  const onSoundsGood = () => {
    sendSegmentEvent('sounds_good clicked');
    onClose();
  };

  if (showSuccessModal) {
    return (
      <ReiHubSubscriptionSuccessModal
        open={showSuccessModal}
        isYearlySubscription
        onClose={() => {
          onClose();
          setShowSuccessModal(false);
          setTimeout(() => document.activeElement.blur(), 1);
          history.push(`${privateBasePath}dashboard`);
        }}
      />
    );
  }

  return (
    <>
      <Modal
        autoFocus={false}
        disableOutsideClick
        id="premiumModalActivated"
        onClose={onClose}
        className={styles.modal}
        open={open}
        mobileFullScreen={mobileFullScreen}
      >
        {showPremiumIsNowActive && (
          <>
            <div
              className={cx(styles.row, { [styles.mb24]: !showREIHubOption })}
            >
              <div className={styles.animationHolder}>
                <PremiumBadge className={styles.icon} />
                <div className={styles.animation}>
                  <SuccessAnimation style={{ width: 50 }} />
                </div>
              </div>
              <div className={styles.headerWrapper}>
                <H2
                  className={cx(styles.title, {
                    [styles.bm4]: !showREIHubOption,
                  })}
                >
                  Premium is now active!
                </H2>

                {!showREIHubOption && (
                  <Paragraph size="large">
                    You’ve got the tools you need to manage your property
                    professionally, efficiently, and confidently.
                  </Paragraph>
                )}
              </div>
            </div>
            <hr className={styles.divider} />
          </>
        )}

        {showREIHubOption ? (
          <>
            <Tag accent small uppercase={false} className={styles.tag}>
              Limited Time Offer
            </Tag>
            <H3>
              {titlePrefix}{' '}
              <span className={styles.savePrice}>
                save ${reiHubYearlyPrice?.savings}
              </span>{' '}
              on rental accounting!
            </H3>
            <Paragraph size="large">{text}</Paragraph>
            <Paragraph size="large" className={styles.paragraph} italic>
              Offer expires in 7 days on{' '}
              {moment().add(7, 'days').format('MMMM D, YYYY')}.
            </Paragraph>
            <Stepper
              className={styles.stepper}
              input={{
                value: unitCount,
                onChange: (val) => setUnitCount(val),
              }}
              id="stepper"
              label="How many units do you own or manage?"
              small
              meta={{
                touched: false,
                error: '',
              }}
              min={listingsCount}
            />
            <H4 className={styles.subTitle}>
              Rental Accounting powered by REI Hub
            </H4>
            <Paragraph className={styles.priceWrapper}>
              <span className={styles.oldPrice}>
                ${reiHubYearlyPrice?.slashedPrice}
              </span>
              <span className={styles.price}>${reiHubYearlyPrice?.price}</span>
              <span className={styles.priceType}>per year</span>
            </Paragraph>

            <HelpfulInfoList className={styles.offerListWrapper}>
              {listItems.map((item, index) => (
                <HelpfulInfoListItem
                  icon={CheckMarkFilled}
                  iconProps={{ color: colors.primary }}
                  className={styles.listItem}
                  key={index}
                >
                  {item}
                </HelpfulInfoListItem>
              ))}
            </HelpfulInfoList>

            <Link
              className={styles.learnMoreLink}
              onClick={(e) => {
                e.preventDefault();
                onLearnMore();
              }}
            >
              Learn more about Rental Accounting
            </Link>
            <div className={styles.offerFooter}>
              <Button onClick={onClaimDiscount} className={styles.btn}>
                Claim my discount
              </Button>
            </div>

            <FlatButton
              onClick={onNotRightNow}
              className={styles.flatBtn}
              data-qa="not-interested-btn"
            >
              Not right now
            </FlatButton>
          </>
        ) : (
          <Button onClick={onSoundsGood} className={styles.goodBtn}>
            Sounds good
          </Button>
        )}
      </Modal>

      {showReiHubPaymentModal && (
        <ReiHubModalWrapped
          unitCount={unitCount}
          onClose={() => {
            setShowReiHubPaymentModal(false);
            setTimeout(() => document.activeElement.blur(), 1);
          }}
          onSuccess={() => {
            sendSegmentEvent('purchase_reihub_subscription clicked');
            sendSegmentEvent('purchase_reihub_subscription success');

            setShowReiHubPaymentModal(false);
            setTimeout(() => document.activeElement.blur(), 1);
            setShowSuccessModal(true);
          }}
          refetchQueries={[basicUserProfile, ownerSettingsQuery]}
        />
      )}
    </>
  );
};

PremiumIsActiveModal.defaultProps = {
  showREIHubOption: false,
  showPremiumIsNowActive: true,
  titlePrefix: 'Bundle and',
  text: `It’s never too early to get organized with automated bookkeeping. You’ll turn next tax season into “relax” season!`,
  listItems: REIHUB_PACKAGE_ITEMS,
  mobileFullScreen: false,
};

PremiumIsActiveModal.propTypes = {
  onClose: PropTypes.func,
  segmentLocation: PropTypes.string,
  segmentProperties: PropTypes.object,
  segmentLoadEvent: PropTypes.string,
  showREIHubOption: PropTypes.bool,
  open: PropTypes.bool,
  privateBasePath: PropTypes.string,
  listingsCount: PropTypes.number,
  showPremiumIsNowActive: PropTypes.bool,
  titlePrefix: PropTypes.string,
  text: PropTypes.string,
  listItems: PropTypes.arrayOf(PropTypes.string),
  mobileFullScreen: PropTypes.bool,
};

export default PremiumIsActiveModal;
