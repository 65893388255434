import Decimal from 'jsdecimal';

export const normalizeLateFeeFields = (charge = {}) => {
  if (charge.late_fee_amount == null) {
    charge.late_fee_grace_period = null;
    charge.is_late_fee_percentage = null;
  } else {
    charge.is_late_fee_percentage = charge.is_late_fee_percentage === true;
    charge.late_fee_grace_period =
      parseInt(charge.late_fee_grace_period) || null;
    charge.late_fee_amount = charge.is_late_fee_percentage
      ? parseFloat(charge.late_fee_amount)
      : Decimal(charge.late_fee_amount).mul(100).toFloat();
  }

  return charge;
};

export const prepareChargeForMutation = (charge = {}) =>
  normalizeLateFeeFields({
    description: charge.description,
    amount: Decimal(charge.amount).mul(100).toFloat(),
    category: charge.category,
    type: charge.rule_type || charge.type,
    start_date: charge.start_date,
    end_date: charge.no_end_date ? null : charge.end_date,
    due_day_of_month: charge.due_day_of_month
      ? parseInt(
          charge.due_day_of_month === 'Last Day' ? 31 : charge.due_day_of_month,
        )
      : undefined,
    destination_id: charge.destination_id,
    is_late_fee_percentage: charge.is_late_fee_percentage,
    late_fee_amount: charge.late_fee_amount,
    late_fee_grace_period: charge.late_fee_grace_period,
    attachments: charge.attachments?.map(({ id }) => id),
  });
