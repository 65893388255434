import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import confirmEmailGQL from './graphql/confirmEmail.graphql';
import ConfirmEmail from './ConfirmEmailComponent';

const ConfirmEmailContainer = (props) => {
  const [confirmEmailRequest, { loading: confirmEmailMutation }] =
    useMutation(confirmEmailGQL);

  const confirmEmail = useCallback(async (variables) => {
    const res = await confirmEmailRequest({
      variables: variables,
    });

    return res?.data?.verifyEmail;
  }, []);

  const newProps = {
    confirmEmail,
    token: props.match.params.token,
    loading: confirmEmailMutation,
  };

  return <ConfirmEmail {...props} {...newProps} />;
};

export default ConfirmEmailContainer;
