import React from 'react';
import { array, object } from 'prop-types';
import compose from 'recompose/compose';

import { Column, Row } from '../Layout';
import { css, withStyles } from '../styles/with-styles';

import HeaderItem from './HeaderItem';

const HeaderWrap = ({ styles, tabs }) => {
  return (
    <Column {...css(styles.barStyles)}>
      <Row {...css(styles.mainRowContainer)}>
        {tabs.map((tab, index) => {
          return <HeaderItem key={tab.id + index} tab={tab} />;
        })}
      </Row>
    </Column>
  );
};

HeaderWrap.propTypes = {
  styles: object,
  tabs: array,
};

export default compose(
  withStyles(({ colors, media }) => ({
    barStyles: {
      backgroundColor: '#FFFFFF',
      position: 'fixed', // or absolute
      top: 0,
      right: 0,
      width: 'inherit',
      left: 0,
      height: 76,
      zIndex: 900,
      boxShadow: 'none',
      paddingLeft: 200,
      borderBottom: `4px solid ${colors.lightGrey}`,
      ...media.smallScreen({
        position: 'absolute',
        height: 60,
        paddingLeft: 0,
      }),
    },
    mainRowContainer: {
      paddingLeft: 32,
      paddingRight: 32,
      height: '100%',
      ...media.xSmallScreen({
        paddingLeft: 0,
        paddingRight: 0,
      }),
    },
  })),
)(HeaderWrap);
