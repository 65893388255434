import get from 'lodash.get';
import isNull from 'lodash.isnull';
import isObject from 'lodash.isobject';
import isString from 'lodash.isstring';
import moment from 'moment';

import isMatterportPresentation from '../../helpers/isMatterportPresentation';

const dateFormats = [
  'MM/DD/YYYY',
  'YYYY-MM-DD',
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'MM/YY',
  'YYYYMMDD',
];

export function getPhones(application) {
  if (!application || !application.telephone) {
    return [];
  }
  const {
    telephone: { telephone_1, telephone_2 },
  } = application;
  const phones = [];
  if (telephone_1 && telephone_1.number && telephone_1.type) {
    phones.push({
      number: telephone_1.number,
      type: telephone_1.type,
    });
  }
  if (telephone_2 && telephone_2.number && telephone_2.type) {
    phones.push({
      number: telephone_2.number,
      type: telephone_2.type,
    });
  }
  return phones;
}

export function getAppliedTo(listing = {}) {
  const address = get(listing, 'address', '');
  const unit = get(listing, 'unit', '');
  const unitString = !unit ? '' : ` Unit# ${unit.trim()}`;
  return `${address}${unitString}`;
}

export function maskSSN(value) {
  if (!isString(value)) {
    console.warn('Please provide string!');
    return '';
  }
  let maskedValue = value.replace(/\D/g, '');
  if (maskedValue.length > 5) {
    maskedValue = maskedValue.replace(
      /^(\d\d\d)(\d{2})(\d{1,4}).*/,
      '$1-$2-$3',
    );
  } else if (maskedValue.length > 3) {
    maskedValue = maskedValue.replace(/^(\d\d\d)(\d+).*/, '$1-$2');
  }
  return maskedValue;
}

export function maskTaxId(value) {
  if (!isString(value)) {
    console.warn('Please provide string!');
    return '';
  }
  let maskedValue = value.replace(/\D/g, '');
  maskedValue = maskedValue.replace(/^(\d\d)(\d{1,7}).*/, '$1-$2');

  return maskedValue;
}

export function clearMask(phone) {
  if (!isString(phone)) {
    return '';
  }
  return phone.replace(/[^A-Z0-9]/gi, '');
}

export function getInsuranceStatus(renterId, insurance, leases) {
  let scraped;
  if (insurance && insurance.proof_url) {
    scraped = true;
  } else {
    scraped = isObject(insurance) && !!insurance.policy_start_at;
  }
  const renterNotified =
    leases &&
    leases.length > 0 &&
    leases.some(
      (l) =>
        l.insurance_notification_sent &&
        l.insurance_notification_sent.some(
          (r) => r.renter_id === renterId && r.notification_sent,
        ),
    );

  if (scraped) {
    if (insurance.policy_canceled_at !== null) {
      return 'canceled';
    }
    return 'scraped';
  }
  if (isNull(insurance) && renterNotified) {
    return 'notified';
  }
  if (!isNull(insurance) && !scraped) {
    return 'notScraped';
  }

  return 'notNotified';
}

export function getFormattedDateLocalTZ(date, format = 'MM/DD/YYYY') {
  return (
    (date && moment(date, dateFormats, true).local().format(format)) ||
    undefined
  );
}

export function mapAddressTypeToString(type) {
  if (type == undefined) {
    return undefined;
  }
  switch (type) {
    case 0:
      return 'OWN';
    case 1:
      return 'RENT';
    case 2:
      return 'FAMILY';
    case 3:
      return 'OTHER';
  }
}

export function mapAddressTypeToInt(type) {
  if (type == undefined) {
    return undefined;
  }
  switch (type) {
    case 'OWN':
      return 0;
    case 'RENT':
      return 1;
    case 'FAMILY':
      return 2;
    case 'OTHER':
      return 3;
  }
}

export function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
}

export const isValidEmail = (email) => {
  if (!email) {
    return false;
  }
  const unitRegex =
    /^(([^<>/().,;:\s@"]+(\.[^<>/().,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return unitRegex.test(email);
};

export const getPropertyStatus = (
  syndicationStatus,
  marketingOnline,
  marketingOnlineDays,
) => {
  switch (syndicationStatus) {
    case 'UNVERIFIED':
    case 'PENDING':
    case 'REQUESTED_REVIEW_VIOLATION':
    case 'REQUESTED_REVIEW_DOCUMENTS':
      return 'Marketing Pending';
    default:
      return marketingOnline
        ? `Marketing (Expires in ${marketingOnlineDays} days)`
        : 'Not Marketing';
  }
};

export function getPaymentRequestCategory(category) {
  switch (category) {
    case 'RENT':
      return 'RENT';
    case 'LATE_FEE':
      return 'LATE FEE';
    case 'SECURITY_DEPOSIT':
      return 'SECURITY DEPOSIT';
    case 'UTILITY_CHARGE':
      return 'UTILITY CHARGE';
    case 'NSF_RETURNED_PAYMENT':
      return 'NSF Fee';
    case 'OTHER':
      return 'OTHER';
    default:
      return '';
  }
}

export const isValidVideoUrl = (url) => {
  if (url === '' || url === undefined) {
    return true;
  }
  url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/)?([A-Za-z0-9._%-]*)(\\&\S+)?/,
  );
  return RegExp.$3.indexOf('youtu') > -1 || RegExp.$3.indexOf('vimeo') > -1;
};

export const isValidVideoTourUrl = (url) =>
  isValidVideoUrl(url) || isMatterportPresentation(url);

/*
  NOTE: This logic a bit ugly, but BE changes included
  updates that will support multiple applications in the future
  and existing logic needs to follow ordering if default listing is not set
*/
export const getActiveListingForRenter = (renter) => {
  return get(renter, 'listings[0]', {});
};
