import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import Label from '../../../../components/Label';
import PasswordInput from '../../../../components/PasswordInput';
import { H2, Link, Paragraph } from '../../../../components/Typography';
import MarketingLayout from '../../../../layout/MarketingLayout';
import composeValidators from '../../../../validators/composeValidators';
import fieldRequired from '../../../../validators/fieldRequired';
import validPasswordStrength from '../../../../validators/validPasswordStrength';
import AcceptInviteSidebar from '../AcceptInviteSidebar';

import styles from './AcceptInvite.module.scss';

const AcceptInviteNewUser = ({
  company,
  accountName,
  userName,
  email,
  handleSubmit,
}) => {
  const [tosAccepted, setTosAccepted] = useState(false);

  return (
    <MarketingLayout
      side={
        <AcceptInviteSidebar
          company={company}
          accountName={accountName}
          userName={userName}
        />
      }
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <H2 className={styles.title}>
            Welcome to TurboTenant, <br /> {userName}!
          </H2>
          <Paragraph size="large" weight="semi">
            {accountName.split(' ')[0]} invited you to their account. Set a
            password to get started.
          </Paragraph>
          <Form
            onSubmit={handleSubmit}
            subscription={{
              submitting: true,
            }}
          >
            {({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit} className={styles.form}>
                <Label>Email</Label>
                <Paragraph className={styles.email}>{email}</Paragraph>
                <Field
                  label="Password"
                  component={PasswordInput}
                  name="password"
                  id="password"
                  showPasswordStrengthChecker
                  validate={composeValidators(
                    fieldRequired,
                    validPasswordStrength,
                  )}
                  className={styles.password}
                />
                <div className={styles.tosWrapper}>
                  <Checkbox
                    className={styles.tosCheckbox}
                    validate={fieldRequired}
                    label={
                      <>
                        I agree to the{' '}
                        <Link
                          href="https://www.turbotenant.com/terms-of-use/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Terms of Use"
                        >
                          Terms of Use
                        </Link>{' '}
                        &{' '}
                        <Link
                          href="https://www.turbotenant.com/privacy-policy/"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Privacy Policy"
                          className={styles.link}
                        >
                          Privacy Policy
                        </Link>
                        .
                      </>
                    }
                    labelClassName={styles.tosLabel}
                    input={{
                      onChange: (event) => {
                        const { checked } = event.currentTarget;
                        setTosAccepted(checked);
                      },
                    }}
                  />
                </div>
                <Button
                  type="submit"
                  className={styles.button}
                  disabled={submitting || !tosAccepted}
                  data-qa="accept-invite-submit"
                >
                  Set My Password
                </Button>
              </form>
            )}
          </Form>
        </div>
      </div>
    </MarketingLayout>
  );
};

AcceptInviteNewUser.defaultProps = {
  company: '',
  userName: 'Moe',
  accountName: 'Joe',
  email: 'moe@gmail.com',
};

AcceptInviteNewUser.propTypes = {
  company: PropTypes.string,
  userName: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default AcceptInviteNewUser;
