import React from 'react';
import FaceBookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { bool, func, object, string } from 'prop-types';

import FaceBookIcon from '../icons/UIExperience/logo-facebook';
import { css, withStyles } from '../styles/with-styles';

class Button extends React.Component {
  static propTypes = {
    faceBookOAuthId: string.isRequired,
    onFailure: func,
    callback: func.isRequired,
    autoLoad: bool,
    disableMobileRedirect: bool,
    styles: object,
    buttonStyles: object,
    label: string,
    fields: string,
  };
  static defaultProps = {
    label: 'Log in with Facebook',
    fields: 'name,picture',
    autoLoad: false,
    buttonStyles: {},
    disableMobileRedirect: false,
  };
  // need to refactor this component
  renderFaceBookButton = (renderProps) => {
    const { styles, label, buttonStyles } = this.props;
    return (
      <div
        onClick={renderProps.onClick}
        {...css(styles.faceBookButton, buttonStyles)}
      >
        <FaceBookIcon style={{ widht: 18, height: 18 }} />
        <div {...css(styles.loginText)}>{label}</div>
      </div>
    );
  };
  render() {
    const {
      faceBookOAuthId,
      autoLoad,
      onFailure,
      callback,
      fields,
      disableMobileRedirect,
    } = this.props;
    return (
      <FaceBookLogin
        appId={faceBookOAuthId}
        fields={fields}
        autoLoad={autoLoad}
        onFailure={onFailure}
        callback={callback}
        render={this.renderFaceBookButton}
        disableMobileRedirect={disableMobileRedirect}
      />
    );
  }
}

export const FaceBookButton = withStyles(({ media }) => ({
  faceBookButton: {
    padding: 11,
    display: 'flex',
    fontFamily: 'Roboto, sans-serif',
    backgroundColor: '#3B5998',
    border: 'none',
    borderRadius: 2,
    fontSize: 14,
    fontWeight: 500,
    color: 'white',
    width: 192,
    height: 40,
    cursor: 'pointer',
    ...media.smallScreen({
      width: '100%',
    }),
  },
  loginText: {
    width: '100%',
    textAlign: 'center',
  },
}))(Button);
