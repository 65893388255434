import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import TTLogo from '../../components/Logo';

const Logo = ({
  externalLink,
  logoLink,
  maxHeight,
  maxWidth,
  customLogo,
  className,
}) => {
  const Img = customLogo ? (
    <a href={externalLink} target="_blank" rel="noopener noreferrer">
      <img
        alt="Turbotenant"
        className="logo"
        src={customLogo}
        // @TODO move to withstyles
        style={{
          maxHeight: maxHeight,
          maxWidth: maxWidth,
          position: 'relative',
          padding: 2,
        }}
      />
    </a>
  ) : (
    <Link to={logoLink} title="Turbotenant">
      <TTLogo
        height={maxHeight}
        width={maxWidth}
        className="logo"
        style={{
          position: 'relative',
          padding: 2,
        }}
      />
    </Link>
  );
  return <div className={className}>{Img}</div>;
};

Logo.propTypes = {
  customLogo: PropTypes.string,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  externalLink: PropTypes.string,
};

Logo.defaultProps = {
  maxHeight: 48,
  logoLink: '/',
};

export default Logo;
