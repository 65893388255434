import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../components/Modal/index';

import REIHubSubscriptionSuccess from './REIHubSubscriptionSuccess';

import styles from './REIHubSubscriptionSuccessModal.module.scss';

const ReiHubSubscriptionSuccessModal = ({
  open,
  onClose,
  isYearlySubscription,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      containerClassName={styles.modalWrap}
      className={styles.container}
      autoFocus={false}
    >
      <REIHubSubscriptionSuccess
        onClose={onClose}
        className={styles.card}
        isYearlySubscription={isYearlySubscription}
      />
    </Modal>
  );
};

ReiHubSubscriptionSuccessModal.propTypes = {
  open: PropTypes.bool,
  isYearlySubscription: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ReiHubSubscriptionSuccessModal;
