import React from 'react';
import {
  arrayOf,
  bool,
  func,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';

import Button from '../../components/Button';
import ButtonPopover from '../../components/ButtonPopover';
import FlatButton from '../../components/FlatButton';
import colors from '../../constants/colors';
import ArrowDropDown from '../../icons/DownArrow';
import { Tooltip } from '../Tooltip';

import styles from './ActionsButton.module.scss';

export const popoverStyles = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  targetOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

const ActionsButton = ({ actions, onAction, bulk, id, 'data-qa': dataQa }) => {
  const handleAction = (action) => {
    onAction(action);
  };
  const [action] = actions;

  if (actions.length === 1) {
    return (
      <Button
        small
        onClick={() => handleAction(action.type || action.label)}
        size="small"
        className={styles.singleButton}
        secondary
        disabled={action.disabled}
        data-qa={dataQa || 'actions-button'}
      >
        {bulk ? action.bulkLabel || action.label : action.label}
      </Button>
    );
  }
  return (
    <div>
      <ButtonPopover
        customAction={(props) => (
          <Button
            icon={ArrowDropDown}
            iconAfter
            className={styles.action}
            secondary={!bulk}
            small
            id={id}
            data-qa={dataQa || 'actions-button'}
            closeOnClick
            {...props}
          >
            Actions
          </Button>
        )}
      >
        {actions.map((actionItem, index) => (
          <React.Fragment key={actionItem.id || index}>
            {actionItem.disabled && (
              <Tooltip
                id={`disabled-tip-${actionItem.type}`}
                place={index === 0 ? 'bottom' : 'top'}
              >
                {actionItem.disabledMessage}
              </Tooltip>
            )}
            <FlatButton
              data-tooltip-id={`disabled-tip-${actionItem.type}`}
              key={actionItem.type}
              onClick={() =>
                !actionItem.disabled &&
                handleAction(actionItem.type || actionItem.label)
              }
              icon={actionItem.icon}
              disabled={actionItem.disabled}
              iconProps={{
                color: colors.primary,
              }}
              className={styles.actionItem}
              id={actionItem.id}
            >
              {bulk
                ? actionItem.bulkLabel || actionItem.label
                : actionItem.label}
            </FlatButton>
          </React.Fragment>
        ))}
      </ButtonPopover>
    </div>
  );
};

ActionsButton.propTypes = {
  styles: object,
  actions: arrayOf(
    shape({
      label: string.isRequired,
      type: string.isRequired,
      icon: oneOfType([func, object]),
      disabled: bool,
      disabledMessage: string,
    }),
  ),
  bulk: bool,
  onAction: func,
  id: string,
};

ActionsButton.defaultProps = {
  label: 'actions',
};

export default ActionsButton;
