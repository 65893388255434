export const isXDecimalPlaces = (decimalPlaces) => (value) => {
  if (!isNaN(value)) {
    if (`${value}`.split('.')[1]?.length > decimalPlaces) {
      return `A maximum of ${decimalPlaces} decimal places allowed.`;
    }
  }
  return undefined;
};

export default isXDecimalPlaces;
