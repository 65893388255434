import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

let ArrowsArrowRight12 = (props) => (
  <SvgIcon {...props}>
    <path
      stroke={props.color}
      strokeWidth={props.strokeWidth || 1.25}
      fillOpacity="0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M5.431 23.498l13.142-11.5-13.142-11.5"
    />
  </SvgIcon>
);
ArrowsArrowRight12 = memo(ArrowsArrowRight12);
ArrowsArrowRight12.displayName = 'ArrowsArrowRight12';
ArrowsArrowRight12.muiName = 'SvgIcon';

export default ArrowsArrowRight12;
