import React from 'react';

import {
  mapAddressTypeToInt,
  mapAddressTypeToString,
} from '../../commons/services/utilities';

import SegmentedControllerField from './SegmentedControllerField';

const ResidenceTypeField = ({ options = [], ...props }) => (
  <SegmentedControllerField
    options={
      options?.length > 0
        ? options
        : [
            {
              label: 'RENT',
              value: 'RENT',
            },
            {
              label: 'OWN',
              value: 'OWN',
            },
            {
              label: 'OTHER',
              value: 'OTHER',
            },
          ]
    }
    format={(value) =>
      value != null && value !== '' ? mapAddressTypeToString(value) : ''
    }
    parse={(value) =>
      value != null && value !== '' ? mapAddressTypeToInt(value) : ''
    }
    {...props}
  />
);

export default ResidenceTypeField;
