// - Common boilerplate
import React, { Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { ErrorBoundary } from 'react-error-boundary';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Toaster } from 'sonner';
import muiTheme from 'tt-commons/styles/mui.theme';

import browserHistory from '../browserHistory';
import AddAppHomeScreen from '../components/AddAppHomeScreen';
import { ModalProvider } from '../components/Modal/ModalProvider';
import initCometChat from '../core/CometChatInitialization';
import { initializeHelloSign } from '../core/helloSign';
import { TTInitializer } from '../core/initialization';
import UserEventSubscriptionHandler from '../core/UserEventSubscriptionHandler';
import { OutsideProvider } from '../helpers/render-outside';
import { useConfig } from '../providers/ConfigProvider';
import GlobalBannerProvider from '../providers/GlobalBannerProvider';
import LayoutProvider, { CurrentLayout } from '../providers/LayoutProvider';
import TTAccountProvider from '../providers/TTAccountProvider';

import ErrorHandler from './errorHandler';
import GlobalComponents from './GlobalComponents';
import MainRoutes from './routes.main';

// ------------------------------
// Bootstrapping
const initializer = new TTInitializer();

const App = () => {
  const config = useConfig();
  const client = initializer.getApolloClient(config);
  initCometChat(config);
  initializeHelloSign();

  return (
    <DndProvider backend={HTML5Backend}>
      <ApolloProvider client={client}>
        <ErrorBoundary FallbackComponent={ErrorHandler}>
          <MuiThemeProvider muiTheme={muiTheme}>
            <React.Fragment>
              <Router history={browserHistory}>
                <TTAccountProvider>
                  <GlobalBannerProvider>
                    <Toaster />
                    <UserEventSubscriptionHandler />
                    <ModalProvider>
                      <OutsideProvider>
                        <LayoutProvider>
                          <CurrentLayout>
                            <Suspense fallback={null}>
                              <MainRoutes />
                            </Suspense>
                          </CurrentLayout>
                          <GlobalComponents />
                        </LayoutProvider>
                        <AddAppHomeScreen />
                      </OutsideProvider>
                    </ModalProvider>
                  </GlobalBannerProvider>
                </TTAccountProvider>
              </Router>
            </React.Fragment>
          </MuiThemeProvider>
        </ErrorBoundary>
      </ApolloProvider>
    </DndProvider>
  );
};

export default App;
