import React from 'react';
import { object, string } from 'prop-types';

import { Tooltip } from '../../components/Tooltip';

class TooltipComponent extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  static propTypes = {
    id: string.isRequired,
    children: object.isRequired,
    styles: object.isRequired,
  };

  static defaultProps = {
    place: 'bottom',
  };

  render() {
    const { children, id, place, ...otherProps } = this.props;
    return (
      <Tooltip id={id} place={place} {...otherProps}>
        {children}
      </Tooltip>
    );
  }
}

export default TooltipComponent;
