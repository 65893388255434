import React from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton, SecondaryButton } from '../Button';
import { css, withStyles } from '../styles/with-styles';
import { ThemedStyleSheet } from '../styles/with-styles';

const theme = ThemedStyleSheet.get();

class LargeCard extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    titleId: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    button1Label: PropTypes.string,
    button1Id: PropTypes.string,
    button1Action: PropTypes.func,
    button1Disabled: PropTypes.bool,
    button2Label: PropTypes.string,
    button2Action: PropTypes.func,
    button2Id: PropTypes.string,
    button2Disabled: PropTypes.bool,
    style: PropTypes.object,
    sideBarStyles: PropTypes.object,
    buttonsDivStyles: PropTypes.object,
    styles: PropTypes.object,
    sideBars: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        learnMore: PropTypes.func,
      }),
    ),
    sideBarWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reverseButtons: PropTypes.bool,
    button1Styles: PropTypes.object,
    buttonSize: PropTypes.string,
    showCardBorder: PropTypes.bool,
    children: PropTypes.any,
  };

  static defaultProps = {
    buttonSize: 'large',
    buttonsDivStyles: {},
    button1Styles: {},
  };

  renderSideBar = (sideBar, idx) => (
    <div key={idx} {...sideBar.sideBarStyles}>
      <p
        {...css({
          ...theme.typography.cardSidebarTitle,
          margin: 0,
          marginBottom: '2px',
        })}
      >
        {sideBar.title}
      </p>
      <p
        {...css({ color: theme.colors.darkBlueGrey, margin: 0 })}
        dangerouslySetInnerHTML={{ __html: sideBar.description }}
      ></p>
      {sideBar.learnMore && (
        <SecondaryButton
          onClick={sideBar.learnMore}
          label="Learn More"
          style={{ marginTop: 12 }}
          size="small"
        />
      )}
    </div>
  );

  render = () => {
    const {
      showCardBorder,
      reverseButtons,
      styles,
      id,
      buttonsDivStyles,
      button1Styles,
      buttonSize,
      titleId,
      children,
      description,
      title,
      sideBarStyles,
    } = this.props;
    const showSideBar = this.props.sideBars && this.props.sideBars.length > 0;
    const sideBarWidth = this.props.sideBarWidth || '33.71%';
    const cardStyle =
      showCardBorder || showCardBorder === undefined
        ? styles.largeCard
        : styles.largeCardNoBorder;
    return (
      <div id={id} {...css(cardStyle, { padding: 32 }, this.props.style)}>
        {(title || description) && (
          <div {...css(styles.text)}>
            {title && (
              <p
                id={titleId || 'largecard_title'}
                {...css({
                  ...theme.typography.largeCardTitle,
                  marginBottom: '2px',
                  marginTop: 0,
                })}
              >
                {title}
              </p>
            )}
            {this.props.subtitle ? (
              <p
                {...css({
                  color: theme.colors.darkBlueGrey,
                  marginBottom: '2px',
                  marginTop: 0,
                })}
              >
                {this.props.subtitle}
              </p>
            ) : (
              ''
            )}
            {description && (
              <p {...css({ color: theme.colors.darkBlueGrey })}>
                {description}
              </p>
            )}
          </div>
        )}
        <div {...css(styles.middleDiv)}>
          <div {...css(styles.textField, showSideBar && styles.fieldMaxWidth)}>
            {children}
          </div>
          {showSideBar ? (
            <div
              {...css(styles.sideBar, { width: sideBarWidth }, sideBarStyles)}
            >
              {this.props.sideBars.map((sideBar, idx) =>
                this.renderSideBar(sideBar, idx),
              )}
            </div>
          ) : (
            ''
          )}
        </div>
        <div
          {...css(
            styles.buttonsDiv,
            reverseButtons ? styles.reversedButtonsContainerStyles : {},
            buttonsDivStyles,
          )}
        >
          {this.props.button2Label && (
            <SecondaryButton
              disabled={this.props.button2Disabled}
              label={this.props.button2Label}
              onClick={this.props.button2Action}
              size={buttonSize}
              id={this.props.button2Id}
              {...css(
                reverseButtons
                  ? styles.secondaryButtonReverse
                  : styles.secondaryButton,
              )}
            />
          )}
          {this.props.button1Label && (
            <PrimaryButton
              disabled={this.props.button1Disabled}
              label={this.props.button1Label}
              onClick={this.props.button1Action}
              {...css(button1Styles)}
              id={this.props.button1Id}
              size={buttonSize}
            />
          )}
        </div>
      </div>
    );
  };
}

export default withStyles(({ media }) => ({
  ...media.hideSmall,
  largeCard: {
    minWidth: 385,
    padding: 32,
    border: '1px solid',
    borderRadius: 4,
    boxSizing: 'border-box',
    borderColor: theme.colors.lightGrey,
    backgroundColor: theme.colors.white,
    ...media.smallScreen({
      minWidth: 280,
      padding: 16,
      paddingBottom: 24,
    }),
  },
  largeCardNoBorder: {
    minWidth: 385,
    // padding: 32,
    boxSizing: 'border-box',
    backgroundColor: theme.colors.white,
    ...media.smallScreen({
      minWidth: 280,
      padding: 16,
      paddingBottom: 24,
    }),
  },
  text: {
    marginBottom: 24,
    ...media.smallScreen({
      marginBottom: 16,
    }),
  },
  buttonsDiv: {
    textAlign: 'center',
    marginTop: 20,
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 0,
    ...media.smallScreen({
      marginTop: 12,
      marginLeft: 16,
      marginRight: 16,
    }),
  },
  middleDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sideBar: {
    flexGrow: 2,
    marginLeft: '4.44%',
    ...media.smallScreen({
      display: 'none',
    }),
  },
  textField: {
    flexGrow: 3,
  },
  fieldMaxWidth: {
    ...media.mediumScreen({
      maxWidth: '61.85%',
    }),
  },
  secondaryButton: {
    marginRight: 20,
    ...media.xSmallScreen({
      marginRight: 0,
      marginBottom: 20,
    }),
  },
  secondaryButtonReverse: {
    marginRight: 20,
    ...media.smallScreen({
      marginRight: 0,
      marginTop: 20,
    }),
  },
  reversedButtonsContainerStyles: {
    ...media.smallScreen({
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column-reverse',
    }),
  },
}))(LargeCard);
