import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import IconButton from '../../../components/IconButton';
import Modal from '../../../components/Modal';
import Close from '../../../icons/Close';
import StripeElementsComponent from '../../../pages/common/stripe/StripeElementsComponent';

import styles from './StripeElementsModal.module.scss';

const StripeElementsModal = ({
  className,
  onClose,
  open,
  onToken,
  title,
  amount,
  amountLabel,
  enablePromo,
  buttonLabel,
  onPayClicked,
  isBillingPaymentMethod = true,
  additionalPaymentInfo,
  supportedStripeProductIds,
  slashedAmount,
}) => {
  const history = useHistory();
  const location = useLocation();
  const removeUpdateCardParas = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('change-card');
    const newSearchString = searchParams.toString();
    history.replace({
      ...location,
      search: newSearchString,
    });
  };

  return (
    <Modal
      open={open}
      className={cx(styles.container, className)}
      mobileFullScreen
    >
      <IconButton
        onClick={() => {
          removeUpdateCardParas();
          onClose();
        }}
        icon={Close}
        className={styles.closeAction}
        iconProps={{ width: 18, height: 18 }}
      />
      <StripeElementsComponent
        noCardBorder
        onToken={onToken}
        showSuccessMessage
        title={title}
        amount={amount}
        slashedAmount={slashedAmount}
        amountLabel={amountLabel}
        className={styles.stripeModalContainer}
        buttonLabel={buttonLabel}
        onPayClicked={onPayClicked}
        enablePromo={enablePromo}
        isBillingPaymentMethod={isBillingPaymentMethod}
        additionalPaymentInfo={additionalPaymentInfo}
        supportedStripeProductIds={supportedStripeProductIds}
      />
    </Modal>
  );
};

StripeElementsModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  feature: PropTypes.string,
  onSuccess: PropTypes.func,
  onPayClicked: PropTypes.func,
  slashedAmount: PropTypes.number,
  supportedStripeProductIds: PropTypes.array,
};

StripeElementsModal.defaultProps = {
  open: true,
};

export default StripeElementsModal;
