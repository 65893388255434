import React from 'react';
import PropTypes from 'prop-types';

import ChromeIcon from '../assets/chrome.png';
import EdgeIcon from '../assets/edge.png';
import FirefoxIcon from '../assets/firefox.png';
import { SecondaryButton, TextButton } from '../Button';
import Dialog from '../Dialog';
import { Column, Row } from '../Layout';
import { css, ThemedStyleSheet, withStyles } from '../styles/with-styles';

const { typography } = ThemedStyleSheet.get();

class IESupportModal extends React.Component {
  static propTypes = {
    styles: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
  };

  constructor(props, context) {
    super(props, context);
  }

  browserIcon = (src, alt) => {
    const { styles } = this.props;
    return <img alt={alt} src={src} {...css(styles.iconStyle)} />;
  };

  render() {
    const { open, onClose } = this.props;
    return (
      <Dialog open={open} width={544}>
        <span {...css(typography.subTitle, { marginBottom: 5 })}>
          TurboTenant No Longer Supports Internet Explorer.
        </span>
        <br />
        <span {...css(typography.checkboxRadioLabel)}>
          While you can continue to use it in Internet Explorer, you'll have a
          significantly easier time using TurboTenant on one of the following
          browsers:
        </span>
        <Row
          vertical="center"
          horizontal="center"
          {...css({ marginBottom: 32, marginTop: 16 })}
        >
          <Column
            vertical="center"
            horizontal="center"
            {...css({ marginRight: 36 })}
          >
            {this.browserIcon(ChromeIcon, 'Chrome')}
            <span {...css(typography.cardSidebarTitle, { marginTop: 8 })}>
              Chrome
            </span>
            <SecondaryButton
              label="learn more"
              size="xsmall"
              onClick={() =>
                window.open('https://www.google.com/chrome/', '_blank')
              }
              {...css({ marginTop: 16 })}
            />
          </Column>
          <Column
            vertical="center"
            horizontal="center"
            {...css({ marginRight: 36 })}
          >
            {this.browserIcon(FirefoxIcon, 'Firefox')}
            <span {...css(typography.cardSidebarTitle, { marginTop: 8 })}>
              Firefox
            </span>
            <SecondaryButton
              label="learn more"
              size="xsmall"
              onClick={() =>
                window.open('https://www.mozilla.org/en-US/firefox/', '_blank')
              }
              {...css({ marginTop: 16 })}
            />
          </Column>
          <Column vertical="center" horizontal="center">
            {this.browserIcon(EdgeIcon, 'Edge')}
            <span {...css(typography.cardSidebarTitle, { marginTop: 8 })}>
              Microsoft Edge
            </span>
            <SecondaryButton
              label="learn more"
              size="xsmall"
              onClick={() =>
                window.open(
                  'https://www.microsoft.com/en-us/windows/microsoft-edge',
                  '_blank',
                )
              }
              {...css({ marginTop: 16 })}
            />
          </Column>
        </Row>
        <Row vertical="center" horizontal="center">
          <TextButton
            label="Continue with Internet Explorer"
            onClick={onClose}
          />
        </Row>
      </Dialog>
    );
  }
}

export default withStyles(() => ({
  iconStyle: {
    width: 88,
    height: 88,
  },
}))(IESupportModal);
