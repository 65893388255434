import React, { memo } from 'react';
import PropTypes from 'prop-types';

const DwellsyLogo = (props) => (
  <svg {...props} viewBox="0 0 328 104">
    <defs></defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          d="M63.8 103.42a26.77 26.77 0 01-22.57-12.31L41 90.7l-.49.1a33 33 0 01-19.72-62.74l.61-.26-.21-.62a20.14 20.14 0 0137.52-14.53l.29.65.65-.28a33 33 0 0130.9 58.14l-.4.26.08.47a27.58 27.58 0 01.41 4.68 26.88 26.88 0 01-26.84 26.85z"
          fill="#fff"
        />
        <path
          fill="#242a39"
          d="M156.92 54c0 11.29-7.71 19-19.14 19h-14.84V35.11h15.21c11.19 0 18.77 7.67 18.77 18.89zm-9.42.11c0-6.54-4-11-9.57-11h-5.76V65h6.25c5.32 0 9.1-4.36 9.1-10.84zM193.16 73.43a12.33 12.33 0 01-10.33-5.83 12.09 12.09 0 01-10.22 5.83c-7.15 0-11.89-5.24-11.89-13.08V43.8h8.86V60c0 3 1.77 5 4.44 5s4.31-1.94 4.31-5V43.76h8.87V60c0 3 1.82 5 4.54 5 2.57 0 4.28-1.94 4.28-5V43.76h8.87v16.55c.01 7.88-4.68 13.12-11.73 13.12zM230.84 62.52l5 4.86a15.19 15.19 0 01-12.5 6c-8.81 0-14.71-5.9-14.71-14.82s5.49-15 14.21-15.11c8.16-.06 13.55 5.3 13.75 13.94l-18.19 4.72a5.86 5.86 0 005.64 4 9.24 9.24 0 006.8-3.59zm-13.15-5.76l10.13-2.71c-.66-2.59-2.32-3.83-4.54-3.83-3.22 0-5.29 2.35-5.59 6.57zM251.74 65.06a5.67 5.67 0 002.57-.72l.76 7.91a13.24 13.24 0 01-5.24 1.24c-5 0-9.42-3.51-9.42-11v-29.6h9v29.22c0 2.08 1 3 2.37 3zM269.2 65.06a5.64 5.64 0 002.57-.72l.76 7.91a13.24 13.24 0 01-5.24 1.24c-5 0-9.42-3.51-9.42-11v-29.6h9v29.22c0 2.08.93 3 2.37 3zM285.29 50c-1.51 0-2.56.59-2.56 1.83 0 3.84 14.3 2 14.25 12.17 0 6-4.89 9.4-11.52 9.4a19.14 19.14 0 01-12.31-4.13l2.85-6.19a16.88 16.88 0 009.68 3.6c1.66 0 2.88-.59 2.88-1.78 0-4-14-2-14-12.11 0-6.11 4.78-9.45 11.18-9.45a20.91 20.91 0 0111.26 3.4L294 53c-3.42-1.89-6.5-3-8.71-3zM328.87 43.76V70c0 8.27-5.95 14-14.4 13.89A23.79 23.79 0 01302.18 80l2.16-6.7a19.31 19.31 0 009.07 2.88c3.93 0 6.55-2.55 6.55-6.43v-4.19a9.55 9.55 0 01-8.77 5c-6.25 0-10.18-4.31-10.18-11.19V43.72h9v12.87c0 3.29 1.77 5.29 4.64 5.29 3.32 0 5.34-2.65 5.34-6.76V43.76z"
        />
        <path
          fill="#6ecdef"
          d="M43.65 26.18a33.83 33.83 0 0115.72-13.82 20.87 20.87 0 00-38.87 15 33.86 33.86 0 0123.15-1.22z"
        />
        <path
          d="M62.8 75.65A33.78 33.78 0 0140.63 91.5a27.56 27.56 0 0050.73-14.93 28.3 28.3 0 00-.42-4.81 33.73 33.73 0 01-28.14 3.89z"
          fill="#f4608e"
        />
        <path
          fill="#5e5aa6"
          d="M42.7 58.83a33.48 33.48 0 01-3.76-15.46c0-.57 0-1.13.05-1.69A20.89 20.89 0 0120.5 27.4a33.76 33.76 0 0020.13 64.1 27.54 27.54 0 012.07-32.67z"
        />
        <path
          fill="#6ecdef"
          d="M39 41.68a33.54 33.54 0 014.66-15.5A33.86 33.86 0 0020.5 27.4 20.89 20.89 0 0039 41.68z"
        />
        <path
          d="M39 41.68a33.54 33.54 0 014.66-15.5A33.86 33.86 0 0020.5 27.4 20.89 20.89 0 0039 41.68z"
          fill="#2bb8f7"
        />
        <path
          d="M42.7 58.83a33.82 33.82 0 0020.1 16.82A33.77 33.77 0 1039 41.68c0 .56-.05 1.12-.05 1.69a33.48 33.48 0 003.75 15.46"
          fill="#ffaf45"
        />
        <path
          d="M56.73 33.74a33.68 33.68 0 00-13.08-7.56A33.54 33.54 0 0039 41.68c0 .56-.05 1.12-.05 1.69a33.48 33.48 0 003.75 15.46A27.49 27.49 0 0163.8 49c.8 0 1.6 0 2.39.11a33.77 33.77 0 00-9.46-15.37z"
          fill="#fc9163"
        />
        <path
          fill="#5e5aa6"
          d="M62.8 75.65a33.82 33.82 0 01-20.1-16.82 27.54 27.54 0 00-2.07 32.67A33.78 33.78 0 0062.8 75.65z"
        />
        <path
          d="M62.8 75.65a33.82 33.82 0 01-20.1-16.82 27.54 27.54 0 00-2.07 32.67A33.78 33.78 0 0062.8 75.65z"
          fill="#a3458b"
        />
        <path
          d="M66.19 49.11a33.74 33.74 0 01-3.39 26.54 33.73 33.73 0 0028.14-3.89 27.57 27.57 0 00-24.75-22.65z"
          fill="#ff895a"
        />
        <path
          d="M42.7 58.83a33.82 33.82 0 0020.1 16.82 33.74 33.74 0 003.39-26.54C65.4 49 64.6 49 63.8 49a27.49 27.49 0 00-21.1 9.83z"
          fill="#f2447a"
        />
      </g>
    </g>
  </svg>
);

DwellsyLogo.defaultProps = {
  width: 105,
  height: 33,
};

DwellsyLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(DwellsyLogo);
