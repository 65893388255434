import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

let StatusCheck2 = (props) => (
  <SvgIcon {...props}>
    <path
      stroke={props.color}
      strokeWidth={props.strokeWidth || 1.25}
      fillOpacity="0"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M20 2.25l-12.5 12.5-3.5-3.5-3.5 3.5 7 7 16-16z"
    />
  </SvgIcon>
);
StatusCheck2 = memo(StatusCheck2);
StatusCheck2.displayName = 'StatusCheck2';
StatusCheck2.muiName = 'SvgIcon';

export default StatusCheck2;
