import React from 'react';
import { Tooltip } from 'react-tooltip';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import formatAddress from '../../helpers/format-address';
import getFormattedDateLocalTZ from '../../helpers/format-date-local';
import getListingBreadcrumbName from '../../helpers/getListingBreadcrumbName';
import isTenant from '../../helpers/isTenant';
import { css, ThemedStyleSheet, withStyles } from '../styles/with-styles';

import ListItemCard from './listitemcard.component';

const theme = ThemedStyleSheet.get();

class RenterCard extends React.Component {
  static propTypes = {
    application: PropTypes.object,
    renter: PropTypes.object,
    dateLabel: PropTypes.string,
    buttonLabel: PropTypes.string,
    buttonAction: PropTypes.func,
    buttonDisabled: PropTypes.bool,
    itemIndex: PropTypes.number,
    secondaryButtons: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        action: PropTypes.func,
        id: PropTypes.string,
      }),
    ),
    style: PropTypes.object,
    styles: PropTypes.object,
    isPastData: PropTypes.bool,
    listing: PropTypes.object,
  };

  getRenterName() {
    const renter = this.props.renter || {};
    return `${renter.first_name} ${renter.last_name}`;
  }

  getIsCoSigner() {
    const application = this.props.application || {};
    return application.applying_as_tenant === false;
  }

  getAddress() {
    const renter = this.props.renter || {};
    const listing = this.props.listing || {};
    if (listing?.id) {
      return getListingBreadcrumbName({
        listing,
        separator: ', ',
        showRootLevel: true,
      });
    }
    const application = this.props.application || renter.application || {};
    const address = get(application, 'listing.address', '');
    const unit = get(application, 'listing.unit', null);
    return formatAddress({ short: true, address, unit });
  }

  getApplicationDate = () => {
    const { renter } = this.props;
    const application = this.props.application || renter.application;
    const date = application?.submitted_date || application?.created_at;
    if (!date) {
      return '';
    }
    return getFormattedDateLocalTZ(date);
  };

  getButtonLabel() {
    const renter = this.props.renter || {};
    if (!isTenant(renter)) {
      return 'view applicant';
    } else {
      return 'view tenant';
    }
  }

  getButtonIdPrefix() {
    const renter = this.props.renter || {};
    if (!isTenant(renter)) {
      return 'view_applicant_';
    } else {
      return 'view_tenant_';
    }
  }

  getHasIdentityVerification() {
    const application = this.props.application || {};

    return application?.credit_check?.request_accepted || null;
  }

  getSubItems() {
    const subItems = [];
    const { styles, renter = {}, isPastData } = this.props;
    const application = this.props.application || renter.application;
    if (!isTenant(renter)) {
      const externalText = application.external ? '(External Application)' : '';
      const dateLabel = application.external
        ? 'DATE REQUESTED'
        : 'DATE SUBMITTED';
      subItems.push({
        text: (
          <>
            <span
              data-tip
              data-for={`renter-key-tip-${renter.id}`}
              data-event="mouseover touchstart"
              data-event-off="mouseout touchend"
            >
              {this.getAddress()}
            </span>
            <Tooltip
              {...css(styles.popover)}
              id={`renter-key-tip-${renter.id}`}
              effect="solid"
              place={'top'}
            >
              <span {...css(styles.popoverText)}>{this.getAddress()}</span>
            </Tooltip>
          </>
        ),
      });
      subItems.push({
        label: dateLabel,
        text: (
          <div {...css(styles.textContainer)}>
            {this.getApplicationDate()}{' '}
            <span {...css(styles.secondText)}>{externalText}</span>
          </div>
        ),
      });
    } else {
      if (!isPastData) {
        renter.leases.map((l) =>
          subItems.push({
            label: l.status === 'UPCOMING' ? 'UPCOMING LEASE' : 'CURRENT LEASE',
            text: <div {...css(styles.leaseTitle)}>{l.title}</div>,
          }),
        );
      } else {
        renter.past_leases.map((l) =>
          subItems.push({
            label: 'LEASE',
            text: <div {...css(styles.leaseTitle)}>{l.title}</div>,
          }),
        );
      }
    }
    return subItems;
  }

  renderLandlordPays() {
    const completed_at = this.props.application?.completed_at;
    const landlord_pays = this.props.application?.landlord_pays;
    let label = 'PAYABLE BY YOU';
    let paid = false;

    if (!completed_at && !landlord_pays) {
      label = 'PAYABLE BY RENTER';
    }

    if (completed_at && landlord_pays) {
      label = 'PAID BY YOU';
      paid = true;
    }

    if (completed_at && !landlord_pays) {
      label = 'PAID BY RENTER';
      paid = true;
    }

    return { label, paid };
  }

  render = () => {
    const { styles, key } = this.props;
    return (
      <ListItemCard
        key={key}
        subtitle={this.getRenterName()}
        subtitleTag={this.getIsCoSigner() ? 'Co-Signer' : ''}
        subItems={this.getSubItems()}
        buttonLabel={this.props.buttonLabel || this.getButtonLabel()}
        buttonAction={this.props.buttonAction}
        itemIndex={this.props.itemIndex}
        buttonDisabled={this.props.buttonDisabled}
        buttonIdPrefix={this.getButtonIdPrefix()}
        secondaryButtons={this.props.secondaryButtons}
        style={this.props.style}
        counter={this.props.renter && this.props.renter.unread_messages_count}
        customSubItemStyles={styles.customSubItemStyles}
        hasIdentityVerification={this.getHasIdentityVerification()}
        landlord_pays_label={this.renderLandlordPays()}
      />
    );
  };
}

export default withStyles(({ typography, layout, media }) => ({
  ...media.hideLarge,
  ...media.hideSmall,
  secondText: {
    ...typography.secondaryItalicText,
    paddingLeft: 5,
  },
  textContainer: {
    display: 'flex',
    ...layout.alignStartStart,
  },
  customSubItemStyles: {
    flexWrap: 'wrap',
  },
  leaseTitle: {
    textDecoration: 'underline',
    ...typography.defaultText,
    width: 310,
    paddingLeft: 3,
    ...media.xSmallScreen({
      width: '100%',
    }),
  },
  popover: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
    opacity: 1,
    color: 'white',
    maxWidth: 320,
    borderRadius: 8,
    backgroundColor: theme.colors.darkBlueGrey,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    whiteSpace: 'normal',
    ...media.smallScreen({
      maxWidth: 220,
    }),
  },
  popoverText: {
    wordBreak: 'break-word',
  },
}))(RenterCard);
