import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  Observable,
  split,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { CometChat } from '@cometchat-pro/chat';
import { print } from 'graphql/language/printer';
import { createClient as createWSClient } from 'graphql-ws';
import sha256 from 'hash.js/lib/hash/sha/256';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import pick from 'lodash.pick';
import moment from 'moment';

import browserHistory from '../browserHistory';
import { COOKIE_NAMES, getCookies, setCookie } from '../helpers/cookies';
import { hasUserFinishedPaymentsOnboarding } from '../helpers/hasUserFinishedPaymentsOnboarding';
import { shouldBeRedirectedToOnboardingSummary } from '../helpers/shouldBeRedirectedToOnboardingSummary';
import { TT_ACCOUNT_ID_HEADER } from '../providers/TTAccountProvider';
import { getItem, setItem } from '../services/localStorageService';
import { segmentIdentify } from '../services/utilities/segment';
import { isTurboListInstalled } from '../services/utilities/turboList';

import { logout } from './auth/authService';
import { apolloCache } from './apolloCache';

let segmentIdentifyAlreadySent = false;
let turboListInstalled = false;

isTurboListInstalled((status) => (turboListInstalled = status));
export const getClient = (GraphqlUrl, authHandler, config) => {
  const httpLink = new HttpLink({
    uri: GraphqlUrl,
  });

  const { IS_OWNER, IS_ADMIN, PRIVATE_BASE_PATH } = config;

  const getTokenString = () => {
    const params = new URLSearchParams(location.search);

    const passedToken = params.get('ttk') || null;
    const token = authHandler.getToken();
    const alternateToken = authHandler.getAlternateToken();

    let authToken = '';
    if (token) {
      authToken += `Bearer ${token}`;
    }
    if (alternateToken) {
      authToken += ` Alternate: ${alternateToken}`;
    }

    if (passedToken && !token) {
      console.log('passedToken', passedToken);
      setItem('auth_token', passedToken);
      authToken += `Bearer ${passedToken}`;
    }

    return authToken;
  };

  // For Redfin, because they can't use static IPs
  const { partner_stable_test_access_key } = getCookies();

  const setHeaders = async (operation) => {
    const opName = operation?.query?.definitions?.[0]?.operation;
    const ttAccountId = new URLSearchParams(
      browserHistory.location?.search,
    ).get('ttAccountId');

    operation.setContext({
      headers: {
        'authorization': getTokenString(),
        'x-graphql-operation': opName || '',
        'x-partner-stable-test-access-key': partner_stable_test_access_key,
        ...(ttAccountId && { [TT_ACCOUNT_ID_HEADER]: ttAccountId }),
      },
    });
  };

  const authMiddlewareNew = new ApolloLink(
    (operation, forward) =>
      new Observable((observer) => {
        let handle;
        Promise.resolve(operation)
          .then((oper) => setHeaders(oper))
          .then(() => {
            handle = forward(operation).subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
          })
          .catch(observer.error.bind(observer));

        return () => {
          if (handle) handle.unsubscribe();
        };
      }),
  );

  const pollingCacheMiddleware = new ApolloLink((operation, forward) => {
    if (
      [
        'basicProfileQuery',
        'tenantsQuery',
        'listingsQuery',
        'leadsQuery',
        'applicantsQuery',
        'leasesQuery',
        'settingsAndBillingQuery',
        'TenantRequestQuery',
        'LeaseRequestQuery',
        'ConversationQuery',
        'paymentRequestsQuery',
        'paymentRequestRulesQuery',
      ].indexOf(operation.operationName) === -1
    )
      return forward(operation);

    const result = apolloCache.diff({
      query: apolloCache.transformDocument(operation.query),
      variables: operation.variables,
      returnPartialData: true,
      optimistic: false,
    });
    const dataHash = get(
      result.result,
      `userData.${operation.operationName.slice(0, -5)}.dataHash`,
    );
    if (dataHash) {
      operation.extensions.pollingHash = dataHash;
    } else {
      const stringifiedCachedData = JSON.stringify(result.result);
      if (stringifiedCachedData && stringifiedCachedData.length > 5) {
        const hex = sha256()
          .update(JSON.stringify(result.result), 'utf8')
          .digest('hex');
        operation.extensions.pollingHash = hex;
      }
    }
    return forward(operation).map((response) => {
      if (JSON.stringify(response.data) === '{}') {
        return {
          data: result.result,
        };
      }
      return response;
    });
  });

  const siftScienceIdentify = (() => {
    const _sift = (window._sift = window._sift || []);
    let prevSiftUserId;

    return (user) => {
      let siftUserId;
      if (user === null && prevSiftUserId !== null) {
        siftUserId = '';
      } else if (user && user.is_admin) {
        siftUserId = '';
      } else if (user) {
        siftUserId = atob(user.entity_id);
      }

      if (siftUserId !== prevSiftUserId) {
        _sift.push(['_setUserId', siftUserId]);
        prevSiftUserId = siftUserId;
      }
    };
  })();

  const getUserInfoMiddleware = new ApolloLink((operation, forward) => {
    if (operation.operationName !== 'basicProfileQuery')
      return forward(operation);
    return forward(operation).map((response) => {
      const user = get(response, 'data.userData.basicProfile.data', undefined);
      siftScienceIdentify(user);
      if (user === null) {
        CometChat.logout().then(
          () => {
            console.log('Messaging Logout completed successfully');
          },
          (error) => {
            console.log('Messaging Logout failed with exception:', { error });
          },
        );
        logout();
      } else if (user) {
        const isCustomer = get(user, 'is_customer', false);
        const isCustomerStored = JSON.parse(getItem('isCustomer'));
        if (isCustomer !== isCustomerStored) {
          setItem('isCustomer', isCustomer);
        }
        if (!user.active && !user.is_admin) {
          browserHistory.replace('/auth/blocklisted');
        }
        if (window.analytics && !segmentIdentifyAlreadySent) {
          segmentIdentify(user.id, user.intercom_hash, { turboListInstalled });
          segmentIdentifyAlreadySent = true;
        }

        const features = get(response, 'data.viewer.features') || [];

        // check if the user is part of the lease agreement pricing experiment
        // and set the forms_exp cookie to the new pricing
        if (features.indexOf('FORMS_59') !== -1) {
          const oneYear = 1000 * 60 * 60 * 24 * 365 * 1; // 1 years
          setCookie(COOKIE_NAMES.FORM_EXP, 59, oneYear);
        }

        if (user?.application_fee === 45) {
          const oneYear = 1000 * 60 * 60 * 24 * 365 * 1; // 1 years
          setCookie(COOKIE_NAMES.APPLICATION_FEE_EXP, 'APPFEE_45', oneYear);
        }
        if (!user.intercom_hash) {
          // We need to identify why some users may have intercom issues
          window.atatus &&
            window.atatus.notify(new Error(`Missing intercom hash`), {
              user,
              token: getItem('auth_token'),
            });
        }
      }
      return response;
    });
  });

  const checkOnBoardingMiddleware = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const profile = get(response, 'data.userData.basicProfile.data', true);
      const process = get(profile, 'onboarding_data.process', '');
      const signup_platform = get(profile, 'signup_platform', '');

      /**
       * NOTE: Skip the redirects from bellow if the user
       * onboarded via mobile app and chose RENT_PAYMENTS as process
       */
      if (
        ['IOS', 'ANDROID'].includes(signup_platform) &&
        process === 'RENT_PAYMENTS'
      ) {
        return response;
      }

      if (process === 'RENTHOP') {
        browserHistory.replace('/renthop/onboarding/process');
      } else {
        const onboarding_completed = get(
          response,
          'data.userData.basicProfile.data.onboarding_completed',
          true,
        );
        const commonConditions = !!profile && IS_OWNER;
        /**
         * We have some users that could opt for RENT_PAYMENTS during the onboarding
         * process but they never ending setting up the account for payments, but they
         * could be using the product for other stuff like marketing, document storage,
         * leases, ect.
         * For those users, we don't want them to be redirected into the new payments
         * onboarding and force them to setup payments if they didn't want to do that originaly.
         * So we are checking when the account was created, if it was after the date we released the
         * new flow, then we redirect the user, otherwise we don't
         */
        const userHasOnboardedAfterNewOnboardingFlow = moment(
          profile?.created_at,
          'YYYY-MM-DD',
        ).isSameOrAfter(moment('12/16/2021', 'MM/DD/YYYY'));

        if (
          commonConditions &&
          !!onboarding_completed &&
          process === 'RENT_PAYMENTS' &&
          !profile.wants_offline_payments &&
          !hasUserFinishedPaymentsOnboarding(profile) &&
          userHasOnboardedAfterNewOnboardingFlow &&
          browserHistory.location.pathname.indexOf('payments/onboarding') === -1
        ) {
          if (shouldBeRedirectedToOnboardingSummary(profile)) {
            // this means that user went through all the steps but didn't click finish
            browserHistory.replace(`${PRIVATE_BASE_PATH}payments/onboarding/4`);
          } else {
            browserHistory.replace(`/onboarding/process`);
          }
        } else if (
          operation.operationName === 'BasicUserInfo' &&
          !onboarding_completed &&
          sessionStorage.getItem('alreadyRedirected') !== 'true' &&
          commonConditions &&
          browserHistory.location.pathname.indexOf('confirm_email') === -1
        ) {
          browserHistory.replace('/onboarding/process');
        }
      }
      return response;
    });
  });

  const trimCredsMiddleware = new ApolloLink((operation, forward) => {
    const fieldsToTrim = pick(operation.variables, ['first_name', 'last_name']);
    if (!isEmpty(fieldsToTrim)) {
      for (const field in fieldsToTrim) {
        try {
          fieldsToTrim[field] = fieldsToTrim[field].trim();
        } catch (e) {
          // ignore
        }
      }
      operation.variables = {
        ...operation.variables,
        ...fieldsToTrim,
      };
    }
    return forward(operation);
  });

  const errorMiddleware = onError(
    ({ response, networkError, graphQLErrors, operation }) => {
      const filteredErrors = [
        'NotIDMAVerifiedError',
        'PersistedQueryNotSupported',
        'PersistedQueryNotFound',
      ];
      console.debug('Error on operation', operation);
      // blocklisted
      if (networkError && networkError.statusCode === 403) {
        return browserHistory.replace(
          `/auth/blocklisted?closed=${
            networkError.result?.deleted_reason === 'ACCOUNT_CLOSED'
          }`,
        );
      }
      if (graphQLErrors) {
        const errorCode = get(graphQLErrors, '[0].code', '');
        const errorsMsg = graphQLErrors.map(({ message, locations, path }) => {
          console.debug(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
          return message;
        });
        if (errorCode === 415) {
          return browserHistory.replace('/reference-request-canceled');
        }
        if (errorCode === 499) {
          // BLOCKLISTED in phone verification
          return browserHistory.replace('/auth/blocklisted');
        }
        if (errorsMsg.indexOf('Unauthorized') >= 0) {
          authHandler.onUnauthorized(true);
          // if (response) response.errors = null; // consume
        } else if (filteredErrors.every((e) => errorsMsg.indexOf(e) < 0)) {
          const errorMsg = `On ${operation.operationName}: ${(
            errorsMsg || []
          ).join(', ')}`;
          window.atatus &&
            window.atatus.notify(new Error(errorMsg), operation.variables);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        if (response) response.errors = null; // consume
        if (networkError.statusCode === 401) {
          authHandler.onUnauthorized(true);
        } else if (networkError.statusCode === 503) {
          return browserHistory.replace('/maintenance');
        }
      }
    },
  );

  const activeUserMiddleware = new ApolloLink((operation, forward) => {
    const { pollingEnabled } = operation.getContext();
    if (
      !window.userIsActive &&
      operation.extensions.pollingHash &&
      pollingEnabled
    ) {
      const inactiveResponse = { data: {} };
      return new Observable((observer) => {
        operation.setContext({ response: inactiveResponse });
        observer.next(inactiveResponse);
        observer.complete();
        return inactiveResponse;
      });
    }
    return forward(operation);
  });

  const middlewares = [
    createPersistedQueryLink({
      generateHash: (param) => sha256().update(print(param)).digest('hex'),
    }),
    authMiddlewareNew,
    errorMiddleware,
  ];
  if (!IS_ADMIN) {
    middlewares.push(
      checkOnBoardingMiddleware,
      pollingCacheMiddleware,
      getUserInfoMiddleware,
      activeUserMiddleware,
      trimCredsMiddleware,
    );
  }
  middlewares.push(httpLink);

  const wsLink = new GraphQLWsLink(
    createWSClient({
      url: config.WS_URL,
      connectionParams: {
        authentication: getItem('auth_token'),
      },
      shouldRetry: () => true,
    }),
  );

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      if (config.USER_TYPE !== 'OWNER' || config.WS_URL === 'false') {
        return false;
      }
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    from(middlewares),
  );

  return new ApolloClient({
    link: splitLink,
    cache: apolloCache,
  });
};
