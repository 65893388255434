import React from 'react';
import ReactPaginate from 'react-paginate';
// import ReactPaginate from 'react-paginate';
import { css, withStyles } from 'react-with-styles';
import PropTypes from 'prop-types';

import LeftArrow from '../icons/UIExperience/left-arrow';
import RightArrow from '../icons/UIExperience/right-arrow';
require('../assets/pagination.scss');

class Pagination extends React.Component {
  getLabel(Icon) {
    return (
      <span>
        <Icon
          style={{
            width: 8,
            height: 12,
          }}
        />
      </span>
    );
  }
  handlePageChange = ({ selected }) => {
    this.props.onPageChange(selected);
  };
  render() {
    const {
      styles,
      pageRangeDisplayed,
      marginPagesDisplayed,
      pageCount,
      currentPage,
    } = this.props;
    return (
      <ReactPaginate
        pageCount={pageCount}
        forcePage={currentPage}
        onPageChange={this.handlePageChange}
        marginPagesDisplayed={marginPagesDisplayed}
        pageRangeDisplayed={pageRangeDisplayed}
        nextLabel={this.getLabel(RightArrow)}
        previousLabel={this.getLabel(LeftArrow)}
        pageClassName={css(styles.pageButton).className}
        containerClassName={css(styles.container).className}
        nextClassName={css(styles.nextButton).className}
        previousClassName={css(styles.previousButton).className}
        breakClassName={css(styles.break).className}
        activeClassName="pagination-page-active"
        pageLinkClassName={css(styles.pageLink).className}
        previousLinkClassName={css(styles.pageLink).className}
        nextLinkClassName={css(styles.pageLink).className}
      />
    );
  }
}

Pagination.defaultProps = {
  pageRangeDisplayed: 5,
  marginPagesDisplayed: 2,
};

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
};

export default withStyles(() => ({
  container: {
    padding: 0,
    display: 'flex',
    listStyle: 'none',
    fontWeight: 'bold',
    width: 'max-content',
    backgroundColor: '#FFFFFF',
  },
  pageButton: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '16px',
    justifyContent: 'center',
    lineHeight: '22px',
    height: '40px',
    textAlign: 'center',
    width: '38px',
    borderTop: '1px solid #E0E0E0',
    borderLeft: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
  },
  nextButton: {
    cursor: 'pointer',
    width: '38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #E0E0E0',
  },
  previousButton: {
    cursor: 'pointer',
    width: '38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTop: '1px solid #E0E0E0',
    borderLeft: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
  },
  break: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '38px',
    borderTop: '1px solid #E0E0E0',
    borderLeft: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
  },
  pageLink: {
    width: '100%',
    height: '100%',
    lineHeight: '38px',
    textAlign: 'center',
  },
}))(Pagination);
