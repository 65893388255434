import React from 'react';
import { any, bool, func, object, string } from 'prop-types';

import LoadingScreen from '../../components/LoadingScreen';
import { TextButton } from '../Button';
import BackArrow from '../icons/arrows/arrow-left-7';
import { Row } from '../Layout';
import {
  isAtLeastSmallScreen,
  isAtLeastXMediumScreen,
} from '../styles/media-queries';
import { css, withStyles } from '../styles/with-styles';

/**
 main column always takes available space
 right column has a fixed size per screen size

 PLEASE DONT MANIPULATE WIDTHS TO MEET THE MOCKS - TRY TO REUSE ONE - IF IT'S TOO DIFFERENT ADD A NEW SECOND_COLUMN CONFIG
 */
class LayoutTwoColumns extends React.Component {
  static propTypes = {
    style: object,
    styles: object,
    children: any,
    topContent: object, // a component that occupies 2 cols
    secondaryContent: object,
    secondaryStyles: object,
    secondaryContentStyles: string,
    loading: bool,
    asCard: bool,
    asCardTransparent: bool,
    responsiveBreakpoint: string,
    backButtonLabel: string,
    backButtonAction: func,
    headerExtraContent: object,
    contentContainerStyles: object,
  };

  static defaultProps = {
    responsiveBreakpoint: 'small',
  };

  showSecondColumn = () => {
    const { responsiveBreakpoint } = this.props;
    if (responsiveBreakpoint === 'medium') {
      return isAtLeastSmallScreen();
    }
    if (responsiveBreakpoint === 'xMedium') {
      return isAtLeastXMediumScreen();
    }
    return isAtLeastSmallScreen();
  };

  render() {
    const {
      styles,
      containerStyles,
      loading,
      secondaryContent,
      asCard,
      asCardTransparent,
      topContent,
      headerExtraContent,
      secondaryContentStyles,
      responsiveBreakpoint,
      backButtonLabel,
      backButtonAction,
      contentContainerStyles,
      secondaryStyles,
    } = this.props;

    const breakpoint = responsiveBreakpoint || 'small';
    const secondColumnStyles = styles['secondColumn_' + secondaryContentStyles];
    const secondColumnBPStyles = styles['second_breakpoint_' + breakpoint];
    const firstColumnBPStyles = styles['first_breakpoint_' + breakpoint];
    const showSecondColumn = this.showSecondColumn();

    return (
      <div {...css(styles.layoutTwoColumnsContainer, containerStyles)}>
        {backButtonLabel && (
          <Row horizontal="spaced">
            <TextButton
              onClick={backButtonAction}
              label={backButtonLabel}
              style={{
                display: 'block',
                marginTop: 2,
                marginLeft: 10,
                marginBottom: 15,
              }}
              icon={BackArrow}
            />
            {headerExtraContent}
          </Row>
        )}
        <LoadingScreen loading={loading} style={{ width: '100%' }}>
          <div
            {...css(
              asCard && styles.contentContainerContainer,
              asCardTransparent && styles.contentContainerContainerTransparent,
              contentContainerStyles,
            )}
          >
            {!!topContent && (
              <div {...css(styles.topContent)}>{topContent}</div>
            )}
            <div {...css(styles.columnsContainer)}>
              <div {...css(styles.firstColumn, firstColumnBPStyles)}>
                {this.props.children}
              </div>
              {showSecondColumn && <div {...css(styles.spaceBetweenColumns)} />}
              {showSecondColumn && (
                <div
                  {...css(
                    secondColumnStyles,
                    secondColumnBPStyles,
                    secondaryStyles,
                  )}
                >
                  {secondaryContent}
                </div>
              )}
            </div>
          </div>
        </LoadingScreen>
      </div>
    );
  }
}

export default withStyles(({ layout, colors, media }) => ({
  layoutTwoColumnsContainer: {
    // ...layout.column
  },
  contentContainerContainer: {
    backgroundColor: colors.white,
    border: '1px solid',
    borderColor: colors.lightGrey,
    borderRadius: '4px',
    padding: 32,
    ...media.mediumScreen({
      padding: 24,
    }),
    ...media.smallScreen({
      padding: 16,
    }),
    ...media.xSmallScreen({
      padding: 8,
    }),
  },
  contentContainerContainerTransparent: {
    padding: 32,
    ...media.mediumScreen({
      padding: 24,
    }),
    ...media.smallScreen({
      padding: 16,
    }),
    ...media.xSmallScreen({
      padding: 8,
    }),
  },
  columnsContainer: {
    ...layout.row,
    ...media.smallScreen({
      flexWrap: 'wrap',
    }),
  },
  columnsContainer_medium: {
    ...layout.row,
    ...media.tillLargeScreen({
      flexWrap: 'wrap',
    }),
  },
  topContent: {
    // flex: 1,
    width: '100%',
  },
  first_breakpoint_small: {
    ...media.smallScreen({
      width: '100%',
      minWidth: '100%',
      marginRight: 0,
    }),
  },
  first_breakpoint_medium: {
    minWidth: 400,
    ...media.tillLargeScreen({
      width: '100%',
      minWidth: '100%',
      marginRight: 0,
    }),
  },
  second_breakpoint_medium: {
    ...media.tillLargeScreen({
      width: '100%',
      minWidth: '100%',
    }),
  },
  spaceBetweenColumns: {
    flex: '0 1',
    ...media.mediumScreen({
      width: 16,
      minWidth: 16,
    }),
    ...media.largeScreen({
      width: 24,
      minWidth: 24,
    }),
    ...media.xlScreen({
      width: 32,
      minWidth: 32,
    }),
  },
  firstColumn: {
    flex: 1,
  },
  secondColumn_big: {
    ...media.mediumScreen({
      width: 304,
      minWidth: 304,
    }),
    ...media.largeScreen({
      width: 380,
      minWidth: 380,
    }),
    ...media.xlScreen({
      width: 420,
      minWidth: 420,
    }),
  },
  secondColumn_small: {
    ...media.mediumScreen({
      width: 284,
      minWidth: 284,
    }),
    ...media.largeScreen({
      width: 340,
      minWidth: 340,
    }),
    ...media.xlScreen({
      width: 420,
      minWidth: 420,
    }),
  },
  secondColumn_xsmall: {
    ...media.mediumScreen({
      width: 212,
      minWidth: 212,
    }),
    ...media.largeScreen({
      width: 304,
      minWidth: 304,
    }),
    ...media.xlScreen({
      width: 420,
      minWidth: 420,
    }),
  },
}))(LayoutTwoColumns);
