import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ZillowLogo = (props) => (
  <svg {...props} viewBox="0 0 96 20">
    <g fill="#006aff">
      <path d="M59.8 13.5c0-3.7 3-6.4 6.6-6.4 3.6 0 6.6 2.7 6.6 6.4s-3 6.4-6.6 6.4c-3.6 0-6.6-2.7-6.6-6.4zM37.4 2.8v.7c0 .3-.1.4-.1.5l-7 11.9h7.5v3.6H24.4v-.7c0-.3 0-.4.2-.7l6.9-11.8h-6.9V2.8h12.8zm43.3 16.7h-3.6L73.2 7.4H77l1.1 3.7c.4 1.2 1 3.5 1 3.5h.1s.6-2.3 1-3.5l1.2-3.7h2.8l1.2 3.7c.4 1.3 1 3.5 1 3.5h.1s.6-2.3 1-3.5l1.1-3.7h3.6l-3.9 12.1h-3.5l-1-3c-.4-1.2-1-3.5-1-3.5h-.1s-.7 2.3-1 3.5l-1 3zm-36.5 0h-3.9V7.4h3.9v12.1zm3-18.5h3.9v18.5h-3.9V1zM54 1h3.9v18.5H54V1zm15.2 12.5c0-1.8-1.3-3-2.8-3-1.6 0-2.8 1.2-2.8 3s1.3 3 2.8 3c1.5 0 2.8-1.2 2.8-3zm24.9-9c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5zm0 2.7c-.6 0-1.2-.5-1.2-1.2 0-.6.5-1.2 1.2-1.2.6 0 1.2.5 1.2 1.2 0 .6-.6 1.2-1.2 1.2zm.1-2.1c.3 0 .6.2.6.6 0 .3-.1.5-.4.6l.3.5h-.3l-.3-.5h-.3v.5h-.3V5.1h.7zm-.1.3h-.3v.7h.3c.2 0 .3-.1.3-.3.1-.3-.1-.4-.3-.4zm-51.9-4c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2zM5.3 17.5h-.2l-1.7-2v-.2c1.3-1.9 3.9-4.8 5.6-6.1-1.7.6-6.7 2.7-8.5 3.7v6.7h17.8v-6.5c-2.4.5-9.7 2.7-13 4.4zm7.2-12.1c.1 0 .1 0 .2.1.3.3 1.3 1.5 1.6 1.8v.2c-2 1.6-4.2 3.8-5.4 5.3 2.2-.9 7.2-2.4 9.5-2.8V7.1l-9-7-8.9 7v3.2c2.7-1.7 9.1-4.2 12-4.9z" />
    </g>
  </svg>
);

ZillowLogo.defaultProps = {
  width: 96,
  height: 20,
};

ZillowLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(ZillowLogo);
