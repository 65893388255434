import React from 'react';
import { css, withStyles } from 'react-with-styles';
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
// import aphroditeInterface from 'react-with-styles-interface-aphrodite/no-important';
import WithStylesContext from 'react-with-styles/lib/WithStylesContext';
import aphroditeInterface from 'react-with-styles-interface-aphrodite';

import Theme from './tt.theme';

ThemedStyleSheet.registerInterface(aphroditeInterface);
ThemedStyleSheet.registerTheme(Theme);

// I guess we wont' need it, but I'm exposing this because ThemedStylesheet is going to be deprecated
const withStylesProvider = (props) => (
  <WithStylesContext.Provider
    value={{
      stylesInterface: aphroditeInterface,
      stylesTheme: Theme,
    }}
  >
    {props.children}
  </WithStylesContext.Provider>
);

export { css, withStyles, ThemedStyleSheet, withStylesProvider };
