import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export default class ImageItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageIndex: 0,
      parent: null,
    };
  }

  static propTypes = {
    style: PropTypes.object,
    imageUrl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    rotation: PropTypes.number,
    className: PropTypes.string,
    avoidCache: PropTypes.bool,
    onError: PropTypes.func,
    autofill: PropTypes.bool, // will fill the image to the parent (needs to have position: relative )
  };

  getUrlAvoidCache(url) {
    return url + '?' + new Date().getTime();
  }

  componentDidMount() {
    // TODO: get rid of findDOMNode
    // eslint-disable-next-line react/no-find-dom-node
    this.setState({ parent: ReactDOM.findDOMNode(this).parentNode });
    this.interval = setInterval(() => {
      if (
        this.imageRef &&
        this.imageRef.naturalWidth &&
        this.imageRef.naturalHeight
      ) {
        // we check for image size (it happens before fully load) and adjust the bounds
        clearInterval(this.interval);
        this.forceUpdate();
      }
    }, 250);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onError = () => {
    if (
      Array.isArray(this.props.imageUrl) &&
      this.props.imageUrl.length > this.state.imageIndex + 1
    ) {
      this.setState({
        imageIndex: this.state.imageIndex + 1,
      });
    } else {
      clearInterval(this.interval);
    }
  };

  render() {
    const {
      style = {},
      avoidCache = false,
      imageUrl,
      className,
      rotation = 0,
      autofill,
      ...ownProps
    } = this.props;

    ['theme', 'css', 'autoFill'].forEach((field) => {
      delete ownProps[field];
    });

    let photoUrl = Array.isArray(imageUrl)
      ? imageUrl[this.state.imageIndex]
      : imageUrl;
    photoUrl = photoUrl
      ? avoidCache
        ? this.getUrlAvoidCache(photoUrl)
        : photoUrl
      : undefined; // To avoid caching

    const { transform, ...filteredStyles } = style;
    const imageStyle = {
      ...filteredStyles,
      transform: `${transform || ''} rotate(${-90 * rotation}deg)`,
    };

    if (autofill) {
      this.getAutoFillStyles(rotation, imageStyle);
    }

    return (
      <img
        src={photoUrl}
        onLoad={this.onImgLoad}
        ref={(input) => {
          this.imageRef = input;
        }}
        className={className}
        style={imageStyle}
        onError={this.onError}
        {...ownProps}
      />
    );
  }

  getAutoFillStyles(rotation, imageStyle) {
    const currentImageWidth = this.imageRef && this.imageRef.naturalWidth;
    const currentImageHeight = this.imageRef && this.imageRef.naturalHeight;

    if (currentImageWidth && currentImageHeight) {
      const { clientWidth: targetWidth, clientHeight: targetHeight } =
        this.state.parent || {};
      const isRotated = rotation % 2 === 1;
      const actualWidth = !isRotated ? currentImageWidth : currentImageHeight;
      const actualHeight = !isRotated ? currentImageHeight : currentImageWidth;
      const scale =
        Math.max(
          targetWidth / (actualWidth || 1),
          targetHeight / (actualHeight || 1),
        ) || 1;

      const scaledWidth = Math.round(actualWidth * scale);
      const scaledHeight = Math.round(actualHeight * scale);

      if (!isRotated) {
        if (scaledWidth > targetWidth) {
          // adjust by height
          imageStyle.height = targetHeight; // 100%
          imageStyle.width = 'auto'; // should be scaledWidth
          imageStyle.top = 0;
          imageStyle.left = -(scaledWidth - targetWidth) / 2;
        } else {
          // adjust by width
          imageStyle.height = 'auto'; // should be scaledHeight
          imageStyle.width = targetWidth; // 100%
          imageStyle.top = -(scaledHeight - targetHeight) / 2;
          imageStyle.left = 0;
        }
      } else {
        if (scaledWidth > targetWidth) {
          // adjust by width, with the height
          imageStyle.height = 'auto';
          imageStyle.width = targetHeight;
          imageStyle.top = -(scaledWidth - targetHeight) / 2;
          imageStyle.left = 0;
        } else {
          imageStyle.height = targetWidth; // adjust by width, with the height
          imageStyle.width = 'auto';
          imageStyle.top = 0;
          imageStyle.left = -(scaledHeight - targetWidth) / 2;
        }
      }
      imageStyle.position = 'absolute';
      imageStyle.objectFit = 'cover';
    } else {
      // until it loads - this is the best we can do
      imageStyle.height = '100%';
      imageStyle.width = '100%';
      imageStyle.top = 0;
      imageStyle.left = 0;
      imageStyle.position = 'absolute';
      imageStyle.objectFit = 'cover';
    }
  }
}
