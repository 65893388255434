import React from 'react';
import PropTypes from 'prop-types';

import { AddButton } from '../Button';
import ProfileLogo from '../icons/people/profile-logo';
import PlusIcon from '../icons/remove-add/add-1';
import { css } from '../styles/with-styles';

class InviteCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inviteModal: false,
    };
  }
  static propTypes = {
    styles: PropTypes.object,
    showAddIcon: PropTypes.bool,
    text: PropTypes.string,
    size: PropTypes.string,
    containerStyles: PropTypes.object,
    iconSize: PropTypes.object,
    inviteHandler: PropTypes.func,
    mainIcon: PropTypes.object,
  };
  render() {
    const {
      text,
      containerStyles,
      inviteHandler,
      showAddIcon,
      mainIcon = ProfileLogo,
      size = 'smallFullWidth',
      iconSize,
    } = this.props;

    return (
      <div {...css(containerStyles)}>
        <AddButton
          onClick={(e) => inviteHandler(e)}
          label={text}
          icon={mainIcon}
          size={size}
          showAddIcon={showAddIcon}
          iconSize={iconSize}
          iconButton={PlusIcon}
        />
      </div>
    );
  }
}

InviteCard.defaultProps = {
  icon: PropTypes.node.isRequired,
  text: 'Invite to apply',
  containerStyles: {},
  inviteHandler: () => ({}),
};

export default InviteCard;
