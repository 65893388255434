import React from 'react';
import cx from 'classnames';

import Content from '././components/Content';
import AvatarCard from './components/AvatarCard';
import Button from './components/Button';
import Cube from './components/Cube';
import Rectangle from './components/Rectangle';
import Section from './components/Section';
import Zebra from './components/Zebra';

import styles from './Skeleton.module.scss';

const Skeleton = ({ children, className, spacing, hybrid, transparent }) => (
  <div
    className={cx(className, styles.skeleton, styles[`spaced-${spacing}`], {
      [styles.hybrid]: hybrid,
      [styles.transparent]: transparent,
    })}
  >
    {children}
  </div>
);

Skeleton.Content = Content;
Skeleton.Cube = Cube;
Skeleton.Button = Button;
Skeleton.Section = Section;
Skeleton.Rectangle = Rectangle;
Skeleton.Zebra = Zebra;
Skeleton.AvatarCard = AvatarCard;

export default Skeleton;
