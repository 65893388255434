import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

let VideosControlPlay = (props) => (
  <SvgIcon {...props}>
    <path
      stroke={props.color}
      fill={props.fill}
      strokeWidth={props.strokeWidth || 1.25}
      fillOpacity={props.fill ? '1' : '0'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M.5.5l23 11.5-23 11.5z"
    />
  </SvgIcon>
);
VideosControlPlay = memo(VideosControlPlay);
VideosControlPlay.displayName = 'VideosControlPlay';
VideosControlPlay.muiName = 'SvgIcon';

export default VideosControlPlay;
