import React from 'react';

import { css, withStyles } from '../styles/with-styles';

const _PageContainer = ({ children, withStylesStyles, theme, ...rest }) => (
  <div {...rest} style={{ height: '100%' }}>
    <div {...css(withStylesStyles.layout)}>{children}</div>
  </div>
);
export default withStyles(
  () => ({
    layout: {
      position: 'relative',
      width: '100%',
      padding: 0,
      flex: 1,
      marginTop: 0,
      backgroundColor: '#FFF',
      //overflow: auto,
      minHeight: '100%',
      maxHeight: '100%',
      height: '100%',
    },
  }),
  { stylesPropName: 'withStylesStyles' },
)(_PageContainer);
