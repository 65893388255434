import React from 'react';
import { any, bool, func, object, string } from 'prop-types';

import LoadingScreen from '../../components/LoadingScreen';
import { TextButton } from '../Button';
import BackArrow from '../icons/arrows/arrow-left-7';
import { css, withStyles } from '../styles/with-styles';

class MediumCardCenter extends React.Component {
  static propTypes = {
    id: string,
    styles: object,
    children: any,
    secondaryContent: object,
    loading: bool,
    size: string,
    backButtonLabel: string,
    backButtonAction: func,
  };

  render() {
    const {
      styles,
      loading,
      children,
      backButtonLabel,
      backButtonAction,
      size,
      secondaryContent,
      id,
    } = this.props;
    const idProps = id ? { id } : {};
    return (
      <div {...css(styles.mainContainer)} {...idProps}>
        {backButtonLabel && (
          <TextButton
            onClick={backButtonAction}
            label={backButtonLabel}
            icon={BackArrow}
          />
        )}

        <div {...css(styles.contentContainer)}>
          {secondaryContent && (
            <div {...css(styles.secondaryContentContainer)}>
              {secondaryContent}
            </div>
          )}
          <div
            {...css(
              styles.mainContentContainer,
              size === 'large' && styles.largeCardContainer,
            )}
          >
            <LoadingScreen loading={loading}>{children}</LoadingScreen>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(({ layout, media }) => ({
  ...media.hideSmall,
  mainContainer: {
    position: 'relative',
    ...layout.column,
    minHeight: '100%',
    overflow: 'auto',
  },
  contentContainer: {
    paddingTop: 24,
    width: '100%',
    margin: 'auto',
    marginTop: 0, // dont center
    ...layout.row,
    ...media.smallScreen({
      paddingTop: 12,
    }),
  },
  mainContentContainer: {
    flex: 1,
    maxWidth: 385,
    margin: 'auto',
  },
  secondaryContentContainer: {
    display: 'none',
    ...media.largeScreen({
      display: 'block',
      flex: 1,
    }),
  },
  largeCardContainer: {
    maxWidth: '100%',
  },
}))(MediumCardCenter);
