import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';

import img from '../../assets/reihub/mobile-acc-nav.png';
import reiHubSsoQuery from '../../graphql/queries/partner_sso/getReiHubSSO.graphql';
import CheckmarkFilled from '../../icons/CheckMarkFilled';
import { segmentTracking } from '../../services/utilities';
import Button from '../Button';
import Card from '../Card';
import FlatButton from '../FlatButton';
import NumberedList from '../NumberedList';

import styles from './REIHubSubscriptionSuccess.module.scss';

const REIHubSubscriptionSuccess = ({
  onClose,
  isYearlySubscription,
  className,
}) => {
  const [showFinalHint, setShowFinalHint] = useState(false);

  const [getSSOUrl] = useLazyQuery(reiHubSsoQuery, {
    onCompleted: (data) => {
      segmentTracking('go_to_reihub clicked', {
        location: 'reihub purchase success screen ',
      });
      if (data?.reiHub?.rei_hub_sso) {
        window.open(data.reiHub.rei_hub_sso, '_blank');
        onClose && onClose();
      }
    },
  });

  return showFinalHint ? (
    <Card className={cx(styles.finalHintCard, className)}>
      <img src={img} alt={'REIHub mobile navigation'} />
      <h3>You can access REI Hub in the future by clicking “Accounting”</h3>
      <Button
        className={styles.actionButton}
        primary
        onClick={() => onClose?.()}
      >
        GO TO MY DASHBOARD
      </Button>
    </Card>
  ) : (
    <Card className={cx(styles.card, className)}>
      <div className={styles.banner}>
        <CheckmarkFilled color={'#04BF8D'} width={80} height={80} />
      </div>
      <div className={styles.content}>
        <h5>Success! Here’s what’s next:</h5>
        <NumberedList
          variant={'bold'}
          size={'large'}
          list={[
            'Click below to setup REI Hub.',
            "We'll automatically send your properties and rent payment information from TurboTenant.",
            "In REI Hub, you'll set settings, review the information we sent over, as well as link any banks and/or credit cards with transactions you want to track.",
            `Any changes to your property count will be reflected in your ${
              isYearlySubscription ? 'yearly' : 'monthly'
            } subscription price.`,
          ]}
          className={styles.itemClassName}
        />
        <Button
          className={styles.actionButton}
          primary
          onClick={() => getSSOUrl()}
        >
          GO TO REI HUB
        </Button>
        <FlatButton
          className={styles.setupLaterBtn}
          onClick={() => {
            segmentTracking('setup_reihub_later clicked', {
              location: 'reihub purchase success screen ',
            });

            if (window.location.pathname === '/owners/dashboard') {
              onClose?.();
              return;
            }

            setShowFinalHint(true);
          }}
        >
          I’LL SET UP REI HUB LATER
        </FlatButton>
      </div>
    </Card>
  );
};

REIHubSubscriptionSuccess.propTypes = {
  isYearlySubscription: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default REIHubSubscriptionSuccess;
