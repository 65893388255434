import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ApartmentAdvisorLogo from '../../icons/ApartmentAdvisorLogo';
import ApartmentList from '../../icons/ApartmentListLogo';
import Apartments from '../../icons/ApartmentsLogo';
import CollegeStudentApartmentsLogo from '../../icons/CollegeStudentApartmentsLogo';
import Craigslist from '../../icons/CraigslistLogo';
import DwellsyLogo from '../../icons/DwellsyLogo';
import FacebookMarketplaceLogo from '../../icons/FacebookMarketplaceLogo';
import Homes from '../../icons/HomesLogo';
import Realtor from '../../icons/RealtorLogo';
import RedfinLogo from '../../icons/RedfinLogo';
import RentalSourceLogo from '../../icons/RentalSourceLogo';
import RentberryLogo from '../../icons/RentberryLogo';
import SpareRoomLogo from '../../icons/SpareRoomLogo';
import UloopLogo from '../../icons/UloopLogo';
import ZillowLogo from '../../icons/ZillowLogo';
import ZumperLogo from '../../icons/ZumperLogo';
import RenthopLogo from '../../pages/onboard/renthop/RenthopLogo';
import TTLogoIcon from '../Logo';

import styles from './PartnerSourceLogo.module.scss';

const logoMapping = {
  zillow: ZillowLogo,
  facebook: FacebookMarketplaceLogo,
  apartments: Apartments,
  apartment_list: ApartmentList,
  realtor: Realtor,
  homes: Homes,
  craigslist: Craigslist,
  mp_uloop: UloopLogo,
  mp_college_rentals: CollegeStudentApartmentsLogo,
  mp_dwellsy: DwellsyLogo,
  mp_zumper: ZumperLogo,
  rentberry: RentberryLogo,
  spareroom: SpareRoomLogo,
  mp_rental_source: RentalSourceLogo,
  renthop: RenthopLogo,
  mp_rent_path: RedfinLogo,
  apartment_advisor: ApartmentAdvisorLogo,
  default: TTLogoIcon,
};

const PartnerSourceLogo = ({ className, source }) => {
  let matchKey = '';

  for (const key in logoMapping) {
    if (source.includes(key)) {
      matchKey = key;
      break;
    }
  }

  !matchKey && (matchKey = 'default');

  const LogoComponent = logoMapping[matchKey];

  return (
    <div className={cx(className, styles.container)}>
      <LogoComponent className={styles.logo} />
    </div>
  );
};

PartnerSourceLogo.propTypes = {
  className: PropTypes.string,
  source: PropTypes.string,
};

export default PartnerSourceLogo;
