import React from 'react';
import PropTypes from 'prop-types';

import propertyPlaceholderImg from '../assets/rental-property.svg';
import { Column, Row } from '../Layout';
import ImageItem from '../PhotoUploader/ImageItem';
import { getPropertyStatus } from '../services/utilities';
import { isSmallScreen } from '../styles/media-queries';
import { css, withStyles } from '../styles/with-styles';
import { ThemedStyleSheet } from '../styles/with-styles';

const theme = ThemedStyleSheet.get();

class PropertyListingCard extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    addressFirstLine: PropTypes.string,
    addressSecondLine: PropTypes.string,
    acceptingApplicants: PropTypes.bool,
    marketingOnline: PropTypes.bool,
    marketingOnlineDays: PropTypes.number,

    imageUrl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    rotation: PropTypes.number,

    buttonAction: PropTypes.func,
    buttonDisabled: PropTypes.bool,
    buttonIdPrefix: PropTypes.string,
    itemIndex: PropTypes.number,
    secondaryButtons: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any,
        action: PropTypes.func,
      }),
    ),
    styles: PropTypes.object,
    rentAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    draftProperty: PropTypes.bool,
    isSlimProperty: PropTypes.bool,
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowDimensionsUpdated);
  }
  componentDidMount() {
    window.addEventListener('resize', this.onWindowDimensionsUpdated);
  }

  onWindowDimensionsUpdated = () => {
    this.forceUpdate(); // need this because of the shouldComponentUpdate
  };

  shouldComponentUpdate(nextProps) {
    return this.props.shouldUpdateRef !== nextProps.shouldUpdateRef; // || this.state !== nextState;
  }

  render = () => {
    const {
      styles,
      imageUrl,
      rotation,
      title,
      addressFirstLine,
      addressSecondLine,
      secondaryButtons,
      acceptingApplicants,
      marketingOnline,
      rentAmount,
      buttonAction,
      draftProperty,
      zillowStatus,
      marketingOnlineDays,
      isSlimProperty = false,
    } = this.props;

    const getImgItemStyles = () => {
      const base = {
        cursor: 'pointer',
        width: 127,
      };
      if (isSlimProperty && !isSmallScreen()) {
        return { ...base, height: 135 };
      }
      return { ...base, height: 177 };
    };

    const getMarketingBadgeStyles = () => {
      if (marketingOnline) {
        switch (zillowStatus) {
          case 'UNVERIFIED':
          case 'PENDING':
          case 'REQUESTED_REVIEW_VIOLATION':
          case 'REQUESTED_REVIEW_DOCUMENTS':
            return styles.marketingPendingUnverified;
          default:
            return styles.marketingOnline;
        }
      }
      return styles.notAcceptingApplicants;
    };

    const imgItemStyle = getImgItemStyles();

    return (
      <Row {...css(styles.card)} onSmall="column" id="propertyListingCard">
        <Column
          {...css(styles.containerClickable, styles.imageContainer)}
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          onClick={buttonAction}
        >
          <ImageItem
            imageUrl={imageUrl || propertyPlaceholderImg}
            rotation={rotation}
            autofill={!!imageUrl}
            onClick={buttonAction}
            style={imgItemStyle}
          />
        </Column>
        <Column vertical="spaced" flexGrow={1}>
          <Column
            {...css(styles.containerClickable, {
              padding: 16,
              paddingBottom: 0,
            })}
            onClick={buttonAction}
          >
            <Row wrap horizontal="spaced" onSmall="column" flexGrow={1}>
              <Column {...css(draftProperty && styles.draftTitle)}>
                <span {...css({ ...theme.typography.publicSectionTitle })}>
                  {!draftProperty ? title : addressFirstLine}
                </span>
                <span {...css({ ...theme.typography.defaultText })}>{`${
                  draftProperty ? '' : addressFirstLine
                } ${addressSecondLine}`}</span>
              </Column>

              <Row vertical="start" noWrap>
                <Row
                  vertical="center"
                  horizontal="end"
                  noWrap
                  {...css(styles.rentAmount)}
                >
                  <span
                    {...css({
                      ...styles.rentAmountLabel,
                      ...theme.typography.defaultText,
                      ...theme.typography.semiboldText,
                    })}
                  >
                    RENT:
                  </span>{' '}
                  <span
                    {...css({
                      fontSize: 18,
                      lineHeight: '27px',
                      marginLeft: 4,
                    })}
                  >
                    ${rentAmount}
                  </span>
                </Row>
              </Row>
            </Row>
            <Row
              horizontal="spaced"
              noWrap
              {...css(styles.butonsContainer)}
              onSmall="column"
              flexGrow={1}
            >
              <Row wrap {...css(styles.smallButonsContainer)} onSmall="column">
                {!draftProperty && (
                  <div style={{ display: 'inherit' }}>
                    <Column
                      vertical="center"
                      horizontal="center"
                      {...css(
                        styles.applicantsButton,
                        acceptingApplicants
                          ? styles.acceptingApplicants
                          : styles.notAcceptingApplicants,
                        styles.applicantsBtn,
                      )}
                    >
                      {acceptingApplicants
                        ? 'Accepting Applications'
                        : 'Not Accepting Applications'}
                    </Column>
                  </div>
                )}
                <div
                  style={{ display: 'inherit' }}
                  {...css(styles.marketingOnlineWrapper)}
                >
                  <Column
                    vertical="center"
                    horizontal="center"
                    noWrap
                    {...css(
                      styles.applicantsButton,
                      getMarketingBadgeStyles(),
                      styles.marketingBtn,
                    )}
                  >
                    {getPropertyStatus(
                      zillowStatus,
                      marketingOnline,
                      marketingOnlineDays,
                    )}
                  </Column>
                </div>
              </Row>
              <Column
                horizontal="end"
                {...css(
                  !draftProperty
                    ? styles.manageButtonContainer
                    : styles.draftButtonContainer,
                )}
              ></Column>
            </Row>
          </Column>
          {secondaryButtons && (
            <Row
              horizontal="spaced"
              {...css(styles.secondaryButtons)}
              onSmall="column"
            >
              {secondaryButtons.map((button, index) => (
                <Column
                  horizontal="center"
                  vertical="center"
                  key={`secondaryButton_${index}`}
                  {...css(
                    styles.divButton,
                    index > 0 && styles.divButtonSeparator,
                    button.disabled && styles.divButtonDisabled,
                  )}
                  onClick={button.action}
                  disabled={button.disabled}
                >
                  {button.label}
                </Column>
              ))}
            </Row>
          )}
        </Column>
      </Row>
    );
  };
}

export default withStyles(({ colors, typography, media }) => ({
  ...media.hideLarge,
  ...media.hideSmall,
  card: {
    border: '1px solid',
    borderRadius: 4,
    boxSizing: 'border-box',
    borderColor: colors.lightGrey,
    backgroundColor: colors.white,
    minWidth: 500,
    minHeight: 135,
    overflow: 'hidden',
    ...media.smallScreen({
      minWidth: 240,
    }),
  },
  imageContainer: {
    position: 'relative',
    minWidth: 192,
    maxWidth: 192,
    width: 192,
    borderStyle: 'solid',
    borderWidth: '0 1px 0 0', // right
    // height: 185,
    overflow: 'hidden',
    borderColor: colors.lightGrey,
    backgroundColor: colors.grey5,
    ...media.smallScreen({
      minWidth: 280,
      maxWidth: '100%',
      width: '100%',
      height: 176,
      borderWidth: '0 0 1px 0', // bottom
    }),
  },
  secondaryButtons: {
    color: '#033A6D',
    fontWeight: 600,
  },
  containerClickable: {
    cursor: 'pointer',
  },
  divButton: {
    color: colors.action3,
    width: '100%',
    borderTop: `1px solid ${colors.lightGrey}`,
    height: 39.5,
    cursor: 'pointer',
    ...media.smallScreen({
      height: 43.5,
    }),
  },
  divButtonDisabled: {
    color: colors.grey,
    cursor: 'default',
  },
  divButtonSeparator: {
    ...media.mediumScreen({
      borderLeft: `1px solid ${colors.lightGrey}`,
    }),
  },
  applicantsButton: {
    height: 24,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: '4px',
    fontSize: 11,
    ...typography.semiboldText,
    lineHeight: '16px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  acceptingApplicants: {
    backgroundColor: '#FF9250',
    color: '#042238',
    fontWeight: 'bold',
  },
  notAcceptingApplicants: {
    backgroundColor: '#E6E9F0',
    color: '#042238',
    fontWeight: 'bold',
  },
  marketingOnline: {
    backgroundColor: '#00C8FF',
    color: '#042238',
    fontWeight: 'bold',
  },
  marketingPendingUnverified: {
    backgroundColor: '#f0dfb1',
    color: '#042238',
    fontWeight: 'bold',
  },
  marketingOnlineWrapper: {
    paddingBottom: 8,
    ...media.smallScreen({
      paddingBottom: 0,
    }),
  },
  applicantsBtn: {
    marginRight: 8,
    marginTop: 8,
    ...media.smallScreen({
      marginRight: 0,
    }),
  },
  marketingBtn: {
    marginTop: 8,
  },
  butonsContainer: {
    paddingBottom: 8,
    ...media.smallScreen({
      flexWrap: 'wrap',
      paddingBottom: 24,
    }),
  },
  smallButonsContainer: {
    paddingBottom: 8,
    ...media.mediumScreen({
      marginTop: 0,
    }),
    ...media.largeScreen({
      marginTop: 0,
    }),
  },
  manageButtonContainer: {
    marginTop: 0,
    ...media.largeScreen({
      marginTop: -12,
    }),
    ...media.smallScreen({
      marginTop: 18,
      alignItems: 'center',
    }),
  },
  draftButtonContainer: {
    marginTop: 0,
    ...media.largeScreen({
      marginTop: -32,
    }),
    ...media.smallScreen({
      marginTop: 18,
      alignItems: 'center',
    }),
  },
  rentAmountLabel: {
    color: colors.gray6,
    fontSize: '12px',
    fontWeight: 'bold',
  },
  rentAmount: {
    ...media.smallScreen({
      marginTop: 8,
      justifyContent: 'start',
    }),
  },
  draftTitle: {
    ...media.smallScreen({
      marginTop: 8,
    }),
  },
  draftDescription: {
    paddingRight: 4,
    ...typography.secondaryItalicText,
  },
}))(PropertyListingCard);
