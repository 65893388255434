import React from 'react';
import { NavLink } from 'react-router-dom';
import { array, bool, number, object, oneOfType, string } from 'prop-types';

import { css, ThemedStyleSheet, withStyles } from '../../styles/with-styles';

const theme = ThemedStyleSheet.get();

class Tab extends React.Component {
  render() {
    const { label, to, styles, count, chevron, first, last, id, navItemStyle } =
      this.props;
    let styleToApply = styles.commonTab;
    let labelStyle = {};
    if (chevron) {
      labelStyle = first ? styles.firstLabelStyleXS : styles.labelContainer;
      styleToApply = last ? styles.lastTab : styles.tab;
    }

    return (
      <NavLink
        to={to}
        {...css(styleToApply, navItemStyle)}
        style={{ color: theme.colors.darkBlueGrey }}
        activeClassName={css(styles.active).className}
        id={id}
      >
        <div {...css(labelStyle)}>
          {count !== undefined && <div {...css(styles.counter)}>{count}</div>}
          {label}
        </div>
      </NavLink>
    );
  }
}

Tab.propTypes = {
  chevron: bool,
  to: string,
  label: oneOfType([string, object, array]),
  styles: object,
  count: number,
  first: bool,
  last: bool,
  id: string,
};

export default withStyles(({ colors, media, typography }) => ({
  commonTab: {
    width: '184px',
    textAlign: 'center',
    fontWeight: 600,
    padding: '16px 29px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: colors.darkBlueGrey,
    ...media.xSmallScreen({
      padding: '16px 12px',
    }),
    ...media.smallScreen({
      width: 'auto',
      flex: 1,
    }),
    position: 'relative',
    display: 'inline-block',
    float: 'left',
  },
  tab: {
    'width': '184px',
    'textAlign': 'center',
    'fontWeight': 600,
    'padding': '16px 29px',
    'textDecoration': 'none',
    'textTransform': 'uppercase',
    ...media.smallScreen({
      width: 'auto',
      flex: 1,
    }),
    ...media.xSmallScreen({
      'width': 110,
      'textAlign': 'center',
      'fontWeight': 600,
      'fontSize': 14,
      'padding': '16px 0px',
      'textDecoration': 'none',
      'textTransform': 'uppercase',
      'position': 'relative',
      'display': 'block',
      'float': 'left',
      ':after': {
        content: "''",
        display: 'block',
        width: 0,
        height: 0,
        borderTop:
          '42px solid transparent' /* Go big on the size, and let overflow hide */,
        borderBottom: '42px solid transparent',
        borderLeft: '20px solid',
        borderLeftColor: colors.lightGrey,
        position: 'absolute',
        top: '50%',
        marginTop: -42,
        left: '100%',
        zIndex: '2',
      },
      ':before': {
        content: "''",
        display: 'block',
        width: 0,
        height: 0,
        borderTop: '42px solid transparent',
        borderBottom: '42px solid transparent',
        borderLeft: '20px solid',
        borderLeftColor: colors.lightBlueGrey,
        position: 'absolute',
        top: '50%',
        marginTop: -42,
        marginLeft: 1,
        left: '100%',
        zIndex: '1',
      },
    }),
    'position': 'relative',
    'display': 'block',
    'float': 'left',
    ':after': {
      content: "''",
      display: 'block',
      width: 0,
      height: 0,
      borderTop:
        '42px solid transparent' /* Go big on the size, and let overflow hide */,
      borderBottom: '42px solid transparent',
      borderLeft: '30px solid',
      borderLeftColor: colors.lightGrey,
      position: 'absolute',
      top: '50%',
      marginTop: -42,
      left: '100%',
      zIndex: '2',
    },
    ':before': {
      content: "''",
      display: 'block',
      width: 0,
      height: 0,
      borderTop: '42px solid transparent',
      borderBottom: '42px solid transparent',
      borderLeft: '30px solid',
      borderLeftColor: colors.lightBlueGrey,
      position: 'absolute',
      top: '50%',
      marginTop: -42,
      marginLeft: 1,
      left: '100%',
      zIndex: '1',
    },
  },
  counter: {
    fontSize: '24px',
    textAlign: 'center',
    ...typography.boldText,
  },
  labelContainer: {
    marginLeft: 20,
    ...media.xSmallScreen({
      marginLeft: 24,
    }),
  },
  firstLabelStyleXS: {
    marginLeft: 20,
    ...media.xSmallScreen({
      marginLeft: 20,
    }),
  },
  lastTab: {
    width: '204px',
    textAlign: 'center',
    fontWeight: 600,
    padding: '16px 29px',
    textDecoration: 'none',
    textTransform: 'uppercase',
    ...media.smallScreen({
      width: 'auto',
      flex: 1,
    }),
    ...media.xSmallScreen({
      width: 100,
      fontSize: 14,
      padding: '16px 0px',
    }),
    position: 'relative',
    display: 'block',
    float: 'left',
  },
  active: {
    'color': colors.action3,
    'backgroundColor': '#FFFFFF',
    ':after': {
      content: "''",
      color: colors.action3,
      borderLeftColor: colors.white,
    },
  },
}))(Tab);
