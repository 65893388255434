import React from 'react';
import PropTypes from 'prop-types';

import LoadingScreen from '../../components/LoadingScreen';
import { Column } from '../Layout';
import { css, withStyles } from '../styles/with-styles';

/**
 * How to use
 *  <TableComponent
 *      loading,
 *      headers,
 *      data
 *  />
 * headers: an array of elements with the shape:
 *      {   name: 'name of the corresponding property in "data" array',
 *          title: 'title to show in the header, could be a text or element',
 *          containerProps: 'an object with properties that will be passed to <td>'
 *      }
 *
 * data: an array of elements with data objects, where each key of data objects
 * will be mapped to the corresponding column, based on "header.name"
 * each data objects should have the shape:
 *      {   key1(ie: age): content(ie: <span>HELLO</span>),
 *          key2(ie: address): {
 *              content: (ie: <span>MY ADDRESS</span>),
 *              containerProps: 'an object with properties that will be passed to <td>'
 *          }
 *      }
 * Keep in mind, the value of any key could be an UI object, text, or an object with the shape:
 *      {
 *          content: (ie: <span>MY ADDRESS</span>),
 *          containerProps: 'an object with properties that will be passed to <td> ie: "{ style: { cursor: 'pointer' } }"'
 *      }
 */
class TableComponent extends React.Component {
  render() {
    const { styles, headers, data, loading } = this.props;
    return (
      <LoadingScreen
        style={{ width: '100%', minHeight: '50vh' }}
        loading={loading}
      >
        <Column horizontal="center">
          <table style={{ width: '100%' }}>
            <thead>
              <tr {...css(styles.headerRow)}>
                {headers.map((header, index) => {
                  const containerProps = header.containerProps || {};
                  return (
                    <td key={`header-${index}`} {...containerProps}>
                      {header.title}
                    </td>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => [
                <tr
                  key={`data-${index}`}
                  {...css(styles.commonRow)}
                  {...item._rowProps}
                >
                  {headers.map((header, index) => {
                    if (!item[header.name]) return;
                    const content =
                      item[header.name].content || item[header.name];
                    const containerProps =
                      item[header.name].containerProps || {};
                    return (
                      <td key={`data-cell-${index}`} {...containerProps}>
                        {' '}
                        {content}
                      </td>
                    );
                  })}
                </tr>,
                item._childRow && (
                  <tr key={`data-${index}-childrow`} {...css(styles.commonRow)}>
                    <td colSpan={headers.length}> {item._childRow} </td>
                  </tr>
                ),
              ])}
            </tbody>
          </table>
        </Column>
      </LoadingScreen>
    );
  }
}

TableComponent.propTypes = {
  loading: PropTypes.bool,
  styles: PropTypes.object,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
  data: PropTypes.array.isRequired,
};

export default withStyles(({ colors, typography }) => ({
  headerRow: {
    height: 26,
    color: colors.medBlueGrey,
    fontSize: 12,
    ...typography.boldText,
    lineHeight: '18px',
    borderBottom: '1pt solid',
    borderBottomColor: colors.lightBlueGrey,
  },
  commonRow: {
    height: 49,
    color: colors.darkBlueGrey,
    fontSize: 14,
    lineHeight: '21px',
    borderBottom: '1pt solid',
    borderBottomColor: colors.lightGrey,
  },
  iconStyle: {
    'width': 24,
    'height': 24,
    ':hover': {
      cursor: 'pointer',
    },
  },
}))(TableComponent);
