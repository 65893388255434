import React, { useContext, useState } from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import cx from 'classnames';
import PropTypes from 'prop-types';

import CalendarIcon from '../../icons/Calendar';
import DollarIcon from '../../icons/Dollar';
import Percentage from '../../icons/Percentage';
import { ListingContext } from '../../providers/ListingProvider';
import composeValidators from '../../validators/composeValidators';
import fieldRequired from '../../validators/fieldRequired';
import isGreaterThan from '../../validators/isGreaterThan';
import isLessThanOrEqualTo from '../../validators/isLessThanOrEqualTo';
import isNumber from '../../validators/isNumber';
import isXDecimalPlaces from '../../validators/isXDecimalPlaces';
import { Column, Row } from '../Grid';
import InputWithIcon from '../InputWithIcon';
import RadioGroup from '../RadioGroup';
import SegmentedController from '../SegmentedController';
import SelectField from '../SelectField';

import LateFeeNotice from './LateFeeNotice';

import styles from './SetAutomaticLateFeeSection.module.scss';

const isLessThanOrEqualTo100 = isLessThanOrEqualTo(100);
const isLessThanOrEqualTo10k = isLessThanOrEqualTo(
  10_000,
  'Must be less than $10,000',
);
const isPositive = isGreaterThan(0);
const isMax2Decimals = isXDecimalPlaces(2);

const SetAutomaticLateFeeSection = ({ formData, setFormData }) => {
  const listing = useContext(ListingContext);
  const state = listing?.state;
  const city = listing?.city;
  const lateFeeAmountValidators = [
    isNumber,
    fieldRequired,
    isPositive,
    isMax2Decimals,
  ];

  if (formData?.is_late_fee_percentage) {
    lateFeeAmountValidators.unshift(isLessThanOrEqualTo100);
  } else {
    lateFeeAmountValidators.unshift(isGreaterThan(0.5));
    lateFeeAmountValidators.unshift(isLessThanOrEqualTo10k);
  }

  const [setUpAutomaticLateFee, setSetUpAutomaticLateFee] = useState(
    formData?.late_fee_amount != null,
  );
  const setUpLateFeesOptions = [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ];

  const gracePeriodOptions = [...Array(31).keys()].map((index) => {
    const day = index + 1;

    return {
      value: day,
      label: `${day} ${day > 1 ? 'days' : 'day'} after rent is due `,
    };
  });

  return (
    <>
      <Row className={styles.rowWithGapAndMargin}>
        <Column span="12">
          <RadioGroup
            horizontal
            options={setUpLateFeesOptions}
            input={{
              value: setUpAutomaticLateFee,
              onChange: (val) => {
                setSetUpAutomaticLateFee(val);

                if (!val) {
                  setFormData({
                    ...formData,
                    is_late_fee_percentage: false,
                    late_fee_amount: null,
                    late_fee_grace_period: 1,
                  });
                }
              },
            }}
            radioRowClassName={styles.radioRowClassName}
            labelProps={{ className: styles.radioLabel }}
          />
        </Column>
      </Row>
      {setUpAutomaticLateFee && (
        <>
          <Row className={styles.rowWithTemplate}>
            <Column span="1">
              <SegmentedController
                id="is_late_fee_percentage"
                name="is_late_fee_percentage"
                label="Fee Type"
                containerClassName={styles.feeTypeController}
                onChange={(requestedStatus) => {
                  setFormData({
                    ...formData,
                    is_late_fee_percentage: requestedStatus,
                    late_fee_amount: null,
                  });
                }}
                selectedValue={formData?.is_late_fee_percentage}
                options={[
                  { label: 'Flat', value: false },
                  { label: '% Unpaid', value: true },
                ]}
              />
            </Column>
            <Column span="1" className={styles.amountColumn}>
              <Field
                value={formData?.late_fee_amount}
                component={InputWithIcon}
                id="late_fee_amount"
                name="late_fee_amount"
                className={styles.lateFeeAmount}
                label="Amount"
                type="number"
                step=".01"
                pattern="[0-9]*"
                inputMode="decimal"
                validate={composeValidators(...lateFeeAmountValidators)}
                icon={
                  formData?.is_late_fee_percentage ? Percentage : DollarIcon
                }
                iconRight={formData?.is_late_fee_percentage}
              />
              <OnChange name="late_fee_amount">
                {(amount) => {
                  setFormData({
                    ...formData,
                    late_fee_amount: Number(amount).toFixed(2),
                  });
                }}
              </OnChange>
            </Column>
            <Column span="12" md="9">
              <Field
                value={formData?.late_fee_grace_period}
                className={styles.gracePeriod}
                component={SelectField}
                id="late_fee_grace_period"
                name="late_fee_grace_period"
                label="Apply Late Fee"
                options={gracePeriodOptions}
                disabled={false}
                icon={CalendarIcon}
              />
              <OnChange name="late_fee_grace_period">
                {(gracePeriod) => {
                  setFormData({
                    ...formData,
                    late_fee_grace_period: gracePeriod,
                  });
                }}
              </OnChange>
            </Column>
          </Row>
          <Row>
            <Column span="12">
              {formData?.late_fee_grace_period != null && (
                <div
                  className={cx(
                    styles.description,
                    styles.automaticLateFeeSummary,
                  )}
                >
                  This late fee rule will only apply to monthly rent. We'll
                  automatically send a late fee to your tenants{' '}
                  {formData?.late_fee_grace_period}{' '}
                  {formData?.late_fee_grace_period > 1 ? 'days' : 'day'} after
                  rent is due if there is still an outstanding balance.
                </div>
              )}
            </Column>
            <Column span="12">
              <LateFeeNotice state={state} city={city} />
            </Column>
          </Row>
        </>
      )}
    </>
  );
};

SetAutomaticLateFeeSection.propTypes = {
  formData: PropTypes.shape({
    late_fee_grace_period: PropTypes.number,
    late_fee_amount: PropTypes.number,
    is_late_fee_percentage: PropTypes.bool,
  }),
  setFormData: PropTypes.func,
};

export default SetAutomaticLateFeeSection;
