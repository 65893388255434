import { TT_ACCOUNT_ID_PARAM } from '../providers/TTAccountProvider';

function injectTtAccountIdScript(ttAccountId, domainUrl) {
  function appendTtAccountIdToUrl(url) {
    const parsedUrl = new URL(url, window.location.origin);
    if (
      domainUrl.includes(parsedUrl.host) &&
      !parsedUrl.searchParams.has(TT_ACCOUNT_ID_PARAM)
    ) {
      parsedUrl.searchParams.set(TT_ACCOUNT_ID_PARAM, ttAccountId);
    }
    return parsedUrl.toString();
  }

  const originalWindowOpen = window.open;
  window.open = function (url, name, specs) {
    const newUrl = appendTtAccountIdToUrl(url);
    return originalWindowOpen.call(window, newUrl, name, specs);
  };

  window.addEventListener('popstate', (event) => {
    const parsedUrl = new URL(window.location.href);
    if (
      domainUrl.includes(parsedUrl.host) &&
      !window.location.search.includes(TT_ACCOUNT_ID_PARAM)
    ) {
      const newUrl = appendTtAccountIdToUrl(window.location.href);
      history.replaceState(event.state, document.title, newUrl);
    }
  });

  window.addEventListener('beforeunload', () => {
    const parsedUrl = new URL(window.location.href);
    if (
      parsedUrl.host.includes(domainUrl) &&
      !window.location.search.includes(TT_ACCOUNT_ID_PARAM)
    ) {
      const newUrl = appendTtAccountIdToUrl(window.location.href);
      history.replaceState(history.state, document.title, newUrl);
    }
  });

  document.addEventListener('click', (event) => {
    const target = event.target.closest('a');
    if (target && target.href) {
      const newUrl = appendTtAccountIdToUrl(target.href);
      target.href = newUrl;
    }
  });

  const initialUrl = new URL(window.location.href);
  if (
    domainUrl.includes(initialUrl.host) &&
    !window.location.search.includes(TT_ACCOUNT_ID_PARAM)
  ) {
    const newUrl = appendTtAccountIdToUrl(window.location.href);
    history.replaceState(history.state, document.title, newUrl);
  }
}

export default injectTtAccountIdScript;
