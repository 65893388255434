const getReiHubYearlyPrice = (reiHubYearlyPricings = [], unitCount) => {
  if (unitCount > 21)
    return reiHubYearlyPricings.find(({ maxUnits }) => maxUnits === 'default');

  const reiHubYearlyPricing = reiHubYearlyPricings.find(
    ({ maxUnits }) => Number(maxUnits) === unitCount,
  );

  return reiHubYearlyPricing || reiHubYearlyPricings[0];
};

export default getReiHubYearlyPrice;
