import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import DropdownMenu from '../../../components/DropdownMenu';
import FlatButton from '../../../components/FlatButton';
import colors from '../../../constants/colors';
import DownArrow from '../../../icons/DownArrow';
import More from '../../../icons/More';
import { Tooltip } from '../../Tooltip';

import styles from './LeadActionsButton.module.scss';

const LeadActionsButton = ({
  actions,
  onAction,
  bulk,
  id,
  'data-qa': dataQa,
}) => {
  const handleAction = (action) => {
    onAction(action);
  };

  const [action] = actions;

  const allActions = bulk
    ? actions.filter((actionItem) => actionItem.bulkLabel)
    : actions;

  if (allActions.length === 1) {
    return (
      <Button
        small
        onClick={() => handleAction(action.type || action.label)}
        size="small"
        className={styles.singleButton}
        secondary={!bulk}
        disabled={action.disabled}
        data-qa={dataQa || 'actions-button'}
      >
        {bulk ? action.bulkLabel || action.label : action.label}
      </Button>
    );
  }

  return (
    <div>
      <DropdownMenu closeOnClick>
        <DropdownMenu.Trigger asChild>
          {bulk ? (
            <div>
              <Button
                className={styles.actionsBtn}
                id={id}
                data-qa={dataQa || 'actions-button'}
                icon={DownArrow}
                iconProps={{ className: styles.menuIcon }}
                iconAfter
              >
                Actions
              </Button>
            </div>
          ) : (
            <FlatButton
              className={styles.menuTrigger}
              id={id}
              data-qa={dataQa || 'actions-button'}
              icon={More}
              iconProps={{ className: styles.menuIcon }}
            ></FlatButton>
          )}
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          {allActions.map((actionItem, index) => (
            <React.Fragment key={actionItem.id || index}>
              <DropdownMenu.Item
                disabled={actionItem.disabled}
                onClick={() =>
                  !actionItem.disabled && handleAction(actionItem.type)
                }
                icon={actionItem.icon}
                iconProps={{
                  color: colors.primary,
                }}
                data-tooltip-id={`disabled-tip-${actionItem.type}`}
                data-qa={`leads-action-item-${actionItem.type}`}
                id={actionItem.id}
                key={actionItem.type}
              >
                {bulk ? actionItem.bulkLabel : actionItem.label}
              </DropdownMenu.Item>

              {actionItem.disabled && (
                <Tooltip
                  id={`disabled-tip-${actionItem.type}`}
                  place={index === 0 ? 'bottom' : 'top'}
                >
                  {actionItem.disabledMessage}
                </Tooltip>
              )}
            </React.Fragment>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu>
    </div>
  );
};

LeadActionsButton.propTypes = {
  'actions': PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
      disabled: PropTypes.bool,
      disabledMessage: PropTypes.string,
      icon: PropTypes.node,
    }),
  ).isRequired,
  'onAction': PropTypes.func.isRequired,
  'bulk': PropTypes.bool,
  'id': PropTypes.string,
  'data-qa': PropTypes.string,
  'props': PropTypes.object,
};

export default LeadActionsButton;
