import React from 'react';
import kebabCase from 'lodash.kebabcase';
import PropTypes from 'prop-types';

import Badge from '../../components/Badge';
import Tag from '../../components/Tag';
import VerifiedBadgeIcon from '../../icons/VerifiedBadge';
import { PrimaryButton, SecondaryButton } from '../Button';
import ProfileLogo from '../icons/people/profile-logo';
import { Column, Row } from '../Layout';
import ImageItem from '../PhotoUploader/ImageItem';
import { css, withStyles } from '../styles/with-styles';
import { ThemedStyleSheet } from '../styles/with-styles';

const theme = ThemedStyleSheet.get();

class ListItemCard extends React.Component {
  static propTypes = {
    imageUrl: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    rotation: PropTypes.number,
    title: PropTypes.string,
    subtitle: PropTypes.any,
    subtitleTag: PropTypes.string,
    counter: PropTypes.number,
    descriptions: PropTypes.arrayOf(PropTypes.string),
    buttonLabel: PropTypes.string,
    buttonAction: PropTypes.func,
    buttonDisabled: PropTypes.bool,
    buttonIdPrefix: PropTypes.string,
    buttonStyleAsSecondary: PropTypes.bool,
    buttonStyle: PropTypes.object,
    buttonLabelStyle: PropTypes.object,
    itemIndex: PropTypes.number,
    secondaryButtons: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any,
        action: PropTypes.func,
      }),
    ),
    styles: PropTypes.object,
    style: PropTypes.object,
    subItems: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        style: PropTypes.object,
      }),
    ),
    subtitleStyles: PropTypes.object,
    itemsContainerStyle: PropTypes.object,
    customSubItemStyles: PropTypes.object,
    logo: PropTypes.object,
    subItemsAsRow: PropTypes.bool,
    imageAsAvatar: PropTypes.bool,
    childRow: PropTypes.object,
    landlord_pays_label: PropTypes.shape({
      label: PropTypes.string,
      paid: PropTypes.bool,
    }),
  };

  renderAvatar() {
    const { imageUrl, styles } = this.props;
    if (imageUrl) {
      return <img {...css(styles.img)} alt="Profile logo!" src={imageUrl} />;
    }
    return (
      <ProfileLogo
        style={{
          width: theme.media.isSmallScreen() ? 64 : 58,
          height: theme.media.isSmallScreen() ? 64 : 58,
        }}
      />
    );
  }

  renderLabel = () => {
    const { styles } = this.props;
    const { label, paid } = this.props.landlord_pays_label;

    return (
      <p {...css(styles.paymentLabel, paid ? styles.paid : {})}>{label}</p>
    );
  };

  render() {
    const buttonSize = theme.media.isSmallScreen() ? 'small' : 'large';
    const {
      buttonStyle,
      buttonLabelStyle,
      styles,
      imageUrl,
      rotation,
      title,
      subtitle,
      counter,
      descriptions,
      subItems,
      secondaryButtons,
      customSubItemStyles,
      logo: Logo,
      itemIndex,
      buttonIdPrefix,
      buttonStyleAsSecondary,
      subItemsAsRow,
      imageAsAvatar,
      childRow,
      subtitleStyles,
      itemsContainerStyle,
      subtitleTag,
      hasIdentityVerification,
    } = this.props;
    const Container = imageAsAvatar ? Row : Column;
    const ItemsContainer = subItemsAsRow
      ? theme.media.isSmallScreen()
        ? Column
        : Row
      : 'div';
    return (
      <Column {...css(styles.card, this.props.style)}>
        <Row {...css(styles.fullWidth)}>
          {imageUrl && !imageAsAvatar ? (
            <div {...css(styles.imageContainer)}>
              <ImageItem imageUrl={imageUrl} rotation={rotation} autofill />
            </div>
          ) : (
            ''
          )}
          <div {...css(styles.fullWidth)}>
            <Container {...css(styles.innerContent)}>
              {imageAsAvatar && !theme.media.isSmallScreen() && (
                <Column {...css({ marginRight: 10 })}>
                  {this.renderAvatar()}
                </Column>
              )}
              <Column {...css(styles.fullWidth)}>
                {title && (
                  <div {...css(styles.fullWidth)}>
                    <p {...css(styles.title)}>{title}</p>
                  </div>
                )}
                <Row onSmall="column" justifyContent="space-between">
                  {this.renderLabel()}
                </Row>
                <Row onSmall="column" justifyContent="space-between">
                  <div {...css(styles.fullWidth, styles.fullWidthMargin)}>
                    {subtitle && (
                      <div
                        {...css(
                          subtitleStyles ? subtitleStyles : styles.subTitle,
                        )}
                      >
                        <div>{subtitle}</div>
                        {subtitleTag && (
                          <Tag small {...css(styles.subTitleTag)}>
                            {subtitleTag}
                          </Tag>
                        )}
                        {typeof counter === 'number' && counter > 0 && (
                          <Badge counter={counter} style={{ marginLeft: 4 }} />
                        )}
                        {hasIdentityVerification && (
                          <div {...css(styles.verifiedBadge)}>
                            <VerifiedBadgeIcon
                              {...css(styles.verifiedBadgeIcon)}
                            />
                            VERIFIED
                          </div>
                        )}
                      </div>
                    )}
                    {descriptions &&
                      descriptions.map((description, index) => (
                        <p
                          key={`description_${index}`}
                          {...css({ marginBottom: 0, marginTop: 0 })}
                        >
                          {description}
                        </p>
                      ))}
                    <ItemsContainer
                      {...css(itemsContainerStyle || { marginTop: 8 })}
                    >
                      {subItems &&
                        subItems.map((item, index) => (
                          <div
                            key={`subitems_${index}`}
                            {...css(styles.subItem, customSubItemStyles)}
                          >
                            {item.label && (
                              <span
                                {...css(
                                  item.style
                                    ? { ...item.style }
                                    : { ...theme.typography.cardSubItem },
                                  { paddingRight: 2 },
                                )}
                              >
                                {item.label + ': '}
                              </span>
                            )}
                            <span
                              {...css(item.label ? {} : styles.subItemText)}
                            >
                              {item.text}
                            </span>
                          </div>
                        ))}
                    </ItemsContainer>
                  </div>
                  <Column
                    horizontal="center"
                    {...css(styles.rightCol, Logo && styles.withLogo)}
                  >
                    {!buttonStyleAsSecondary ? (
                      <PrimaryButton
                        disabled={this.props.buttonDisabled}
                        label={this.props.buttonLabel}
                        onClick={this.props.buttonAction}
                        size={buttonSize}
                        style={buttonStyle}
                        labelStyle={buttonLabelStyle}
                        id={`${buttonIdPrefix}${itemIndex}`}
                        data-qa={kebabCase(
                          `${buttonIdPrefix}${itemIndex}-button`,
                        )}
                      />
                    ) : (
                      <SecondaryButton
                        disabled={this.props.buttonDisabled}
                        label={this.props.buttonLabel}
                        onClick={this.props.buttonAction}
                        size={buttonSize}
                        style={buttonStyle}
                        labelStyle={buttonLabelStyle}
                        id={`${buttonIdPrefix}${itemIndex}`}
                      />
                    )}
                    {Logo && Logo}
                  </Column>
                </Row>
              </Column>
            </Container>
            {secondaryButtons ? (
              <div {...css(styles.secondaryButtons)}>
                {secondaryButtons.map((button, index) => (
                  <div
                    key={`secondaryButton_${index}`}
                    {...css(
                      styles.divButton,
                      index > 0 && styles.divButtonSeparator,
                      button.disabled && styles.divButtonDisabled,
                    )}
                    onClick={button.action}
                    disabled={button.disabled}
                    id={button.id}
                  >
                    {button.label}
                  </div>
                ))}
              </div>
            ) : (
              ''
            )}
          </div>
        </Row>
        {childRow && <Row>{childRow}</Row>}
      </Column>
    );
  }
}

ListItemCard.defaultProps = {
  customSubItemStyles: {},
};

export default withStyles(({ colors, typography, media }) => ({
  ...media.hideLarge,
  ...media.hideSmall,
  mainContainer: {
    ...media.mediumScreen({
      width: 424,
    }),
  },
  card: {
    border: '1px solid',
    borderRadius: 4,
    boxSizing: 'border-box',
    borderColor: colors.lightGrey,
    backgroundColor: colors.white,
    minWidth: 500,
    overflow: 'hidden',
    ...media.smallScreen({
      minWidth: 240,
    }),
    position: 'relative',
  },
  rightCol: {
    marginTop: 8,
    ...media.smallScreen({
      marginTop: 16,
    }),
  },
  imageContainer: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 168,
    minHeight: 168,
    width: 168,
    borderStyle: 'solid',
    borderWidth: '0 1px 0 0', // right
    // height: 185,
    overflow: 'hidden',
    borderColor: theme.colors.lightGrey,
    backgroundColor: theme.colors.white,
    ...media.smallScreen({
      minWidth: 280,
      width: '100%',
      height: 178,
      borderWidth: '0 0 1px 0', // bottom
    }),
  },
  fullWidth: {
    width: '100%',
  },
  fullWidthMargin: {
    marginRight: 0,
    maxWidth: 454,
    ...media.mediumScreen({
      marginRight: 16,
    }),
  },
  innerContent: {
    padding: '24px 24px 16px',
  },
  secondaryButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    color: colors.action3,
    ...typography.semiboldText,
    ...media.smallScreen({
      flexDirection: 'column',
    }),
  },
  divButton: {
    color: colors.action3,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderTop: `1px solid ${colors.lightGrey}`,
    height: 39.5,
    cursor: 'pointer',
    ...media.smallScreen({
      height: 43.5,
    }),
  },
  divButtonDisabled: {
    color: colors.grey,
    cursor: 'default',
  },
  divButtonSeparator: {
    ...media.mediumScreen({
      borderLeft: `1px solid ${colors.lightGrey}`,
    }),
  },
  title: {
    ...typography.smallCardTitle,
    marginBottom: 2,
  },
  subTitle: {
    ...typography.cardSidebarTitle,
    margin: '0 0 2px 0',
    display: 'flex',
    alignItems: 'center',
  },
  subTitleTag: {
    background: `#7FE3FF`,
    marginLeft: 8,
  },
  withLogo: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    ...media.smallScreen({
      alignItems: 'center',
    }),
  },
  subItem: {
    marginTop: 2,
    display: 'flex',
    marginBottom: 0,
  },
  img: {
    width: 60,
    height: 60,
    borderRadius: '50%',
    ...media.xSmallScreen({
      width: 64,
      height: 64,
    }),
  },
  verifiedBadge: {
    display: 'flex',
    alignItems: 'center',
    color: '#04BF8D',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: '18px',
    marginLeft: 4,
    ...media.smallScreen({
      position: 'absolute',
      top: 12,
      right: 12,
      fontSize: 11,
    }),
  },
  verifiedBadgeIcon: {
    marginRight: 4,
    ...media.smallScreen({
      width: 9,
      height: 10,
    }),
  },
  paymentLabel: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: 0.035,
    color: theme.colors.grey,
    margin: '2px 0',
  },
  paid: {
    color: theme.colors.green,
  },
  subItemText: {
    wordBreak: 'break-word',
    ...media.mediumScreen({
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      wordBreak: 'normal',
    }),
  },
}))(ListItemCard);
