import React, { useMemo } from 'react';
import { OnChange } from 'react-final-form-listeners';
import cx from 'classnames';
import PropTypes from 'prop-types';

import FinalForm from '../../components/FinalForm';
import { CheckboxField } from '../../components/FinalFormFields';
import PartnerSourceLogo from '../../components/PartnerSourceLogo/PartnerSourceLogo';
import { Tooltip } from '../../components/Tooltip';
import formatPhone from '../../helpers/format-phone';
import getFormattedDate from '../../helpers/getFormattedDate';
import getListingBreadcrumbName from '../../helpers/getListingBreadcrumbName';
import hideLeadEmailByDomainFromUI from '../../helpers/hideLeadEmailByDomainFromUI';
import truncate from '../../helpers/truncate';
import Calendar from '../../icons/Calendar';
import CheckDouble from '../../icons/CheckDouble';
import { getConfig } from '../../services/configService';
import EmailEmail from '../icons/email/email';
import PhonePhone5 from '../icons/phone/phone-5';
import { isSmallScreen } from '../styles/media-queries';
import { ThemedStyleSheet } from '../styles/with-styles';

import LeadActionsButton from './LeadActionsButton';

import styles from './LeadsTable.module.scss';

const theme = ThemedStyleSheet.get();

const excludedTags = ['INPUT', 'svg', 'path', 'A', 'BUTTON'];

const LeadsTable = ({
  leads,
  selectedLeads,
  onSelectAll,
  totalLeads,
  onSelectLead,
  ...props
}) => {
  const selectedSet = useMemo(() => new Set(selectedLeads), [selectedLeads]);
  const leadsList = useMemo(
    () =>
      leads.map((lead) => ({
        ...lead,
        selected: selectedSet.has(lead.id),
      })),
    [leads, selectedSet],
  );
  const { LEADS_TABLE_PAGE_SIZE } = getConfig();

  const allActions = props.actions();

  return (
    <FinalForm
      initialValues={{
        select_all: leads.length === selectedSet.size,
      }}
    >
      {({ values, form }) => (
        <div className={styles.table}>
          <div className={styles.header}>
            <div className={styles.firstColumn}>
              <CheckboxField
                required={false}
                name="select_all"
                label={
                  values.select_all
                    ? `${
                        totalLeads > LEADS_TABLE_PAGE_SIZE
                          ? leads.length
                          : totalLeads
                      } Selected`
                    : `Select (${
                        totalLeads > LEADS_TABLE_PAGE_SIZE
                          ? leads.length
                          : totalLeads
                      })`
                }
                className={styles.checkboxSelectedAll}
                labelClassName={styles.checkboxSelectedAllLabel}
              />
              <OnChange name="select_all">
                {(value) => {
                  onSelectAll(value);
                }}
              </OnChange>
            </div>
            <div className={styles.secondColumn}>Contact Info</div>
            <div className={styles.thirdColumn}>Became a Lead</div>
            <div className={styles.fourthColumn}>Activity</div>
            <div className={styles.fifthColumn}>Source</div>
            <div className={styles.sixthColumn}>
              {selectedSet.size > (isSmallScreen() ? 0 : 1) ? (
                <LeadActionsButton
                  bulk
                  actions={allActions}
                  onAction={(type) =>
                    props.onAction({
                      type,
                      allSelected: values.select_all,
                    })
                  }
                />
              ) : (
                <>Actions</>
              )}
            </div>
          </div>
          <div className={styles.content}>
            {leadsList.map((lead, index) => {
              const showStatus = ['VIEWED', 'APPLIED'].indexOf(lead.status) < 0;
              const leadActions = props.actions(lead);

              return (
                <div
                  key={lead.id}
                  className={styles.leadRow}
                  onClick={(e) => {
                    if (!excludedTags.includes(e.target.tagName)) {
                      props.onViewLead(lead.id);
                    }
                  }}
                  style={
                    lead.status === 'NEW' ? { backgroundColor: '#F5F6F8' } : {}
                  }
                  data-qa={`leads-table-row-${index}`}
                >
                  <div
                    className={cx(styles.firstColumn, styles.firstColumnRow)}
                  >
                    <CheckboxField
                      controlled
                      required={false}
                      name={`select_${lead.id}`}
                      className={styles.checkboxLead}
                      style={{ marginRight: 15 }}
                      initialValue={lead.selected}
                      checked={lead.selected}
                      checkboxIconStyles={
                        lead.selected
                          ? { fill: theme.colors.lightBlueGrey }
                          : {}
                      }
                      // @NOTICE: do not use on change to prevent a infinite re-render issue
                      onClick={() => {
                        onSelectLead(lead.id, form, values.select_all);
                      }}
                    />
                    <div className={styles.leadInfo}>
                      {showStatus && (
                        <b
                          className={styles.leadStatus}
                          style={
                            lead.status === 'CONTACTED'
                              ? { color: theme.colors.green }
                              : { color: theme.colors.link }
                          }
                        >
                          {lead.status}
                        </b>
                      )}
                      {(lead.first_name || lead.last_name) && (
                        <div className={styles.leadName} data-qa="lead-name">
                          <div>
                            {truncate(
                              `${lead.first_name} ${lead.last_name}`,
                              25,
                            )}
                          </div>
                        </div>
                      )}
                      <b
                        className={styles.leadAddress}
                        data-tooltip-id={`lead-key-tip-${lead.id}`}
                      >
                        {getListingBreadcrumbName({
                          listing: lead?.listing,
                          separator: ', ',
                        })}
                      </b>
                      <Tooltip id={`lead-key-tip-${lead.id}`}>
                        <span>
                          {getListingBreadcrumbName({
                            listing: lead?.listing,
                            separator: ', ',
                          })}
                        </span>
                      </Tooltip>
                    </div>
                  </div>
                  <div className={cx(styles.secondColumn, styles.leadContact)}>
                    {lead.email && !hideLeadEmailByDomainFromUI(lead.email) && (
                      <a
                        href={`mailto:${lead.email}`}
                        className={styles.leadEmail}
                      >
                        {truncate(lead.email, 23)}
                      </a>
                    )}
                    {lead.phone && (
                      <a
                        href={`tel:${lead.phone}`}
                        className={styles.leadPhone}
                      >
                        {formatPhone(lead.phone)}
                      </a>
                    )}
                  </div>
                  <div className={styles.secondColumnMobile}>
                    {lead.email && (
                      <a
                        href={`mailto:${lead.email}`}
                        className={styles.leadEmail}
                      >
                        <EmailEmail color={theme.colors.primary} />
                      </a>
                    )}
                    {lead.phone && (
                      <a
                        href={`tel:${lead.phone}`}
                        className={styles.leadPhone}
                      >
                        <PhonePhone5 color={theme.colors.primary} />
                      </a>
                    )}
                  </div>
                  <div
                    className={cx(styles.thirdColumn, styles.thirdColumnRow)}
                  >
                    {getFormattedDate(lead.created_at)}
                  </div>
                  <div
                    className={cx(styles.fourthColumn, styles.fourthColumnRow)}
                  >
                    <div className={styles.leadActivity}>
                      <div
                        title={
                          lead.questionnaire ? 'Pre-Screener Completed' : ''
                        }
                      >
                        <CheckDouble
                          className={cx(styles.activityIcon, {
                            [styles.disable]: !lead.questionnaire,
                          })}
                        />
                      </div>
                      <div title={lead.has_showing ? 'Showing Scheduled' : ''}>
                        <Calendar
                          className={cx(styles.activityIcon, {
                            [styles.disable]: !lead.has_showing,
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.fifthColumn}>
                    <PartnerSourceLogo source={lead.source.toLowerCase()} />
                  </div>
                  <div
                    className={cx(styles.sixthColumn, styles.sixthColumnRow)}
                  >
                    {selectedSet.size <= 1 && (
                      <LeadActionsButton
                        actions={leadActions}
                        onAction={(type) =>
                          props.onAction({ type, leadsIds: [lead.id] })
                        }
                        data-qa={`leads-actions-button-${index}`}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </FinalForm>
  );
};

LeadsTable.propTypes = {
  styles: PropTypes.object,
  leads: PropTypes.array,
  totalLeads: PropTypes.number,
  selectedLeads: PropTypes.array,
  onAction: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectLead: PropTypes.func,
  onViewLead: PropTypes.func,
  actions: PropTypes.func,
};

export default LeadsTable;
