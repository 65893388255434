import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

let InterfaceFeedbackReportProblemTriangle = (props) => (
  <SvgIcon {...props}>
    <g
      stroke={props.color}
      strokeWidth={props.strokeWidth || 1.25}
      fillOpacity="0"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    >
      <path strokeLinecap="round" d="M11.5 16.805v-7.652" />
      <path strokeLinecap="round" d="M22.5 23.5h-22l11-22z" />
      <path d="M12 20c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5z" />
    </g>
  </SvgIcon>
);
InterfaceFeedbackReportProblemTriangle = memo(
  InterfaceFeedbackReportProblemTriangle,
);
InterfaceFeedbackReportProblemTriangle.displayName =
  'InterfaceFeedbackReportProblemTriangle';
InterfaceFeedbackReportProblemTriangle.muiName = 'SvgIcon';

export default InterfaceFeedbackReportProblemTriangle;
