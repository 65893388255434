import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Tooltip.module.scss';

const Tooltip = ({ className, type, ...otherProps }) => (
  <ReactTooltip
    className={cx(styles.reactTooltip, className, styles[type])}
    classNameArrow={styles.reactTooltipArrow}
    offset={16}
    {...otherProps}
  />
);

Tooltip.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'default',
    'dark',
    'light',
    'success',
    'warning',
    'error',
    'info',
    'pale',
  ]).isOptional,
};

export default Tooltip;
