import React from 'react';
import { object, string } from 'prop-types';

import { css, withStyles } from '../styles/with-styles';

import Layout from './index';

class PageCardCenter extends React.Component {
  static propTypes = {
    styles: object,
    // children: object,
    title: string,
  };

  render() {
    const { styles, title } = this.props;
    return (
      <div {...css(styles.mainContainer)}>
        <div {...css(styles.cardContainer)}>
          <Layout.Components.SmallCard title={title}>
            {this.props.children}
          </Layout.Components.SmallCard>
        </div>
      </div>
    );
  }
}

export default withStyles(({ layout }) => ({
  mainContainer: {
    position: 'relative',
    ...layout.column,
    minHeight: '100%',
    overflow: 'auto',
  },
  cardContainer: {
    flex: 1,
    flexShrink: 0,
    paddingTop: 80,
  },
}))(PageCardCenter);
