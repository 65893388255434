import React from 'react';
import { useLocation } from 'react-router-dom';

import { useUserProfile } from '../../../core/TTgraphql';
import Academy from '../../../icons/Academy';
import Accounting from '../../../icons/Accounting';
import DashboardIcon from '../../../icons/DashboardNav';
import ExpensesIcon from '../../../icons/ExpensesNav';
import FormsIcon from '../../../icons/FormsNav';
import GiftNav from '../../../icons/GiftNav';
import HelpIcon from '../../../icons/HelpNav';
import LeasesIcon from '../../../icons/LeaseNav';
import LogoutIcon from '../../../icons/LogoutNav';
import MaintenanceIcon from '../../../icons/MaintenanceAltThick';
import MessagesIcon from '../../../icons/MessagesNav';
import PaymentsIcon from '../../../icons/PaymentsNav';
import HomeIcon from '../../../icons/PropertyNav';
import ProfileIcon from '../../../icons/RentersNav';
import SettingsIcon from '../../../icons/SettingsNav';
import ToolboxNav from '../../../icons/ToolboxNav';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';
import ReiHubLoadingModal, {
  useOpenREIHub,
} from '../../ReiHubSubscription/ReiHubLoadingModal';
import { useGetItemStatus } from '../../ShowHideComponents';
import useMessagingNotifications from '../useMessagingNotifications';

import MobileNavigation from './MobileNavigation';

const MobileNavigationWrapped = () => {
  const location = useLocation();

  const { user, loading: userLoading } = useUserProfile({}, false);

  const { PRIVATE_BASE_PATH, IS_OWNER } = useConfig();

  const { unreadMessagesCount: newMessagingCount } = useMessagingNotifications(
    user?.id,
  );
  const isPremiumUser = user?.premium_subscription_subscribed;

  const [isModalOpen, setOpenREIHub] = useOpenREIHub({
    isDemoMode: !user.reihub_subscription_subscribed,
  });

  /**
   * NOTE: Mobile navigation can be hidden on the Messages page.
   */
  const isHidden = !useGetItemStatus('mobileNav')();

  const loginToReiHub = async () => {
    if (user.reihub_subscription_subscribed) {
      return setOpenREIHub();
    }

    return null;
  };

  const OWNER_DRAWER_ITEMS = [
    {
      label: 'Leases',
      id: 'leases',
      isActiveFilter: 'leases',
      to: PRIVATE_BASE_PATH + 'leases',
      Icon: LeasesIcon,
      onClick: () => {
        segmentTracking('leases clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Forms',
      id: 'forms',
      isActiveFilter: 'forms',
      to: PRIVATE_BASE_PATH + 'forms',
      Icon: FormsIcon,
      onClick: () => {
        segmentTracking('forms clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Payments',
      id: 'payments',
      isActiveFilter: 'payments',
      isInactiveFilter: 'payments/expenses,payments/accounting',
      to: PRIVATE_BASE_PATH + 'payments',
      Icon: PaymentsIcon,
      onClick: () => {
        segmentTracking('payments clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Expenses',
      id: 'expenses',
      isActiveFilter: 'payments/expenses',
      to: PRIVATE_BASE_PATH + 'payments/expenses',
      Icon: ExpensesIcon,
      onClick: () => {
        segmentTracking('expenses clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Accounting',
      id: 'accounting',
      Icon: Accounting,
      ...(user.reihub_subscription_subscribed
        ? {}
        : {
            isActiveFilter: 'owners/payments/accounting',
            to: `${PRIVATE_BASE_PATH}payments/accounting`,
          }),
      onClick: () => {
        segmentTracking('accounting clicked', {
          location: 'Nav Bar',
        });
        loginToReiHub();
      },
    },
    {
      label: 'Toolbox',
      id: 'toolbox',
      isActiveFilter: 'toolbox',
      to: PRIVATE_BASE_PATH + 'toolbox',
      Icon: ToolboxNav,
      onClick: () => {
        segmentTracking('toolbox clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Education',
      id: 'academy',
      isActiveFilter: 'academy',
      to: `${PRIVATE_BASE_PATH}academy`,
      Icon: Academy,
      onClick: () => {
        segmentTracking('education clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Maintenance',
      id: 'maintenance',
      isActiveFilter: 'owners/maintenance',
      to: `${PRIVATE_BASE_PATH}maintenance/maintenance-requests`,
      Icon: MaintenanceIcon,
      onClick: () => {
        segmentTracking('maintenance clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Settings',
      id: 'settings',
      isActiveFilter: 'settings',
      to: PRIVATE_BASE_PATH + 'settings',
      Icon: SettingsIcon,
      onClick: () => {
        segmentTracking('settings clicked', {
          location: 'Nav Bar',
        });
      },
    },
    ...(isPremiumUser
      ? []
      : [
          {
            label: 'Referrals',
            id: 'earn_referral',
            to: `${PRIVATE_BASE_PATH}referrals`,
            Icon: GiftNav,
            onClick: () => {
              segmentTracking('referrals clicked', {
                location: 'Nav Bar',
              });
            },
          },
        ]),
    {
      label: 'Help',
      id: 'help',
      isActiveFilter: 'help_center',
      to: PRIVATE_BASE_PATH + 'help_center',
      Icon: HelpIcon,
      onClick: () => {
        segmentTracking('help clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Logout',
      id: 'logout',
      Icon: LogoutIcon,
      to: '/logout',
      onClick: () => {
        segmentTracking('logout clicked', {
          location: 'Nav Bar',
        });
      },
    },
  ];

  const OWNER_NAV_ITEMS = [
    {
      label: 'Dashboard',
      id: 'dashboard',
      isActiveFilter: 'dashboard',
      to: PRIVATE_BASE_PATH + 'dashboard',
      Icon: DashboardIcon,
      onClick: () => {
        segmentTracking('dashboard clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Properties',
      id: 'properties',
      isActiveFilter: 'properties',
      to: PRIVATE_BASE_PATH + 'properties',
      Icon: HomeIcon,
      onClick: () => {
        segmentTracking('properties clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Messages',
      id: 'messages',
      isActiveFilter: 'owners/messages',
      to: PRIVATE_BASE_PATH + 'messages',
      Icon: MessagesIcon,
      onClick: () => {
        segmentTracking('messages clicked', {
          location: 'Nav Bar',
        });
      },
    },
    {
      label: 'Renters',
      id: 'renters',
      isActiveFilter: 'renters',
      to: PRIVATE_BASE_PATH + 'renters',
      Icon: ProfileIcon,
      onClick: () => {
        segmentTracking('renters clicked', {
          location: 'Nav Bar',
        });
      },
    },
  ];

  const RENTER_NAV_ITEMS = [
    {
      label: 'Home',
      id: 'dashboard',
      isActiveFilter: 'dashboard',
      to: PRIVATE_BASE_PATH + 'dashboard',
      Icon: HomeIcon,
    },
    {
      label: 'Messages',
      id: 'messages',
      isActiveFilter: 'messages',
      to: PRIVATE_BASE_PATH + 'messages',
      Icon: MessagesIcon,
    },
    {
      label: 'Toolbox',
      id: 'toolbox',
      href: 'https://www.turbotenant.com/renter-toolbox/',
      Icon: ToolboxNav,
      onClick: () => {
        segmentTracking('toolbox clicked', {
          location: 'Tenants Nav Bar',
        });
      },
    },
    {
      label: 'Help',
      id: 'help_center',
      isActiveFilter: 'help_center',
      to: PRIVATE_BASE_PATH + 'help_center',
      Icon: HelpIcon,
    },
    {
      label: 'Settings',
      id: 'settings',
      isActiveFilter: 'settings',
      to: PRIVATE_BASE_PATH + 'settings',
      Icon: SettingsIcon,
    },
  ];

  return (
    <>
      <MobileNavigation
        location={location}
        isHidden={isHidden}
        isOwner={IS_OWNER}
        ownerNavItems={OWNER_NAV_ITEMS}
        ownerDrawerItems={OWNER_DRAWER_ITEMS}
        renterNavItems={RENTER_NAV_ITEMS}
        unreadMessagesCount={newMessagingCount}
        userDataLoaded={userLoading}
        user={user}
      />
      <ReiHubLoadingModal open={isModalOpen} />
    </>
  );
};

export default MobileNavigationWrapped;
