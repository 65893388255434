import React, { useEffect, useState } from 'react';
import EventEmitter from 'events';
import { any, object } from 'prop-types';

import ErrorIcon from '../icons/interface-feedback/report-problem-triangle';
import InfoIcon from '../icons/status/check-2';
import * as colors from '../styles/colors';
import { css, withStyles } from '../styles/with-styles';

const Emitter = new EventEmitter();

const iconStyle = { marginRight: 10 };
const IconInfo = <InfoIcon color={colors.gray5} style={iconStyle} />;
const IconError = <ErrorIcon color={colors.gray5} style={iconStyle} />;

const Message = (props) => {
  const [state, setState] = useState({
    message: '',
    error: true,
  });

  useEffect(() => {
    Emitter.addListener('status_message', ({ message, error }) => {
      setState({
        message,
        error,
      });
      setTimeout(() => {
        setState({
          message: '',
          error: true,
        });
      }, 5000);
    });
  }, []);

  const { styles } = props;
  return (
    state.message && (
      <div style={{ width: '100%', height: '100%' }}>
        <div {...css(styles.infoStyle)}>
          <div {...css(styles.iconContainer)}>
            {state.error ? IconError : IconInfo}
          </div>
          <div {...css(styles.textContainer)}>{state.message}</div>
        </div>
      </div>
    )
  );
};

Message.propTypes = {
  styles: object,
  children: any,
};

export const showInfoMessage = (message) => {
  Emitter.emit('status_message', {
    message,
    error: false,
  });
};

export const showErrorMessage = (message) => {
  Emitter.emit('status_message', {
    message,
    error: true,
  });
};

export const MessageComponent = withStyles(({ colors, typography, media }) => ({
  infoStyle: {
    backgroundColor: colors.action3,
    color: colors.white,
    borderRadius: 10,
    height: 'auto',
    width: 568,
    position: 'fixed',
    zIndex: 2000,
    top: 56,
    margin: 'auto',
    left: 0,
    right: 0,
    padding: 7,
    display: 'flex',
    ...media.smallScreen({
      width: 330,
    }),
    ...media.xSmallScreen({
      width: '90%',
    }),
  },
  textContainer: {
    flex: 1,
    margin: 'auto',
    ...typography.semiboldText,
  },
  iconContainer: {
    margin: 'auto',
    display: 'block',
    flex: 1,
    maxWidth: 25,
    marginRight: 10,
    paddingTop: 5,
  },
}))(Message);
