import React from 'react';
import { func, object, string } from 'prop-types';

import BlockWithColorBar from '../BlockWithColorBar';
import { PrimaryButton } from '../Button';
import Caution from '../icons/UIExperience/caution';
import { Column, Row } from '../Layout';
import { css, withStyles } from '../styles/with-styles';

const PaymentStatusNotice = ({
  styles,
  title,
  titleId,
  description,
  icon,
  buttonLabel,
  buttonId,
  buttonAction,
  borderLeftColor,
  containerStyles,
  mainRowVertical = 'center',
}) => {
  return (
    <BlockWithColorBar
      colorBar={borderLeftColor}
      contentStyles={styles.contentStyles}
      style={containerStyles}
    >
      <Row vertical={mainRowVertical} horizontal="spaced" onSmall="column">
        <Row vertical="center">
          {icon && icon}
          <Column>
            {title && (
              <span {...css(styles.smallTitle)} id={titleId}>
                {title}
              </span>
            )}
            {description && description}
          </Column>
        </Row>
        {buttonLabel && (
          <PrimaryButton
            fullWidth
            id={buttonId}
            label={buttonLabel}
            onClick={buttonAction}
            size={'large'}
            {...css(styles.btn)}
          />
        )}
      </Row>
    </BlockWithColorBar>
  );
};

PaymentStatusNotice.propTypes = {
  history: object,
  styles: object,
  title: string,
  titleId: string,
  description: object,
  icon: object,
  buttonLabel: string,
  buttonId: string,
  buttonAction: func,
  borderLeftColor: string,
  containerStyles: object,
  mainRowVertical: string,
};

PaymentStatusNotice.defaultProps = {
  icon: <Caution style={{ marginRight: 12, height: 56, width: 75 }} />,
  borderLeftColor: '#FF3B3B',
  containerStyles: { marginBottom: 16 },
};

export default withStyles(({ media, colors, typography }) => ({
  smallTitle: {
    color: colors.darkBlueGrey,
    fontSize: 20,
    lineHeight: '34px',
    ...typography.boldText,
  },
  contentStyles: {
    padding: '24px 32px 24px 16px',
    borderRadius: '4px 4px 0px 0px',
    border: '1px solid #E6E9F0',
    ...media.smallScreen({
      padding: '24px 16px 24px 8px',
    }),
  },
  btn: {
    ...media.smallScreen({
      marginTop: 12,
    }),
  },
}))(PaymentStatusNotice);
