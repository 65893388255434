import React from 'react';
import { array, bool, func, object, oneOfType, string } from 'prop-types';

import LoadingScreen from '../../components/LoadingScreen';
import { TextButton } from '../Button';
import BackArrow from '../icons/arrows/arrow-left-7';
import { css, withStyles } from '../styles/with-styles';

import SectionTitle from './components/SectionTitle';

class PageSimple extends React.Component {
  static propTypes = {
    styles: object,
    children: oneOfType([object, array]),
    loading: bool,
    title: string,
    backButtonLabel: string,
    backButtonAction: func,
  };

  render() {
    const { styles, title, loading, backButtonLabel, backButtonAction } =
      this.props;

    return (
      <div {...css(styles.pageSimpleContainer)}>
        {backButtonLabel && (
          <div>
            <TextButton
              icon={BackArrow}
              onClick={backButtonAction}
              label={backButtonLabel}
              {...css(styles.backButton)}
            />
          </div>
        )}
        <LoadingScreen loading={loading} style={{ minHeight: '50vh' }}>
          {title && <SectionTitle title={title} />}
          {this.props.children}
        </LoadingScreen>
      </div>
    );
  }
}

export default withStyles(({ layout, media }) => ({
  backButton: {
    marginTop: 2,
    marginLeft: 10,
    marginBottom: 15,
    ...media.hideOnPrint,
  },
  pageSimpleContainer: {
    position: 'relative',
    ...layout.column,
    minHeight: '50vh',
    ...media.print({
      display: 'block',
    }),
  },
}))(PageSimple);
