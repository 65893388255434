import React from 'react';
import PropTypes from 'prop-types';

import { ThemedStyleSheet } from '../styles/with-styles';
import { isMobileDevice } from '../utils';

const theme = ThemedStyleSheet.get();

const { colors, typography } = theme;
const disabledColor = colors.action3on70;
const mainColor = colors.action3;
const hoverColor = colors.action2;

const defaultButtonStyle = {
  display: 'inline-block',
  letterSpacing: 0.25,
  ...typography.noSelect,
  ...typography.semiboldText,
  textTransform: 'uppercase',
};

export class TextButton extends React.Component {
  state = { isHover: false };

  static propTypes = {
    icon: PropTypes.object,
    label: PropTypes.any,
    labelColor: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    styles: PropTypes.object,
    style: PropTypes.object,
    labelStyles: PropTypes.object,
    buttonStyle: PropTypes.object,
    iconStyles: PropTypes.object,
    size: PropTypes.string,
  };

  static defaultProps = {
    buttonStyle: {},
    iconStyles: {},
    labelStyles: {},
  };

  onMouseEnter = () => {
    this.setState({ isHover: true });
  };

  onMouseLeave = () => {
    this.setState({ isHover: false, isPressed: false });
  };

  getColor() {
    const { disabled, labelColor } = this.props;
    const { isHover } = this.state;
    if (disabled) {
      return disabledColor;
    }
    if (isHover && !isMobileDevice()) {
      return labelColor || hoverColor;
    }
    return labelColor || mainColor;
  }

  getSize() {
    const { size } = this.props;
    if (!size) {
      return 22;
    }
    switch (size) {
      case 'small':
        return 22;
      case 'large':
        return 26;
      case 'xlarge':
        return 32;
    }
  }

  render = () => {
    const {
      disabled,
      icon: IconType,
      style,
      size,
      labelStyles,
      buttonStyle,
      iconStyles,
      ...otherProps
    } = this.props;
    const isSmall = size === 'small';
    const myColor = this.getColor();
    const iconSize = this.getSize();
    return (
      <div style={style}>
        <div
          disabled={disabled}
          style={{
            ...defaultButtonStyle,
            color: myColor,
            fontSize: isSmall ? 14 : 16,
            cursor: disabled ? 'auto' : 'pointer',
            ...buttonStyle,
          }}
          {...otherProps}
          onClick={(e) => {
            disabled
              ? e.preventDefault()
              : this.props.onClick && this.props.onClick(e);
          }}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          {IconType && (
            <IconType
              color={myColor}
              style={{
                position: 'relative',
                top: 5,
                marginRight: 7,
                width: iconSize,
                height: iconSize,
                ...iconStyles,
              }}
            />
          )}
          <span
            style={{
              ...labelStyles,
            }}
          >
            {this.props.label}
          </span>
        </div>
      </div>
    );
  };
}
