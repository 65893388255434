import React from 'react';
import PropTypes from 'prop-types';

import { isSmallScreen } from '../styles/media-queries';
import { css, withStyles } from '../styles/with-styles';

const wrapperStyle = {
  maxWidth: 700,
  margin: 'auto',
};

class Stepper extends React.Component {
  static propTypes = {
    selectedStepIndex: PropTypes.number.isRequired,
    steps: PropTypes.array.isRequired,
    style: PropTypes.object,
    styles: PropTypes.object,
    renderLarge: PropTypes.bool,
  };

  static defaultProps = {
    renderFullLabels: false,
  };

  render = () => {
    const { styles, style, selectedStepIndex, steps, renderFullLabels } =
      this.props;
    const stepsComponents = steps.map((step, index) =>
      this.renderStep(step, index, selectedStepIndex === index),
    );
    return (
      <div>
        {isSmallScreen() && !renderFullLabels ? (
          <div style={{ ...wrapperStyle, ...style }}>
            <div {...css(styles.stepsSmallContainer)}>
              {`Step ${selectedStepIndex + 1} of ${steps && steps.length}`}
            </div>
          </div>
        ) : (
          <div style={{ ...wrapperStyle, ...style }}>
            <div {...css(styles.stepsContainer)}>{stepsComponents}</div>
          </div>
        )}
      </div>
    );
  };

  renderStep = (step, index, selected) => {
    const { styles } = this.props;
    return (
      <span
        key={index}
        {...css(
          styles.stepElement,
          selected ? styles.activeElement : styles.notActiveElement,
        )}
      >
        {index + 1}. {step}
      </span>
    );
  };
}
export default withStyles(({ layout, colors, typography, media }) => ({
  stepsContainer: {
    ...layout.row,
    ...layout.spaceBetween,
    ...typography.semiboldText,
    fontSize: 16,
    color: colors.grey,
  },
  stepsSmallContainer: {
    justifyContent: 'flex-end',
    fontSize: 16,
    color: 'rgb(51,64,76)',
    fontWeight: '600',
  },
  stepElement: {
    padding: '5px 2px',
    margin: '0 10px',
    ...media.smallScreen({
      margin: '0 6px',
      fontSize: '16px',
    }),
  },
  notActiveElement: {
    opacity: 0.7,
  },
  activeElement: {
    color: colors.action3,
    borderBottomColor: colors.action3,
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
  },
  ...media.hideSmall,
  ...media.hideLarge,
}))(Stepper);
