import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import FlatButton from '../../../../../../components/FlatButton';
import { Column, Row } from '../../../../../../components/Grid';
import { getPaymentRequestRuleDueToNarrative } from '../../../../../../helpers/getPaymentRequestRuleDueToNarrative';
import DeleteIcon from '../../../../../../icons/Delete';
import EditIcon from '../../../../../../icons/Edit';
import { getRentPaymentCategoryLabel } from '../../../../../../services/utilities';

import styles from './ChargeCollapsed.module.scss';

const ChargeCollapsed = ({ charge, onEdit, onDelete, className }) => {
  const {
    category,
    description,
    amount,
    late_fee_amount,
    late_fee_grace_period,
    is_late_fee_percentage,
  } = charge;

  return (
    <div className={cx(styles.container, className)}>
      <Row>
        <Column span={11}>
          <Row>
            <Column span={10}>
              <span className={styles.category}>
                {getRentPaymentCategoryLabel(category)}
              </span>
              {description && (
                <span className={styles.description}>
                  &nbsp;-&nbsp;{description}
                </span>
              )}
            </Column>
          </Row>
        </Column>
        <Column span={1} className={styles.buttonColumn}>
          <FlatButton
            id={`edit_charge_button`}
            onClick={onEdit}
            icon={EditIcon}
            className={styles.button}
            iconProps={{ className: styles.trashIcon }}
          />
          <FlatButton
            id={`delete_charge_button`}
            onClick={onDelete}
            icon={DeleteIcon}
            iconProps={{ className: styles.trashIcon }}
            className={styles.button}
          />
        </Column>
      </Row>
      <Row>
        <Column span={2} className={styles.amountColumn}>
          <span className={styles.amount}>${amount}</span>
        </Column>
      </Row>
      <p className={styles.dueNarrative}>
        {getPaymentRequestRuleDueToNarrative(charge)}
      </p>
      {late_fee_amount && (
        <>
          <Row>
            <Column span={11}>
              <Row>
                <Column span={12}>
                  <span className={styles.category}>Automatic Late Fee</span>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row>
            <Column span={2} className={styles.amountColumn}>
              <span className={styles.amount}>
                {is_late_fee_percentage || '$'}
                {late_fee_amount}
                {is_late_fee_percentage && '%'}
              </span>
            </Column>
          </Row>
          <p className={styles.lateFeeNarrative}>
            Sent {late_fee_grace_period}{' '}
            {late_fee_grace_period > 1 ? 'days' : 'day'} after rent is due if
            outstanding balance exists
          </p>
        </>
      )}
    </div>
  );
};

ChargeCollapsed.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  charge: PropTypes.shape({
    category: PropTypes.string,
    description: PropTypes.string,
    // We expect this to be already formatted which is why it's a string.
    // If we allow a number then something like 3.5 will show with only
    // one decimal.
    amount: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

export default ChargeCollapsed;
