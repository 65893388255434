import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

let LogoFaceBook = (props) => (
  <SvgIcon {...props} viewBox={props.viewBox || '0 0 18 18'}>
    <g
      id="Authentication"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Login"
        transform="translate(-535.000000, -276.000000)"
        fill="#FFFFFF"
      >
        <g transform="translate(448.000000, 104.000000)" id="facebook-sign-up">
          <g transform="translate(76.000000, 161.000000)">
            <path
              d="M28.0065719,11 L11.9934281,11 C11.4447159,11 11,11.4446484 11,11.9934281 L11,28.0065719 C11,28.5552167 11.4447159,29 11.9934281,29 L20.6143338,29 L20.6143338,22.0294799 L18.268616,22.0294799 L18.268616,19.3128958 L20.6143338,19.3128958 L20.6143338,17.3095161 C20.6143338,14.9845707 22.0343357,13.7186074 24.1083277,13.7186074 C25.1017558,13.7186074 25.9556453,13.7925918 26.2044407,13.8256386 L26.2044407,16.2553223 L24.766027,16.2559968 C23.6380511,16.2559968 23.4196722,16.7919624 23.4196722,17.5784768 L23.4196722,19.3128958 L26.1097514,19.3128958 L25.7594551,22.0294799 L23.4196722,22.0294799 L23.4196722,29 L28.0065719,29 C28.5552167,29 29,28.5552167 29,28.0065719 L29,11.9934281 C29,11.4446484 28.5552167,11 28.0065719,11"
              id="Imported-Layers-Copy"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

LogoFaceBook = memo(LogoFaceBook);

LogoFaceBook.displayName = 'LogoFaceBook';

LogoFaceBook.muiName = 'SvgIcon';

LogoFaceBook.defaultProps = {
  style: {
    width: 18,
    height: 18,
  },
};

export default LogoFaceBook;
