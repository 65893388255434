import React from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton, SecondaryButton } from '../Button';
import { isSmallScreen } from '../styles/media-queries';
import { css } from '../styles/with-styles';
import { ThemedStyleSheet } from '../styles/with-styles';

const theme = ThemedStyleSheet.get();

export default class SmallCard extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    titleStyle: PropTypes.object,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    button1Label: PropTypes.string,
    button1Action: PropTypes.func,
    button1Id: PropTypes.string,
    button1Disabled: PropTypes.bool,
    button2Label: PropTypes.string,
    button2Action: PropTypes.func,
    button2Id: PropTypes.string,
    button2Disabled: PropTypes.bool,
    button2Styles: PropTypes.object,
    style: PropTypes.object,
    buttonsContainerStyles: PropTypes.object,
    button1Styles: PropTypes.object,
    buttonSize: PropTypes.string,
    forceMobile: PropTypes.bool,
    renderCardBorder: PropTypes.bool,
  };

  render = () => {
    const smallScreen = isSmallScreen() || this.props.forceMobile;
    const buttonSize = this.props.buttonSize || 'large';
    const {
      renderCardBorder,
      button2Styles,
      buttonsContainerStyles = {},
      button1Styles,
    } = this.props;
    const smallCardStyles = {
      minWidth: smallScreen ? 297 : 305,
      padding: smallScreen ? 16 : 32,
      paddingBottom: 24,
      paddingTop: 10,
      border:
        renderCardBorder || renderCardBorder === undefined ? '1px solid' : null,
      borderRadius: 4,
      boxSizing: 'border-box',
      borderColor: theme.colors.lightGrey,
      backgroundColor: theme.colors.white,
    };

    const textStyles = {
      marginBottom: smallScreen ? 16 : 24,
    };

    const buttonsDivStyles = {
      textAlign: 'center',
      marginLeft: smallScreen ? 16 : 12,
      marginRight: smallScreen ? 16 : 12,
      ...buttonsContainerStyles,
    };

    const titleStyle = {
      ...theme.typography.smallCardTitle,
      marginBottom: 2,
      ...this.props.titleStyle,
    };

    return (
      <div {...css({ ...smallCardStyles, ...this.props.style })}>
        <div {...css(textStyles)}>
          <p style={titleStyle}>{this.props.title}</p>
          {this.props.subtitle ? (
            <p {...css({ color: theme.colors.grey, marginBottom: 2 })}>
              {this.props.subtitle}
            </p>
          ) : (
            ''
          )}
          <p {...css({ color: theme.colors.darkBlueGrey })}>
            {this.props.description}
          </p>
        </div>
        {this.props.button2Label ? (
          <div {...css(buttonsDivStyles)}>
            <PrimaryButton
              fullWidth={true}
              disabled={this.props.button1Disabled}
              label={this.props.button1Label}
              onClick={this.props.button1Action}
              id={this.props.button1Id}
              size={buttonSize}
              style={{ marginBottom: 12, ...button1Styles }}
            />
            <SecondaryButton
              fullWidth={true}
              disabled={this.props.button2Disabled}
              label={this.props.button2Label}
              onClick={this.props.button2Action}
              id={this.props.button2Id}
              size={buttonSize}
              style={button2Styles}
            />
          </div>
        ) : (
          <div {...css(buttonsDivStyles)}>
            <SecondaryButton
              fullWidth={true}
              disabled={this.props.button1Disabled}
              label={this.props.button1Label}
              onClick={this.props.button1Action}
              id={this.props.button1Id}
              size={buttonSize}
            />
          </div>
        )}
      </div>
    );
  };
}
