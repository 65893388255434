import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import { loginCometChatUser } from '../../components/CometChatWorkspace/utils';
import FullPageError from '../../components/FullPageError';
import LoadingScreen from '../../components/LoadingScreen';
import * as authService from '../../core/auth/authService';

import confirmDeviceMutation from './graphql/confirmDevice.graphql';

const ConfirmDevice = ({ match, history }) => {
  const { confirmationToken } = match.params;
  const [confirmDevice, { error }] = useMutation(confirmDeviceMutation);

  const confirm = async () => {
    const res = await confirmDevice({
      variables: {
        confirmationToken,
      },
    });

    const token = get(res, 'data.confirmDevice.token');
    const messagingToken = get(res, 'data.confirmDevice.messagingToken');
    if (messagingToken) {
      loginCometChatUser(messagingToken);
    }
    if (token) {
      authService.login(token);
      history.replace('/');
    }
  };
  useEffect(() => {
    if (!confirmationToken) {
      return;
    }
    confirm();
  }, []);

  if (!confirmationToken || error) {
    return (
      <FullPageError
        style={{ marginTop: '100px' }}
        reason={!confirmationToken || error}
      />
    );
  }

  return <LoadingScreen loading />;
};

ConfirmDevice.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ConfirmDevice;
