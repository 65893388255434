import React from 'react';
import loadScript from 'load-script';
import moment from 'moment';
import PropTypes from 'prop-types';

import { withConfig } from '../../providers/ConfigProvider';

let loaded = false;

class WootricSDK extends React.Component {
  componentDidMount() {
    if (loaded) {
      this.initWootric();
      return;
    }
    loadScript('https://cdn.wootric.com/wootric-sdk.js', () => {
      loaded = true;
      try {
        this.initWootric();
      } catch (e) {
        window.atatus &&
          window.atatus.notify(
            new Error('Wootrics init error' + (e && e.toString())),
          );
      }
    });
  }
  componentWillUnmount() {
    if (window.wootric && window.WootricSurvey) {
      try {
        window.WootricSurvey.stop();
      } catch (e) {
        console.log('Wootrics unmount error' + (e && e.toString()));
      }
    }
  }
  componentDidUpdate(prevProps) {
    const { email } = this.props;
    if (email && email !== prevProps.email) {
      try {
        this.initWootric();
      } catch (e) {
        window.atatus &&
          window.atatus.notify(
            new Error('Wootrics init error' + (e && e.toString())),
          );
      }
    }
  }

  initWootric() {
    const { email, created_at, config } = this.props;
    if (email) {
      window.wootricSettings = {
        account_token: config.WOOTRICS_ACCOUNT_TOKEN,
        created_at: moment(created_at, ['MM/DD/YYYY', 'YYYY-MM-DD']).unix(),
        email,
      };
      if (window.wootric) {
        window.wootric('run');
      }
    }
  }
  render() {
    return null;
  }
}

WootricSDK.propTypes = {
  email: PropTypes.string,
  created_at: PropTypes.string,
  config: PropTypes.object,
};

export default withConfig(WootricSDK);
