import basecard from './basecard.component';
import basepropertycard from './basepropertycard.component';
import centeredcard from './centeredcard.component';
import helpcard from './help-card';
import invitecard from './invite-card';
import largecard from './largecard.component';
import listitemcard from './listitemcard.component';
import propertyListingCard from './propertylistingcard.component';
import renterCard from './renter-card.component';
import smallcard from './smallcard.component';

export const SmallCard = smallcard;
export const LargeCard = largecard;
export const CenteredCard = centeredcard;
export const PropertyListingCard = propertyListingCard;
export const RenterCard = renterCard;
export const BaseCard = basecard;
export const HelpCard = helpcard;
export const InviteCard = invitecard;
export const ListItemCard = listitemcard;
export const BasePropertyCard = basepropertycard;
