import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import banner from '../../../../assets/banner-offline-to-online.png';
import bannerMobile from '../../../../assets/banner-offline-to-online-mobile.png';
import { isSmallScreen } from '../../../../commons';
import BulletPoint from '../../../../components/BulletPoint';
import Button from '../../../../components/Button';
import { H2, Link, Paragraph, Span } from '../../../../components/Typography';
import BANNER_NAMES from '../../../../constants/banner_names';
import { useUserProfile } from '../../../../core/TTgraphql';
import useDismissBanner from '../../../../helpers/useDismissBanner';
import { useConfig } from '../../../../providers/ConfigProvider';
import { segmentTracking } from '../../../../services/utilities';

import styles from './OfflineToOnlineConversionPage.module.scss';

const OfflineToOnlineConversionPage = () => {
  const { user } = useUserProfile({}, false);
  const { PRIVATE_BASE_PATH } = useConfig();
  const [dismissBannerMutation] = useDismissBanner();
  const { state } = useLocation();
  const firstName = user?.first_name || '';

  useEffect(() => {
    dismissBannerMutation({
      variables: {
        bannerName: BANNER_NAMES.OFFLINE_TO_ONLINE_CONVERSION_PAGE,
        dismissFor: 30,
      },
    });
  }, []);

  const onGetStarted = () => {
    segmentTracking('get_started clicked', {
      location: 'Record Payment Offline Tracking Promo',
    });
  };
  const onMaybeLater = () => {
    segmentTracking('maybe_later clicked', {
      location: 'Record Payment Offline Tracking Promo',
    });
  };
  const onLearnMore = () => {
    segmentTracking('learn_more clicked', {
      location: 'Record Payment Offline Tracking Promo',
    });
  };
  const onSeeTenantExperience = () => {
    segmentTracking('see_tenant_experience clicked', {
      location: 'Record Payment Offline Tracking Promo',
    });
  };

  const to = state?.to || `${PRIVATE_BASE_PATH}payments`;

  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <img src={isSmallScreen ? bannerMobile : banner} />
      </div>
      <div className={styles.content}>
        <Paragraph size="large" weight="semi">
          Hey {firstName} 👋
        </Paragraph>
        <H2 className={styles.header}>
          Tired of Recording Charges Manually? Start Collecting Rent with
          TurboTenant!
        </H2>
        <Paragraph italic className={styles.subheader}>
          You’ll benefit from everything included in tracking, plus…
        </Paragraph>
        <BulletPoint type="checkmark" color="lightGreenAlt">
          <Paragraph size="large" weight="semi">
            Tenant payments and deposits are automatically recorded, simplifying
            what you have to do.
          </Paragraph>
        </BulletPoint>
        <BulletPoint type="checkmark" color="lightGreenAlt">
          <Paragraph size="large" weight="semi">
            Tenants will be able to pay through their tenant portal.
          </Paragraph>
        </BulletPoint>
        <BulletPoint type="checkmark" color="lightGreenAlt">
          <Paragraph size="large" weight="semi">
            Free for landlords.{' '}
            <Link
              target="_blank"
              onClick={onLearnMore}
              href="https://support.turbotenant.com/en/articles/4004080-rent-payments-guide#some-questions-you-may-still-have"
            >
              Learn more
            </Link>
            .
          </Paragraph>
        </BulletPoint>
        <BulletPoint type="checkmark" color="lightGreenAlt">
          <Paragraph size="large">
            <Span size="large" weight="semi">
              Industry-standard security
            </Span>{' '}
            – We use the same processes that big banks use to keep your payments
            secure.
          </Paragraph>
        </BulletPoint>
        <BulletPoint type="checkmark" color="lightGreenAlt">
          <Paragraph size="large">
            <Span size="large" weight="semi">
              Less than 5 minutes to set up
            </Span>{' '}
            – Just provide your bank details to connect your account for
            deposits.
          </Paragraph>
        </BulletPoint>
      </div>
      <div className={styles.footer}>
        <div className={styles.btnWrapper}>
          <Button secondary to={to} onClick={onMaybeLater}>
            Maybe Later
          </Button>
          <Button
            to="/owners/payments/setup/collection?offlineConversion=true&bankStep=true"
            onClick={onGetStarted}
          >
            Get Started
          </Button>
        </div>
        <Link
          onClick={onSeeTenantExperience}
          size="large"
          target="_blank"
          href="https://support.turbotenant.com/en/articles/4004078-how-does-a-renter-setup-their-account-to-pay-rent-on-turbotenant"
        >
          See the Tenant Experience
        </Link>
      </div>
    </div>
  );
};

export default OfflineToOnlineConversionPage;
