import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const CheckDouble = (props) => (
  <svg {...props} fill={props.color} viewBox="0 0 35 24">
    <path
      d="m31.33 1.113 3.954 3.924a1.064 1.064 0 0 1 0 1.513L17.21 24.487a1.084 1.084 0 0 1-1.525 0L7.777 16.64a1.064 1.064 0 0 1 0-1.513l3.954-3.925a1.084 1.084 0 0 1 1.525 0l3.192 3.168L29.805 1.113a1.084 1.084 0 0 1 1.525 0ZM6.194 11.202l1.979 1.961-1.525 1.514-1.216-1.205-2.429 2.41 6.383 6.334.818-.81 1.524 1.513-1.58 1.567a1.084 1.084 0 0 1-1.524 0L.716 16.638a1.064 1.064 0 0 1 0-1.514l3.954-3.923a1.084 1.084 0 0 1 1.524 0Zm24.374-7.819L17.21 16.64a1.084 1.084 0 0 1-1.525 0l-3.191-3.168-2.43 2.411 6.383 6.334 16.55-16.424-2.43-2.41Zm-6.297-2.27 2.156 2.14-1.525 1.514-1.394-1.384-7.985 7.924-1.525-1.514 8.748-8.68a1.084 1.084 0 0 1 1.525 0Z"
      fillRule="evenodd"
    />
  </svg>
);

CheckDouble.defaultProps = {
  width: 35,
  height: 24,
  color: colors.black,
};

CheckDouble.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(CheckDouble);
