import React from 'react';
import cx from 'classnames';

import RenthopLogoImg from '../../../assets/renthop/renthopLogo.png';

import styles from './RenthopLogo.module.scss';

const RenthopLogo = ({ className }) => (
  <img
    src={RenthopLogoImg}
    alt="Renthop"
    className={cx(styles.renthopLogo, className)}
  />
);

export default RenthopLogo;
