import React from 'react';

import styles from './Section.module.scss';

const Section = ({ title, withDivider = false, children }) => (
  <>
    {withDivider && <div className={styles.divider} />}
    <div className={styles.subTitle}>{title}</div>
    {children}
  </>
);

export default Section;
