const composeValidators =
  (...validators) =>
  (value, formValues) => {
    if (validators.some((v) => typeof v !== 'function')) {
      console.warn('Invalid validator');
    }
    return validators
      .filter((v) => typeof v === 'function')
      .reduce(
        (error, validator) => error || validator(value, formValues),
        undefined,
      );
  };

export default composeValidators;
