import React from 'react';
import PropTypes from 'prop-types';

import ContentEditionBin1 from '../icons/content-edition/bin-1';
import RotateIcon from '../icons/design-actions/rotate-left';
import AddIcon from '../icons/remove-add/add-circle-1';
import { css, ThemedStyleSheet, withStyles } from '../styles/with-styles';

const theme = ThemedStyleSheet.get();

const sizeSmall = {
  width: 130,
  height: 111,
};

const sizeSmallFeatured = {
  width: 124,
  height: 111,
};

const sizeLarge = {
  width: 296,
  height: 194,
};

const sizeLargeFullWidth = {
  width: '100%',
  height: 194,
};
const sizeSmallFullWidth = {
  width: '100%',
  height: 142,
};
const sizeUploadDocuments = {
  width: '100%',
  maxWidth: 424,
  height: 120,
};
const sizeTenants = {
  width: '100%',
  height: 130,
};
const disabledBackgroundColor = theme.colors.lightBlueGrey;
class AddButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hovered: false,
    };
  }

  static propTypes = {
    icon: PropTypes.object,
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    styles: PropTypes.object,
    style: PropTypes.object,
    iconSize: PropTypes.object,
    size: PropTypes.string,
    showAddIcon: PropTypes.bool,
    className: PropTypes.string,
    showDeleteButton: PropTypes.bool,
    showRotateButton: PropTypes.bool,
    onClickDeleteButton: PropTypes.func,
    onClickRotateButton: PropTypes.func,
    iconButton: PropTypes.object,
    imageUrl: PropTypes.string,
    secondaryUrl: PropTypes.string,
    rotation: PropTypes.number,
    isFeatured: PropTypes.bool,
    disableEdit: PropTypes.bool,
  };

  // @TODO - this file is a mess right now..  'Feature' stuff should not go in here, and we need to use maps to organize different configurations.. not so many ifs
  render() {
    const size = this.props.size || 'large';
    const {
      icon: IconType,
      style,
      iconButton,
      styles,
      showAddIcon = true,
      rotation = 0,
      isFeatured = false,
      disableEdit = false,
      iconSize = size === 'smallFullWidth'
        ? {
            width: 37,
            height: 37,
          }
        : { width: 65, height: 65 },
    } = this.props;
    const showIcon =
      IconType &&
      (size === 'large' ||
        size === 'largeFullWidth' ||
        size === 'smallFullWidth' ||
        size === 'sizeTenants');
    const mainColor = this.props.disabled
      ? disabledBackgroundColor
      : theme.colors.grey;

    let DefaultButtonIcon = AddIcon;
    let buttonStyle = { marginRight: 4, top: 4, width: 17, height: 17 };
    if (iconButton) {
      DefaultButtonIcon = iconButton;
      buttonStyle = { marginRight: 4, top: 2, width: 13, height: 13 };
    }
    const addIconButton = (
      <DefaultButtonIcon
        color={this.state.hovered ? theme.colors.action2 : mainColor}
        style={{
          position: 'relative',
          width: 21,
          height: 21,
          ...(!showIcon
            ? { display: 'block', margin: 'auto', marginBottom: 5 }
            : buttonStyle),
        }}
      />
    );

    const imageStyle = {
      transform: this.props.imageUrl ? `rotate(${-90 * rotation}deg)` : '',
      maxWidth: rotation % 2 ? 109 : isFeatured ? 122 : 128,
      maxHeight: rotation % 2 ? (isFeatured ? 122 : 128) : 109,
    };

    const featuredStyle = isFeatured ? css(styles.featuredStyle) : {};
    return (
      <div {...featuredStyle}>
        {isFeatured && (
          <span {...css(styles.featuredLabel)}>FEATURED PHOTO*</span>
        )}
        <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
          {this.props.imageUrl && (
            <div
              {...css(
                isFeatured ? styles.featuredImageStyle : styles.imageStyle,
              )}
            >
              <img
                src={this.props.imageUrl}
                style={imageStyle}
                onError={() => {
                  console.log('THUMB FAILED');
                  this.src = this.props.secondaryUrl;
                }}
              />
            </div>
          )}
          {(this.props.showDeleteButton || this.props.showRotateButton) && (
            <div
              {...css(styles.buttonDeleteOrRotate)}
              onClick={this.props.onClick}
            >
              {this.props.showDeleteButton && (
                <div
                  {...css(styles.divLeftIconStyles)}
                  onClick={this.props.onClickDeleteButton}
                >
                  <ContentEditionBin1 color={theme.colors.primary} />
                </div>
              )}
              {this.props.showRotateButton && (
                <div
                  {...css(styles.divRightIconStyles)}
                  onClick={this.props.onClickRotateButton}
                >
                  <RotateIcon color={theme.colors.primary} />
                </div>
              )}
            </div>
          )}
          <span
            style={style}
            className={this.props.className}
            onMouseEnter={() => this.setState({ hovered: true })}
            onMouseLeave={() => this.setState({ hovered: false })}
          >
            <div
              disabled={this.props.disabled}
              {...css(
                styles.buttonStyle,
                disableEdit
                  ? styles.buttonStyleBorderSolid
                  : styles.buttonStyleBorderDashed,
                size === 'large'
                  ? sizeLarge
                  : size === 'largeFullWidth'
                  ? sizeLargeFullWidth
                  : size === 'smallFullWidth'
                  ? sizeSmallFullWidth
                  : size === 'sizeUploadDocuments'
                  ? sizeUploadDocuments
                  : size === 'sizeTenants'
                  ? sizeTenants
                  : isFeatured
                  ? sizeSmallFeatured
                  : sizeSmall,
              )}
              onClick={this.props.disabled ? null : this.props.onClick}
            >
              <div {...css(styles.labelWrapper)}>
                {showIcon && (
                  <IconType
                    color={
                      this.state.hovered ? theme.colors.action2 : mainColor
                    }
                    style={{
                      ...iconSize,
                    }}
                  />
                )}
                <span>
                  {showAddIcon && addIconButton}
                  {!this.props.imageUrl && (
                    <span
                      {...css(styles.label, {
                        color: this.state.hovered
                          ? theme.colors.action2
                          : mainColor,
                      })}
                    >
                      {this.props.label}
                    </span>
                  )}
                </span>
              </div>
            </div>
          </span>
        </div>
      </div>
    );
  }
}

export default withStyles(({ colors, typography, shadows, media }) => ({
  buttonStyle: {
    'borderRadius': 4,
    'textAlign': 'center',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center',
    'cursor': 'pointer',
    ...typography.noSelect,
    ':hover': {
      backgroundColor: colors.lightGrey,
      borderColor: colors.action2,
    },
    ':active': {
      ...shadows.smallInner(),
    },
  },
  buttonStyleBorderDashed: {
    border: '2px dashed #9DA0A3', //pallette?
  },
  buttonStyleBorderSolid: {
    border: '1px solid ' + colors.lightGrey,
  },
  label: {
    textTransform: 'uppercase',
    ...typography.semiboldText,
  },
  labelWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  buttonDeleteOrRotate: {
    'position': 'absolute',
    'width': 130,
    'height': 111,
    'cursor': 'pointer',
    'opacity': 0,
    ':hover': {
      color: 'blue',
      opacity: 1,
    },
    ...media.smallScreen({
      opacity: 1,
    }),
    ...media.xSmallScreen({
      opacity: 1,
    }),
  },
  divLeftIconStyles: {
    'position': 'absolute',
    'padding': 3,
    'top': 10,
    'left': 10,
    'cursor': 'pointer',
    'display': 'flex',
    'alignItems': 'center',
    ':hover': {
      opacity: 0.9,
      borderRadius: '4px',
      backgroundColor: colors.white,
    },
  },
  divRightIconStyles: {
    'position': 'absolute',
    'padding': 3,
    'top': 10,
    'right': 10,
    'cursor': 'pointer',
    'display': 'flex',
    'alignItems': 'center',
    ':hover': {
      opacity: 0.9,
      borderRadius: '4px',
      backgroundColor: colors.white,
    },
  },
  imageStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '130px',
    height: '111px',
  },
  featuredImageStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '124px',
    height: '111px',
  },
  featuredLabel: {
    height: 18,
    width: 111,
    color: colors.black,
    fontSize: 12,
    fontWeight: 600,
    marginTop: 8,
    marginBottom: 8,
    padding: 0,
    // lineHeight: 18
  },
  featuredStyle: {
    padding: 8,
    backgroundColor: colors.lightGrey,
    textAlign: 'center',
    borderRadius: 4,
  },
}))(AddButton);
