import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useDropdownMenuContext } from '../../context';

import styles from './Item.module.scss';

const Item = ({
  className,
  as: As = Link,
  danger,
  small = false,
  icon: Icon,
  iconProps = {},
  children,
  onClick,
  ...rest
}) => {
  const { isOpen, closeOnClick, context } = useDropdownMenuContext();

  const handleClick = () => {
    if (onClick) onClick();

    if (isOpen && closeOnClick) context.onOpenChange(false);
  };

  return (
    <As
      className={cx(
        styles.item,
        { [styles.danger]: danger, [styles.small]: small },
        className,
      )}
      onClick={handleClick}
      {...rest}
    >
      {Icon && (
        <span className={styles.iconWrapper}>
          <Icon {...iconProps} />
        </span>
      )}
      {children}
    </As>
  );
};

Item.propTypes = {
  className: PropTypes.string,
  danger: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.elementType,
  iconProps: PropTypes.object,
  as: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default Item;
