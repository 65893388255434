export const small = () => {
  // @TODO - may be add some parameter
  return {
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0px 2px 5px, rgba(0, 0, 0, 0.12) 0px 2px 3px',
  };
};

export const smallInner = () => {
  // @TODO - may be add some parameter
  return {
    boxShadow: 'inset rgba(0, 0, 0, 0.2) 0px 1px 3px 0px',
  };
};
