import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const CloseModalLight = (props) => (
  <svg {...props} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m8.664 7 4.991 4.992a1.176 1.176 0 1 1-1.663 1.663L7 8.664l-4.992 4.991a1.176 1.176 0 1 1-1.663-1.663L5.336 7 .345 2.008A1.177 1.177 0 0 1 2.008.345L7 5.336 11.992.345a1.176 1.176 0 1 1 1.663 1.663L8.664 7Z"
      fill="#ACB9C8"
    />
  </svg>
);

CloseModalLight.defaultProps = {
  width: 14,
  height: 14,
  color: colors.greyLight,
};

CloseModalLight.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(CloseModalLight);
