import React from 'react';
import { Tabs as MTabs } from 'material-ui';
import PropTypes from 'prop-types';

import { ThemedStyleSheet } from '../styles/with-styles';

const { colors, typography } = ThemedStyleSheet.get();

export class Tabs extends React.Component {
  static propTypes = {
    defaultTab: PropTypes.number,
    currentTab: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    autoWidth: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func,
    onTabChange: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object,
  };

  constructor(props) {
    super(props);
    const selectedTab = props.defaultTab && parseInt(props.defaultTab, 10);
    this.state = {
      selectedTab: selectedTab || 0,
    };
  }

  handleChange = (tab) => {
    const { onTabChange } = this.props;
    if (onTabChange) {
      onTabChange(tab);
    }
  };

  handleActive = (idx) => {
    this.setState({
      selectedTab: idx,
    });
  };

  render = () => {
    const styles = {
      tabItemContainer: {
        backgroundColor: colors.lightGrey,
        width: 'initial',
        display: 'inline-block',
        position: 'relative',
        zIndex: 1,
        top: 1, // to hide the border down the tab
        borderRadius: '4px 4px 0px 0px',
      },
      selectedTab: {
        padding: '0 20px',
        minWidth: 141,
        backgroundColor: 'white',
        color: colors.action3,
        border: '1px solid ' + colors.lightGrey,
        ...typography.semiboldText,
        borderBottom: 0,
        borderRadius: '4px 4px 0px 0px',
      },
      unselectedTab: {
        padding: '0 20px',
        minWidth: 141,
        opacity: '0.7',
        color: colors.darkBlueGrey,
        ...typography.semiboldText,
        backgroundColor: 'transparent',
      },
    };

    const { children, currentTab, onTabChange, ...muiProps } = this.props;
    const { selectedTab } = this.state;
    delete muiProps.defaultTab;

    const childrenWithProps = React.Children.map(children, (child, idx) =>
      React.cloneElement(child, {
        style:
          idx === (currentTab || selectedTab)
            ? styles.selectedTab
            : styles.unselectedTab,
        onActive: () => this.handleActive(idx),
        value: idx,
      }),
    );
    return (
      <MTabs
        {...muiProps}
        onChange={this.handleChange}
        tabTemplateStyle={styles.tabTemplate}
        tabItemContainerStyle={styles.tabItemContainer}
        inkBarStyle={{ display: 'none' }}
        value={currentTab || selectedTab}
      >
        {childrenWithProps}
      </MTabs>
    );
  };
}
