import React from 'react';
import { FlatButton as MFlatButton } from 'material-ui';
import PropTypes from 'prop-types';

import { isSmallScreen } from '../styles/media-queries';
import { ThemedStyleSheet } from '../styles/with-styles';

const theme = ThemedStyleSheet.get();

const pressedStyle = {
  ...theme.shadows.smallInner(),
};

const getSizeStyle = (size) => {
  switch (size) {
    case 'xlarge':
      return {
        minWidth: 232,
        height: 44,
        fontSize: 14,
      };
    case 'large':
      if (isSmallScreen()) {
        return {
          width: '100%',
          height: 48,
          fontSize: 14,
        };
      } else {
        return {
          minWidth: 200,
          height: 44,
          fontSize: 14,
        };
      }
    case 'medium':
      return {
        minWidth: 194,
        height: 44,
        fontSize: 14,
      };
    case 'mediumsmall':
      return {
        minWidth: 232,
        height: 44,
        fontSize: 12,
      };
    case 'small':
      return {
        minWidth: 176,
        height: 40,
        fontSize: 12,
      };
    case 'xsmall':
      return {
        minWidth: 96,
        height: 40,
        fontSize: 12,
      };
    case 'xxsmall':
      return {
        lineHeight: '30px',
        minWidth: 82,
        height: 32,
        top: -3,
      };
  }
  return {};
};
const getLabelStyle = (size) => {
  switch (size) {
    case 'xlarge':
    case 'large':
    case 'medium':
      return {
        fontSize: 14,
        ...theme.typography.boldText,
      };
    case 'mediumsmall':
    case 'small':
    case 'xsmall':
    case 'xxsmall':
      return {
        fontSize: 12,
        ...theme.typography.boldText,
      };
  }
  return {};
};

export class PrimaryButton extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = { isHover: false };
  }

  static propTypes = {
    'Icon': PropTypes.object,
    'iconStyles': PropTypes.object,
    'fullWidth': PropTypes.bool,
    'label': PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    'labelStyle': PropTypes.object,
    'onClick': PropTypes.func,
    'disabled': PropTypes.bool,
    'styles': PropTypes.object,
    'style': PropTypes.object,
    'size': PropTypes.string,
    'backgroundColor': PropTypes.string,
    'className': PropTypes.string,
    'type': PropTypes.string,
    'id': PropTypes.string,
    'data-qa': PropTypes.string,
  };

  onMouseEnter = () => {
    this.setState({ isHover: true });
  };

  onMouseLeave = () => {
    this.setState({ isHover: false, isPressed: false });
  };

  onMouseUp = () => {
    this.setState({ isPressed: false });
  };

  onMouseDown = () => {
    this.setState({ isPressed: true });
  };

  buttonClick = (e) => {
    if (this.props.onClick) {
      e.preventDefault();
      e.stopPropagation();
      this.props.onClick(e);
    }
  };

  render = () => {
    const size = this.props.size || 'large';
    const {
      disabled,
      labelStyle,
      backgroundColor,
      type = 'button',
      Icon,
      iconStyles,
      'data-qa': dataQa,
    } = this.props;
    const disabledBackgroundColor = theme.colors.action3on70;
    const backColor = disabled
      ? disabledBackgroundColor
      : backgroundColor
      ? backgroundColor
      : this.state.isHover
      ? theme.colors.action2
      : theme.colors.action3;

    const muiProps = {
      fullWidth: this.props.fullWidth,
      label: this.props.label,
      onClick: (e) => this.buttonClick(e),
      backgroundColor: backColor,
      hoverColor: theme.colors.action2,
      disableTouchRipple: true,
      style: {
        borderRadius: 30,
        ...getSizeStyle(size),
        ...(this.state.isPressed && !disabled ? pressedStyle : {}),
        ...this.props.style,
      },
      labelStyle: {
        color: theme.colors.white,
        ...getLabelStyle(size),
        ...labelStyle,
      },
      className: this.props.className,
      id: this.props.id,
      type,
      icon: Icon ? (
        <Icon
          style={{ ...iconStyles }}
          color={iconStyles ? iconStyles.color : null}
        />
      ) : null,
    };

    return (
      <MFlatButton
        {...muiProps}
        disabled={disabled}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        data-qa={dataQa}
      ></MFlatButton>
    );
  };
}

export class SecondaryButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { isHover: false };
  }

  static propTypes = {
    'icon': PropTypes.object,
    'fullWidth': PropTypes.bool,
    'label': PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    'labelStyle': PropTypes.object,
    'onClick': PropTypes.func,
    'disabled': PropTypes.bool,
    'styles': PropTypes.object,
    'style': PropTypes.object,
    'size': PropTypes.string,
    'className': PropTypes.string,
    'type': PropTypes.string,
    'id': PropTypes.string,
    'data-qa': PropTypes.string,
  };

  onMouseEnter = () => {
    this.setState({ isHover: true });
  };

  onMouseLeave = () => {
    this.setState({ isHover: false, isPressed: false });
  };

  onMouseUp = () => {
    this.setState({ isPressed: false });
  };

  onMouseDown = () => {
    this.setState({ isPressed: true });
  };

  buttonClick = (e) => {
    if (this.props.onClick) {
      e.preventDefault();
      e.stopPropagation();
      this.props.onClick(e);
    }
  };

  render = () => {
    const size = this.props.size || 'large';
    const {
      disabled,
      fullWidth,
      label,
      className,
      labelStyle,
      type = 'button',
      'data-qa': dataQa,
    } = this.props;
    const disabledBackgroundColor = theme.colors.action3on70;
    const mainColor = disabled
      ? disabledBackgroundColor
      : this.state.isHover
      ? theme.colors.action2
      : theme.colors.action3;

    const muiProps = {
      fullWidth,
      label,
      onClick: (e) => this.buttonClick(e),
      backgroundColor: theme.colors.transparent,
      hoverColor: theme.colors.transparent,
      disableTouchRipple: true,
      style: {
        borderRadius: 30,
        borderColor: mainColor,
        borderStyle: 'solid',
        borderWidth: 2,
        ...getSizeStyle(size),
        ...(this.state.isPressed && !disabled ? pressedStyle : {}),
        ...this.props.style,
      },
      labelStyle: {
        color: mainColor,
        letterSpacing: size == 'large' ? 0.5 : 0.25,
        ...getLabelStyle(size),
        ...labelStyle,
      },
      className,
      id: this.props.id,
      type,
    };

    return (
      <MFlatButton
        {...muiProps}
        disabled={this.props.disabled}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        data-qa={dataQa}
      />
    );
  };
}
