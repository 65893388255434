import React from 'react';
import { Field, useField } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import kebabCase from 'lodash.kebabcase';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import { statesList } from '../../constants';
import {
  getAddressSuggestions,
  getUSFullAddress,
} from '../../core/services/smartyStreets';
import composeValidators from '../../validators/composeValidators';
import fieldRequiredStandard from '../../validators/fieldRequired';
import fieldRequiredShortStandard from '../../validators/fieldRequiredShort';
import isZip from '../../validators/isZip';
import validAddress from '../../validators/validAddress';
import validShareAbleAddress from '../../validators/validShareAbleAddress';
import validShareAbleCity from '../../validators/validShareAbleCity';
import validShareAbleState from '../../validators/validShareAbleState';
import validShareAbleUnit from '../../validators/validShareAbleUnit';
import ComboBox from '../ComboBox';
import Input from '../Input';
import SelectField from '../SelectField';

import styles from './AddressAutoSuggestFields.module.scss';

const getOptions = async (query, pathname) => {
  if (query.length < 5) {
    return [];
  }
  const { suggestions } = await getAddressSuggestions(query, pathname);
  return suggestions;
};

const defaultFieldNames = {
  city: 'city',
  unit: 'unit',
  address: 'address',
  zip: 'zip',
  state: 'state',
};

const noop = () => {};

const AddressAutoSuggestFields = ({
  fieldNames,
  validators,
  className,
  streetAddressInputProps,
  unitInputProps,
  fieldsClassName,
  form,
  showPoBoxesNote,
  validatePOBoxes,
  location,
  narrow,
  areFieldsRequired,
}) => {
  const states = statesList.map(({ title, value }) => ({
    value,
    label: title,
  }));
  const fields = {
    ...defaultFieldNames,
    ...fieldNames,
  };
  const cityField = useField(fields.city);
  const zipField = useField(fields.zip);
  const stateField = useField(fields.state);

  const fieldRequired = areFieldsRequired ? fieldRequiredStandard : noop;
  const fieldRequiredShort = areFieldsRequired
    ? fieldRequiredShortStandard
    : noop;

  return (
    <div
      className={cx(styles.container, className, {
        [styles.narrow]: narrow,
      })}
    >
      <div className={cx(styles.addressContainer, styles.address)}>
        <Field
          label={streetAddressInputProps?.label || 'Street Address'}
          name={fields.address}
          data-qa={kebabCase(fields.address)}
          component={ComboBox}
          validate={composeValidators(
            fieldRequired,
            validatePOBoxes ? validShareAbleAddress : validAddress,
            ...(validators.address || []),
          )}
          getOptions={(query) => getOptions(query, location?.pathname)}
          className={cx(styles.field, styles.address, fieldsClassName?.address)}
          itemToString={(item) => (item ? item.text : '')}
          onSelected={(item) => {
            const { city, state, street_line } = item;
            getUSFullAddress({
              city,
              state,
              street: street_line,
            }).then((response) => {
              const zip = get(response, '[0].components.zipcode', '');
              if (zip) {
                zipField.input.onChange(zip);
              }
            });
            if (city) {
              cityField.input.onChange(city);
            }
            if (state) {
              stateField.input.onChange(state);
            }
            return street_line;
          }}
          debounce={600}
        />
        <OnChange name="address">
          {form?.mutators?.setLeaseNickName || (() => {})}
        </OnChange>
      </div>
      <Field
        label={unitInputProps?.label || 'Unit'}
        hint={unitInputProps?.hint || '(Optional)'}
        id={fields.unit}
        name={fields.unit}
        inputProps={{
          'data-qa': kebabCase(fields.unit),
        }}
        component={Input}
        className={cx(styles.field, styles.unit, fieldsClassName?.unit)}
        validate={composeValidators(
          validShareAbleUnit,
          ...(validators.unit || []),
        )}
      />
      {showPoBoxesNote && (
        <p className={styles.poBoxNote}>
          P.O. boxes are not accepted as a valid address. This is not the rental
          address.
        </p>
      )}
      <Field
        label="City"
        id={fields.city}
        name={fields.city}
        inputProps={{
          'data-qa': kebabCase(fields.city),
        }}
        component={Input}
        validate={composeValidators(
          fieldRequired,
          validShareAbleCity,
          ...(validators.city || []),
        )}
        className={cx(styles.field, styles.city, fieldsClassName?.city)}
      />
      <Field
        className={cx(styles.field, styles.state, fieldsClassName?.state)}
        label="State"
        options={states}
        id={fields.state}
        validate={composeValidators(
          fieldRequiredShort,
          validShareAbleState,
          ...(validators.state || []),
        )}
        name={fields.state}
        data-qa={kebabCase(fields.state)}
        component={SelectField}
        selectClassName={fieldsClassName?.stateSelect}
      />
      <Field
        className={cx(styles.field, styles.zip, fieldsClassName?.zip)}
        label="Zip Code"
        type="number"
        pattern="[0-9]*"
        id={fields.zip}
        validate={composeValidators(
          fieldRequiredShort,
          isZip,
          ...(validators.zip || []),
        )}
        name={fields.zip}
        inputProps={{
          'data-qa': kebabCase(fields.zip),
        }}
        component={Input}
        parse={(value) => (value ? value.substring(0, 5) : '')}
      />
    </div>
  );
};

AddressAutoSuggestFields.propTypes = {
  fieldNames: PropTypes.object,
  validators: PropTypes.shape({
    city: PropTypes.array,
    unit: PropTypes.array,
    address: PropTypes.array,
    zip: PropTypes.array,
    state: PropTypes.array,
  }),
  showPoBoxesNote: PropTypes.bool,
  validatePOBoxes: PropTypes.bool,
  unitInputProps: PropTypes.object,
  narrow: PropTypes.bool,
  areFieldsRequired: PropTypes.bool,
  className: PropTypes.string,
  streetAddressInputProps: PropTypes.object,
  fieldsClassName: PropTypes.object,
  form: PropTypes.object,
  location: PropTypes.object,
};

AddressAutoSuggestFields.defaultProps = {
  fieldNames: defaultFieldNames,
  validators: {
    city: [],
    unit: [],
    address: [],
    zip: [],
    state: [],
  },
  showPoBoxesNote: false,
  validatePOBoxes: true,
  narrow: false,
  areFieldsRequired: true,
};

export default withRouter(AddressAutoSuggestFields);
