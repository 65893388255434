import React from 'react';
import { object, string } from 'prop-types';

import { css, withStyles } from '../styles/with-styles';

class TermsAndPolicies extends React.Component {
  static propTypes = {
    styles: object,
    termsOfUseLink: string,
    description: string,
    privacyPolicyLink: string,
    authorizationPolicyLink: string,
  };

  static defaultProps = {
    description: 'By clicking the button above you are agreeing to our',
    termsOfUseLink: 'https://www.turbotenant.com/terms-of-use/',
    privacyPolicyLink: 'https://www.turbotenant.com/privacy-policy/',
    authorizationPolicyLink:
      'https://www.turbotenant.com/application-authorization-policy/',
  };

  render() {
    const {
      styles,
      termsOfUseLink,
      privacyPolicyLink,
      description,
      authorizationPolicyLink,
    } = this.props;
    return (
      <div {...css(styles.terms)}>
        <span>
          {description}&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            {...css(styles.footerLink)}
            href={authorizationPolicyLink}
          >
            Application Authorization <br /> Policy
          </a>
          ,&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            {...css(styles.footerLink)}
            href={termsOfUseLink}
          >
            Terms of Use
          </a>
          &nbsp;&&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            {...css(styles.footerLink)}
            href={privacyPolicyLink}
          >
            Privacy Policy.
          </a>
        </span>
      </div>
    );
  }
}

export default withStyles(({ colors, typography }) => ({
  terms: {
    fontSize: 12,
    lineHeight: '20px',
    textAlign: 'center',
    color: colors.darkBlueGrey,
  },
  footerLink: {
    ...typography.linkText,
    'color': colors.darkBlueGrey,
    'fontSize': 12,
    'lineHeight': '20px',
    ':hover': {
      color: colors.black,
    },
  },
}))(TermsAndPolicies);
