import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

let EmailEmail = (props) => (
  <SvgIcon {...props}>
    <g
      stroke={props.color}
      strokeWidth={props.strokeWidth || 1.25}
      fillOpacity="0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    >
      <path d="M22.5 17c0 .828-.672 1.5-1.5 1.5h-18c-.828 0-1.5-.672-1.5-1.5v-11c0-.829.672-1.5 1.5-1.5h18c.828 0 1.5.671 1.5 1.5v11zM22 5l-10 8-10-8" />
    </g>
  </SvgIcon>
);
EmailEmail = memo(EmailEmail);
EmailEmail.displayName = 'EmailEmail';
EmailEmail.muiName = 'SvgIcon';

export default EmailEmail;
