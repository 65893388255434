import React from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import BlankPageLayout from '../../../layout/BlankPageLayout';
import { useConfig } from '../../../providers/ConfigProvider';
import { RouteWithLayout } from '../../../providers/LayoutProvider';
import { segmentTracking } from '../../../services/utilities';

import { AcceptInviteExistingUser, AcceptInviteNewUser } from './AcceptInvite';

const UserRoutes = ({ match }) => {
  const { path } = match;
  const { OWNERS_URL } = useConfig();

  return (
    <Switch>
      <RouteWithLayout
        path={`${path}/accept_invite/:new?`}
        render={(props) =>
          props.match?.params?.new ? (
            <AcceptInviteNewUser
              handleSubmit={() => {
                console.log('onSubmit');
                segmentTracking('set_password clicked', {
                  location: 'Multi User Access Accept Invite',
                });
              }}
              {...props}
            />
          ) : (
            <AcceptInviteExistingUser
              handleSubmit={() => {
                console.log('onSubmit');
                segmentTracking('accept_invite clicked', {
                  location: 'Multi User Access Accept Invite',
                });
              }}
              ownersUrl={OWNERS_URL}
              {...props}
            />
          )
        }
        layout={BlankPageLayout}
      />
    </Switch>
  );
};

UserRoutes.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default UserRoutes;
