import React from 'react';
import PropTypes from 'prop-types';

import { css, withStyles } from '../styles/with-styles';
class BaseCard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    styles: PropTypes.object,
    baseCardStyles: PropTypes.object,
    baseCardClassName: PropTypes.object,
    containerProps: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    hideBorder: PropTypes.bool,
  };

  render = () => {
    const { style, styles, hideBorder, baseCardStyles, containerProps } =
      this.props;
    return (
      <div className={this.props.className} {...css(style)} {...containerProps}>
        <div
          className={this.props.baseCardClassName}
          {...css(
            hideBorder ? styles.baseCardNoBorder : styles.baseCard,
            baseCardStyles,
          )}
        >
          {this.props.children}
        </div>
      </div>
    );
  };
}

export default withStyles(({ media }) => ({
  ...media.hideSmall,
  baseCard: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E6E9F0',
    boxSizing: 'border-box',
    borderRadius: '0 4px 4px 4px',
    ...media.mediumScreen({
      padding: 32,
    }),
    ...media.smallScreen({
      padding: 16,
    }),
    ...media.print({
      padding: 32,
    }),
  },
  baseCardNoBorder: {
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    ...media.mediumScreen({
      padding: 32,
    }),
    ...media.smallScreen({
      padding: 16,
    }),
  },
}))(BaseCard);
