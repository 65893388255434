import * as colors from './colors';
import * as media from './media-queries';

// @TODO: some global things about typography... we need to actually define it
export const mainTitleH1 = {
  color: colors.headline1,
};

export const secondaryTitleH2 = {
  color: colors.action3,
};

export const sectionTitle = {
  fontSize: '1.2em',
  color: colors.gray6,
};

export const sectionTitleLight = {
  color: colors.action1,
  fontSize: 24,
};

export const secondaryText = {
  color: colors.gray6,
  fontSize: '.8em',
};

export const regularText = {
  fontWeight: 'normal', // 400
};

export const linkText = {
  'color': colors.link,
  'textDecoration': 'underline',
  'fontSize': 14,
  ...regularText,
  'cursor': 'pointer',
  ':hover': {
    color: colors.action3,
  },
};

export const boldText = {
  fontWeight: 'bold', // 700
};

export const semiboldText = {
  fontWeight: 600,
};

export const errorText = {
  color: colors.error,
  fontSize: 14,
};

export const checkboxRadioLabel = {
  color: colors.darkBlueGrey,
  top: 1,
  fontSize: 16,
  ...regularText,
};

export const smallCardTitle = {
  // lineHeight: '30px',
  fontSize: media.isSmallScreen() ? '16px' : '20px',
  ...semiboldText,
  color: colors.black,
};

export const publicSectionTitle = {
  lineHeight: '30px',
  fontSize: 20,
  ...semiboldText,
  color: colors.black,
};

export const mediumTitle = {
  lineHeight: '30px',
  fontSize: 20,
  fontWeight: 'bold',
  color: colors.defaultText,
  marginTop: 0,
  marginBottom: 8,
};

export const defaultTitle = smallCardTitle;

export const smallCardDescription = {
  lineHeight: '21px',
};

export const largeCardTitle = {
  lineHeight: media.isSmallScreen() ? '30px' : '34px',
  fontSize: media.isSmallScreen() ? 20 : 24,
  fontFamily: 'Poppins',
  fontWeight: 600,
  letterSpacing: '0px',
};

export const cardSidebarTitle = {
  lineHeight: '24px',
  fontSize: 16,
  fontWeight: 600,
  color: colors.black,
};

export const mobileTitle = cardSidebarTitle;

export const cardSubItem = {
  ...semiboldText,
  fontSize: 12,
  lineHeight: '21px',
};

export const routeTitle = {
  ...boldText,
  color: colors.black,
  fontSize: 32,
  lineHeight: '48px',
};

export const subTitle = {
  ...boldText,
  color: colors.black,
  fontSize: 24,
  lineHeight: '36px',
};

export const blockTitle = {
  ...boldText,
  color: colors.black,
  fontSize: 16,
  lineHeight: '24px',
};

export const noSelect = {
  // -webkit-touch-callout: none; /* iOS Safari */
  // -webkit-user-select: none; /* Safari */
  // -khtml-user-select: none; /* Konqueror HTML */
  // -moz-user-select: none; /* Firefox */
  // -ms-user-select: none; /* Internet Explorer/Edge */
  userSelect:
    'none' /* Non-prefixed version, currentlysupported by Chrome and Opera */,
};

export const defaultText = {
  color: colors.darkBlueGrey,
  fontSize: 14,
  lineHeight: '21px',
};

export const secondaryItalicText = {
  color: colors.darkBlueGrey,
  fontSize: 14,
  lineHeight: '21px',
  fontStyle: 'italic',
};

export const smallText = {
  color: colors.darkBlueGrey,
  fontSize: 12,
  lineHeight: '18px',
};

export const smallGreyTitle = {
  color: colors.grey,
  fontSize: 12,
  lineHeight: '18px',
  ...boldText,
};

export const boldLargeTitle = {
  color: colors.darkBlueGrey,
  fontSize: 18,
  lineHeight: '27px',
  ...boldText,
};

export const centeredText = {
  textAlign: 'center',
};
