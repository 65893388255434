import React from 'react';
import PropTypes from 'prop-types';

import FlatButton from '../../../FlatButton';
import { useDropdownMenuContext } from '../../context';

const Trigger = ({ asChild = false, children }) => {
  const { refs, getReferenceProps, isOpen } = useDropdownMenuContext();

  if (asChild) {
    return React.cloneElement(children, {
      ref: refs.setReference,
      ...getReferenceProps(),
    });
  }

  if (typeof children === 'function') {
    return children({
      isOpen,
      ref: refs.setReference,
      ...getReferenceProps(),
    });
  }

  return (
    <FlatButton ref={refs.setReference} {...getReferenceProps()}>
      {children}
    </FlatButton>
  );
};

Trigger.propTypes = {
  asChild: PropTypes.bool,
  children: PropTypes.node,
};

export default Trigger;
