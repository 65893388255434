import React from 'react';
import PropTypes from 'prop-types';

import { Row } from '../Layout';
import { css, ThemedStyleSheet, withStyles } from '../styles/with-styles';

const theme = ThemedStyleSheet.get();

const disabledBackgroundColor = theme.colors.lightBlueGrey;

class DashedButton extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      hovered: false,
    };
  }

  static propTypes = {
    className: PropTypes.string,
    customSize: PropTypes.object,
    icon: PropTypes.object,
    iconStyle: PropTypes.object,
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    styles: PropTypes.object,
    style: PropTypes.object,
  };

  render = () => {
    const {
      className,
      disabled,
      icon: IconType,
      iconStyle,
      style,
      styles,
    } = this.props;
    const mainColor = this.props.disabled
      ? disabledBackgroundColor
      : theme.colors.grey;

    return (
      <Row
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
        horizontal="center"
        vertical="center"
        className={className}
        disabled={disabled}
        {...css(
          styles.buttonStyle,
          disabled
            ? styles.buttonStyleBorderSolid
            : styles.buttonStyleBorderDashed,
        )}
        style={style}
        onClick={!disabled && this.props.onClick}
      >
        <Row
          horizontal="center"
          vertical="center"
          {...css(styles.labelWrapper)}
        >
          {IconType && (
            <div>
              <IconType
                color={this.state.hovered ? theme.colors.action2 : mainColor}
                style={{
                  // color: mainColor,
                  marginTop: 3,
                  ...iconStyle,
                }}
              />
            </div>
          )}
          <span
            {...css(styles.label, {
              color: this.state.hovered ? theme.colors.action2 : mainColor,
            })}
          >
            {this.props.label}
          </span>
        </Row>
      </Row>
    );
  };
}

export default withStyles(({ colors, typography, shadows }) => ({
  buttonStyle: {
    'borderRadius': 4,
    'textAlign': 'center',
    'cursor': 'pointer',
    ...typography.noSelect,
    ':hover': {
      backgroundColor: colors.lightGrey,
      borderColor: colors.action2,
    },
    ':active': {
      ...shadows.smallInner(),
    },
  },
  buttonStyleBorderDashed: {
    border: '2px dashed #9DA0A3', //pallette?
  },
  buttonStyleBorderSolid: {
    border: '1px solid ' + colors.lightGrey,
  },
  label: {
    ...typography.semiboldText,
    textTransform: 'uppercase',
    marginLeft: 5,
  },
  labelWrapper: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))(DashedButton);
