import React from 'react';
import { array, bool, func, object, oneOfType, string } from 'prop-types';

import LoadingScreen from '../../components/LoadingScreen';
import { TextButton } from '../Button';
import BackArrow from '../icons/arrows/arrow-left-7';
import { css, withStyles } from '../styles/with-styles';

import Layout from './index';
class PageSimpleCenter extends React.Component {
  static propTypes = {
    styles: object,
    children: oneOfType([object, array]),
    loading: bool,
    title: string,
    backButtonLabel: string,
    backButtonAction: func,
    containerStyles: object,
  };

  render() {
    const {
      styles,
      containerStyles,
      title,
      backButtonLabel,
      backButtonAction,
      loading,
    } = this.props;
    return (
      <div {...css(styles.mainContainer, containerStyles)}>
        <div {...css(styles.cardContainer)}>
          {backButtonLabel && (
            <div>
              <TextButton
                icon={BackArrow}
                onClick={backButtonAction}
                label={backButtonLabel}
                {...css(styles.backButton)}
              />
            </div>
          )}
          <LoadingScreen loading={loading} style={{ minHeight: '50vh' }}>
            {title && <Layout.Components.SectionTitle title={title} />}
            {this.props.children}
          </LoadingScreen>
        </div>
      </div>
    );
  }
}

export default withStyles(({ layout, media }) => ({
  mainContainer: {
    position: 'relative',
    ...layout.column,
    minHeight: '100%',
    overflow: 'auto',
  },
  cardContainer: {
    width: '100%',
    maxWidth: 646,
    margin: 'auto',
    ...media.smallScreen({
      margin: 'unset',
    }),
  },
}))(PageSimpleCenter);
