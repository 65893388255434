import React from 'react';
import { any, func, object } from 'prop-types';

import ErrorIcon from '../icons/interface-feedback/report-problem-triangle';
import InfoIcon from '../icons/status/check-2';
import * as colors from '../styles/colors';
import { css, withStyles } from '../styles/with-styles';

const iconStyle = { marginRight: 10 };
const IconInfo = <InfoIcon color={colors.gray5} style={iconStyle} />;
const IconError = <ErrorIcon color={colors.gray5} style={iconStyle} />;

class MessageContext extends React.Component {
  static propTypes = {
    styles: object,
    children: any,
  };

  static childContextTypes = {
    showInfoMessage: func.isRequired,
    showErrorMessage: func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    // Attributes
    this.state = {
      message: '',
      error: true,
    };
  }

  // Context Functions

  showInfoMessage = (message) => {
    this.setState({
      message,
      error: false,
    });
  };
  showErrorMessage = (message) => {
    this.setState({ message, error: true });
  };

  // Lifecycle
  getChildContext() {
    // All context types must be defined on the object;
    return Object.keys(this.constructor.childContextTypes).reduce(
      (memo, key) => {
        memo[key] = this[key];
        return memo;
      },
      {},
    );
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.setState({ message: '' });
    }, 5000);
  }

  UNSAFE_componentWillReceiveProps({ sources }) {
    if (sources !== this.props.sources) this.loadSources(sources);
  }

  // Render
  render = () => {
    const { styles } = this.props;
    return (
      <div style={{ width: '100%', height: '100%' }}>
        {this.props.children}
        {this.state.message !== '' && (
          <div {...css(styles.infoStyle)}>
            <div {...css(styles.iconContainer)}>
              {this.state.error ? IconError : IconInfo}
            </div>
            <div {...css(styles.textContainer)}>{this.state.message}</div>
          </div>
        )}
      </div>
    );
  };
}

export default withStyles(({ colors, typography, media }) => ({
  infoStyle: {
    backgroundColor: colors.action3,
    color: colors.white,
    borderRadius: 10,
    height: 'auto',
    width: 568,
    position: 'fixed',
    zIndex: 2000, //dialogs modal backgroud have zIndex: 1500
    top: 56,
    margin: 'auto',
    left: 0,
    right: 0,
    padding: 7,
    display: 'flex',
    ...media.smallScreen({
      width: 330,
    }),
    ...media.xSmallScreen({
      width: '90%',
    }),
  },
  textContainer: {
    flex: 1,
    margin: 'auto',
    ...typography.semiboldText,
  },
  iconContainer: {
    margin: 'auto',
    display: 'block',
    flex: 1,
    maxWidth: 25,
    marginRight: 10,
    paddingTop: 5,
  },
}))(MessageContext);
