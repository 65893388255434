import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

let ContentEditionBin1 = (props) => (
  <SvgIcon {...props}>
    <g
      stroke={props.color}
      strokeWidth={props.strokeWidth || 1.25}
      fillOpacity="0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    >
      <path d="M3.5 3.5h16v20h-16zM7.5.5h8v3h-8zM1 3.5h22M7.5 7v12M11.5 7v12M15.5 7v12" />
    </g>
  </SvgIcon>
);
ContentEditionBin1 = memo(ContentEditionBin1);
ContentEditionBin1.displayName = 'ContentEditionBin1';
ContentEditionBin1.muiName = 'SvgIcon';

export default ContentEditionBin1;
