import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

let FooterHome = (props) => (
  <SvgIcon width="34" height="24" viewBox="0 0 34 24" {...props}>
    <g
      transform="translate(-767 -1382)translate(640 1270)translate(0 79)translate(44 0)translate(80 25)translate(3.333333 8.333333)"
      fill="#1A1919"
    >
      <path d="M16.6 0.1L0.2 8.1 0.2 11.9 2 11.9 2 23.2 31 23.2 31 11.9 33.2 11.9 33.2 8.1 16.6 0.1ZM27.5 19.8L5.6 19.8 5.6 9.5 16.6 3.9 27.5 9.5 27.5 19.8 27.5 19.8Z" />
      <polygon points="11.4 13.1 21.6 13.1 21.6 9.5 11.4 9.5" />
      <polygon points="11.4 18.3 21.6 18.3 21.6 14.7 11.4 14.7" />
    </g>
  </SvgIcon>
);
FooterHome = memo(FooterHome);
FooterHome.displayName = 'FooterHome';
FooterHome.muiName = 'SvgIcon';

export default FooterHome;
