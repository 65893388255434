import React from 'react';
import PropTypes from 'prop-types';

import { TextButton } from '../Button';
import { BaseCard } from '../Cards';
import BackArrow from '../icons/arrows/arrow-left-7';
import ForwardArrow from '../icons/arrows/arrow-right-12';
import { Column, Row } from '../Layout';
import { css, ThemedStyleSheet, withStyles } from '../styles/with-styles';

import { Tab, TabContent } from './tab.component';
import { Tabs } from './tabs.component';

const theme = ThemedStyleSheet.get();

class TabsResponsiveClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: props.currentTab,
    };
  }

  static propTypes = {
    currentTab: PropTypes.number,
    onlyDesktop: PropTypes.bool,
    onTabChange: PropTypes.func,
    styles: PropTypes.object,
    tabs: PropTypes.array,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.currentTab !== this.props.currentTab) {
      this.setState({ currentTab: nextProps.currentTab });
    }
  }

  handleTabChange = (tab) => {
    const { onTabChange, tabs } = this.props;
    this.setState({ currentTab: tab });
    onTabChange && onTabChange(tabs[tab].id || tab, false);
  };

  onTabClicked = (id) => {
    const { onTabChange } = this.props;
    const currentTab = this.props.tabs.findIndex((t) => t.id === id);
    this.setState({ currentTab });
    onTabChange && onTabChange(currentTab, true);
  };

  handleBack = () => {
    const { onTabChange } = this.props;
    this.setState({ currentTab: undefined });
    onTabChange && onTabChange(-1, true);
  };

  renderDesktop() {
    const { desktopStyles, onlyDesktop, styles, tabs = [] } = this.props;
    return (
      <Tabs
        {...css(
          !onlyDesktop && styles.hideXSmall,
          desktopStyles || styles.tabContainer,
        )}
        currentTab={this.state.currentTab}
        onTabChange={this.handleTabChange}
      >
        {tabs.map((tab, index) => (
          <Tab
            label={tab.title}
            id={tab.id}
            key={tab.id}
            className={css(styles.tabRoot).className}
          >
            <TabContent containerStyles={tab.containerStyles}>
              {this.state.currentTab === index ? tab.content : <div></div>}
            </TabContent>
          </Tab>
        ))}
      </Tabs>
    );
  }

  getCurrentTabContent = () => {
    const { tabs } = this.props;
    const { currentTab } = this.state;
    if (
      currentTab === undefined ||
      currentTab < 0 ||
      currentTab >= tabs.length
    ) {
      return <div>NO CONTENT</div>;
    }
    return tabs[currentTab].content;
  };

  renderMobile() {
    const { styles, tabs = [] } = this.props;
    const renderContent = this.state.currentTab !== undefined ? true : false;
    if (renderContent) {
      return (
        <Column {...css(styles.mobileContentContainer)}>
          <TextButton
            onClick={this.handleBack}
            label="BACK"
            style={{ width: 78 }}
            icon={BackArrow}
          />
          <BaseCard {...css(styles.contentRow)}>
            {this.getCurrentTabContent()}
          </BaseCard>
        </Column>
      );
    }
    return (
      <div style={{ width: '100%' }}>
        <Column {...css(styles.mobileTabsContainer)}>
          {tabs.map((tab) => (
            <Row
              horizontal="spaced"
              vertical="center"
              {...css(styles.tabTitle)}
              onClick={() => this.onTabClicked(tab.id)}
              key={tab.id}
              id={tab.id}
            >
              <span>{tab.title}</span>
              <ForwardArrow
                color={theme.colors.primary}
                style={{ width: 16, height: 16 }}
              />
            </Row>
          ))}
        </Column>
      </div>
    );
  }

  render() {
    return !this.props.onlyDesktop && theme.media.isSmallScreen()
      ? this.renderMobile()
      : this.renderDesktop();
  }
}

export const TabsResponsive = withStyles(({ media, colors }) => ({
  ...media.hideXSmall,
  contentRow: {
    marginTop: 12,
  },
  mobileContentContainer: {
    padding: 6,
    paddingTop: 0,
    width: '100%',
  },
  mobileTabsContainer: {
    width: '100%',
    marginTop: 20,
    backgroundColor: '#FFFFFF;',
    borderTop: `1px solid ${colors.lightGrey};`,
    borderRight: `1px solid ${colors.lightGrey};`,
    borderLeft: `1px solid ${colors.lightGrey};`,
  },
  tabContainer: {
    marginTop: 24,
    width: '100%',
  },
  tabTitle: {
    color: `${colors.primary};`,
    padding: '17px;',
    cursor: 'pointer;',
    textTransform: 'uppercase;',
    borderBottom: `1px solid ${colors.lightGrey};`,
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '21px',
  },
  tabRoot: {
    ...media.hideOnPrint,
    'height': 49,
    'marginBottom': -1,
    ':nth-child(n) > div > div:nth-child(1)': {
      height: 'auto',
    },
  },
}))(TabsResponsiveClass);
