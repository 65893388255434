import { useMutation, useQuery } from '@apollo/client';
import get from 'lodash.get';

import basicUserProfile from '../../graphql/basicUserProfile.graphql';
import addBankAccountMut from '../../graphql/mutations/payments/addBankAccount.graphql';
import createPaymentRuleGQL from '../../graphql/mutations/payments/createPaymentRequestRule.graphql';
import deleteOfflinePaymentGQL from '../../graphql/mutations/payments/deleteOfflinePayment.graphql';
import deletePaymentRequestMutation from '../../graphql/mutations/payments/deletePaymentRequest.graphql';
import deletePaymentRequestRuleMutation from '../../graphql/mutations/payments/deletePaymentRequestRule.graphql';
import editBankAccountNameMut from '../../graphql/mutations/payments/editBankAccount.graphql';
import editPaymentRequestMutation from '../../graphql/mutations/payments/editPaymentRequest.graphql';
import editPaymentRequestRuleMutation from '../../graphql/mutations/payments/editPaymentRequestRule.graphql';
import getBankAccountBalance from '../../graphql/mutations/payments/getBankAccountBalance.graphql';
import removeBankAccountMut from '../../graphql/mutations/payments/removeBankAccount.graphql';
import removePaymentMethodGQL from '../../graphql/mutations/payments/removePaymentMethod.graphql';
import replaceBankAccountMut from '../../graphql/mutations/payments/replaceBankAccount.graphql';
import sendOfflineRentPaymentGQL from '../../graphql/mutations/payments/sendOfflineRentPayment.graphql';
import sendRentPaymentMut from '../../graphql/mutations/payments/sendRentPayment.graphql';
import setPaymentMethodForRentPayments from '../../graphql/mutations/payments/setPaymentMethodForRentPayments.graphql';
import setupStripeAccount from '../../graphql/mutations/payments/setupStripeAccount.graphql';
import updatePaymentMethodMut from '../../graphql/mutations/payments/updatePaymentMethod.graphql';
import updateRiskStatusGQL from '../../graphql/mutations/payments/updateRiskStatus.graphql';
import getEntities from '../../graphql/queries/entities/entities.graphql';
import leaseRequestGQL from '../../graphql/queries/leaseRequest.graphql';
import paymentRequestRulesQuery from '../../graphql/queries/payment_request_rules.graphql';
import paymentRequestRulesRenterGQL from '../../graphql/queries/payment_request_rules_renter.graphql';
import paymentRequestsQuery from '../../graphql/queries/payment_requests.graphql';
import { removeFromApolloCache } from '../../services/utilities';
import { ApolloNetworkStatus } from '../enums';

export const useAddBankAccount = ({ refetchQueries = [], ...rest } = {}) => {
  return useMutation(addBankAccountMut, {
    refetchQueries: [
      { query: basicUserProfile },
      { query: getEntities },
      ...refetchQueries,
    ],
    awaitRefetchQueries: true,
    ...rest,
  });
};

export const useReplaceBankAccount = ({
  refetchQueries = [],
  ...rest
} = {}) => {
  return useMutation(replaceBankAccountMut, {
    refetchQueries: [
      { query: basicUserProfile },
      { query: getEntities },
      ...refetchQueries,
    ],
    awaitRefetchQueries: true,
    ...rest,
  });
};

export const useRemoveBankAccount = (options = {}) => {
  return useMutation(removeBankAccountMut, {
    refetchQueries: [
      { query: basicUserProfile },
      { query: getEntities },
      ...(options?.refetchQueries || []),
    ],
    awaitRefetchQueries: true,
    ...options,
  });
};

export const useEditBankAccountName = (options = {}) => {
  return useMutation(editBankAccountNameMut, {
    ...options,
  });
};

export const useSetupPayments = (options = {}) => {
  return useMutation(setupStripeAccount, {
    refetchQueries: [{ query: basicUserProfile }, { query: getEntities }],
    awaitRefetchQueries: true,
    ...options,
  });
};

export const useUpdatePaymentMethod = () => {
  return useMutation(updatePaymentMethodMut, {
    refetchQueries: [{ query: basicUserProfile }],
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true,
  });
};

export const useRemovePaymentMethod = () => {
  return useMutation(removePaymentMethodGQL, {
    refetchQueries: [{ query: basicUserProfile }],
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true,
  });
};

export const useGetBankAccountBalance = () =>
  useMutation(getBankAccountBalance);

export const useSendRentPayment = () => {
  return useMutation(sendRentPaymentMut, {
    refetchQueries: [{ query: basicUserProfile }],
    awaitRefetchQueries: true,
    update: (cache) => {
      removeFromApolloCache(['payment_requests'], cache);
    },
  });
};

export const useSendOfflineRentPayment = () =>
  useMutation(sendOfflineRentPaymentGQL);

export const useEditPaymentRequestRules = ({
  leaseId,
  refetchQueries = [],
} = {}) => {
  return useMutation(editPaymentRequestRuleMutation, {
    refetchQueries: [
      { query: leaseRequestGQL, variables: { id: leaseId } },
      ...refetchQueries,
    ],
    awaitRefetchQueries: true,
    update: (cache) => {
      removeFromApolloCache(['paymentRequests', 'paymentRequestRules'], cache);
    },
  });
};

export const useCreatePaymentRule = ({ leaseId, refetchQueries = [] } = {}) => {
  return useMutation(createPaymentRuleGQL, {
    refetchQueries: [
      { query: leaseRequestGQL, variables: { id: leaseId } },
      ...refetchQueries,
    ],
    awaitRefetchQueries: true,
    update: (cache) => {
      removeFromApolloCache(
        ['paymentRequests', 'paymentRequestRules', 'leases'],
        cache,
      );
    },
  });
};

export const useEditPaymentRequest = ({ leaseId }) => {
  return useMutation(editPaymentRequestMutation, {
    refetchQueries: [{ query: leaseRequestGQL, variables: { id: leaseId } }],
    awaitRefetchQueries: true,
    update: (cache) => {
      removeFromApolloCache(['paymentRequests', 'paymentRequestRules'], cache);
    },
  });
};

export const useDeletePaymentRequestRule = ({
  leaseId,
  refetchQueries = [],
}) => {
  return useMutation(deletePaymentRequestRuleMutation, {
    refetchQueries: [
      { query: leaseRequestGQL, variables: { id: leaseId } },
      ...refetchQueries,
    ],
    awaitRefetchQueries: true,
    update: (cache) => {
      removeFromApolloCache(['paymentRequests', 'paymentRequestRules'], cache);
    },
  });
};

export const useDeletePaymentRequest = ({
  leaseId,
  refetchQueries = [],
} = {}) => {
  const queriesToRefetch = refetchQueries || [];

  if (leaseId) {
    queriesToRefetch.push({
      query: leaseRequestGQL,
      variables: { id: leaseId },
    });
  }

  return useMutation(deletePaymentRequestMutation, {
    refetchQueries: queriesToRefetch,
    awaitRefetchQueries: true,
    update: (cache) => {
      removeFromApolloCache(['paymentRequests', 'paymentRequestRules'], cache);
    },
  });
};

export const useDeleteOfflinePayment = (params) => {
  const { leaseId, refetchQueries = [] } = params || {};

  let queriesToRefetch = refetchQueries || [];

  if (leaseId) {
    queriesToRefetch = [
      ...queriesToRefetch,
      {
        query: paymentRequestsQuery,
        variables: { leaseFilter: [leaseId] },
      },
      { query: leaseRequestGQL, variables: { id: leaseId } },
    ];
  }

  return useMutation(deleteOfflinePaymentGQL, {
    refetchQueries: queriesToRefetch,
    awaitRefetchQueries: true,
  });
};

export const usePaymentRequests = (options = {}) => {
  const response = useQuery(paymentRequestsQuery, {
    ...(options || {}),
  });
  const error = get(response, 'error', null);
  const networkStatus = get(response, 'networkStatus', '');
  return {
    ...response,
    loading:
      response.loading &&
      response.networkStatus !== ApolloNetworkStatus.POLLING,
    failedNetworkRequest: networkStatus === 8 || !!error,
    payment_requests: get(
      response,
      'data.userData.payment_requests.data.edges',
      [],
    ).map(({ node }) => node),
    total_count: get(response, 'data.userData.payment_requests.data.total', 0),
    refetch: response.refetch,
  };
};

export const usePaymentRequestRules = (options = {}) => {
  const response = useQuery(paymentRequestRulesQuery, {
    ...options,
    variables: {
      ...options.variables,
    },
  });
  const error = get(response, 'error', null);
  const networkStatus = get(response, 'networkStatus', '');
  return {
    response,
    loading:
      response.loading &&
      response.networkStatus !== ApolloNetworkStatus.POLLING,
    failedNetworkRequest: networkStatus === 8 || !!error,
    rules: get(
      response,
      'data.userData.payment_request_rules.data.edges',
      [],
    ).map(({ node }) => node),
    total_count: get(
      response,
      'data.userData.payment_request_rules.data.total',
      0,
    ),
  };
};

export const usePaymentRequestRulesRenter = ({ leaseIds, options = {} }) => {
  const response = useQuery(paymentRequestRulesRenterGQL, {
    variables: { leaseIds },
    ...(options || {}),
  });

  const error = get(response, 'error', null);
  const networkStatus = get(response, 'networkStatus', '');

  return {
    response,
    loading:
      response.loading &&
      response.networkStatus !== ApolloNetworkStatus.POLLING,
    failedNetworkRequest: networkStatus === 8 || !!error,
    rules: get(
      response,
      'data.userData.payment_request_rules.data.edges',
      [],
    ).map(({ node }) => node),
    total_count: get(
      response,
      'data.userData.payment_request_rules.data.total',
      0,
    ),
  };
};

export const useSetPaymentMethodForRentPayments = () => {
  return useMutation(setPaymentMethodForRentPayments, {
    refetchQueries: [{ query: basicUserProfile }],
    awaitRefetchQueries: true,
  });
};

export const useUpdateRiskStatus = () => {
  return useMutation(updateRiskStatusGQL);
};
