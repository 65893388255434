import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../../components/Button';
import PlusCircle from '../../../../../../icons/PlusCircle';
import ChargeCollapsed from '../../components/ChargeCollapsed';
import OneTimeChargeForm from '../OneTimeChargeForm';

import styles from './OneTimeChargesListForm.module.scss';

const OneTimeChargesEditList = ({
  onAdd,
  onEdit,
  onDelete,
  charges,
  isPremiumUser,
  bankAccountOptions,
  closedForm = false,
}) => {
  const [showForm, setShowForm] = useState(true);
  const [editingId, setEditingId] = useState(null);

  const initialCharge = {};

  const onChargeEdit = async (charge) => {
    await onEdit(charge);
    setEditingId(null);
  };

  const onChargeEditClick = (charge) => {
    setEditingId(charge.id);
    setShowForm(false);
  };

  useEffect(() => {
    setShowForm(charges.length === 0 && !closedForm);
  }, [charges.length]);

  return (
    <>
      {charges.map((charge, index) => (
        <section key={`onetime_charge${index}`}>
          {charge.id !== editingId && (
            <ChargeCollapsed
              charge={charge}
              key={charge.id}
              onEdit={() => onChargeEditClick(charge)}
              onDelete={() => onDelete(charge)}
              className={styles.chargeCollapsed}
            />
          )}

          {charge.id === editingId && (
            <OneTimeChargeForm
              chargeInfo={charge}
              onSubmit={onChargeEdit}
              onCancel={() => setEditingId(null)}
              formClassName={styles.editChargeForm}
              isPremiumUser={isPremiumUser}
              bankAccountOptions={bankAccountOptions}
            />
          )}
        </section>
      ))}

      {showForm && (
        <OneTimeChargeForm
          chargeInfo={initialCharge}
          onSubmit={onAdd}
          onCancel={() => setShowForm(false)}
          isPremiumUser={isPremiumUser}
          bankAccountOptions={bankAccountOptions}
        />
      )}

      {!showForm && !editingId && (
        <Button
          tertiary
          className={(styles.mybtn, styles.label)}
          type="button"
          onClick={() => setShowForm(true)}
          icon={PlusCircle}
          id="add_one_time_charge_button"
        >
          Add One-Time Charge
        </Button>
      )}
    </>
  );
};

OneTimeChargesEditList.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  charges: PropTypes.array,
  isPremiumUser: PropTypes.bool,
  bankAccountOptions: PropTypes.array,
  closedForm: PropTypes.bool,
};

export default OneTimeChargesEditList;
