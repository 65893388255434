import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import LoadingScreen from '../../../components/LoadingScreen';
import leaseAgreementUnsupportedStates from '../../../constants/lease_agreement/lease-agreement-unsupported-states';
import { useUserProfile } from '../../../core/TTgraphql';
import leaseRequestQuery from '../../../graphql/queries/leaseRequest.graphql';
import { useConfig } from '../../../providers/ConfigProvider';

import RentalAddendumWizard from './RentalAddendumWizard';

const RentalAddendumRoute = ({
  match: {
    url,
    params: { leaseId },
  },
  history,
  location,
}) => {
  const { user, loading: userLoading } = useUserProfile();
  const { data: { node: lease } = {}, loading } = useQuery(leaseRequestQuery, {
    variables: { id: leaseId },
  });
  const { PRIVATE_BASE_PATH } = useConfig();

  const { redirectTo } = queryString.parse(location.search);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!lease) {
      history.replace('/');
    }

    const addressState = get(lease, 'listing.state');
    if (leaseAgreementUnsupportedStates.includes(addressState)) {
      history.replace('/');
    }

    /**
     * NOTE: If there's an already started addendum redirect to that one
     * so it can be finished before starting a new one
     */
    const pendingAddendum = (lease.wizard_documents || []).find(
      (wd) => !wd.completed_at,
    );

    if (pendingAddendum) {
      history.replace({
        pathname: `${PRIVATE_BASE_PATH}rental-addendum/${lease.id}/${pendingAddendum.id}`,
        search: redirectTo ? `redirectTo=${redirectTo}` : '',
      });
    }
  }, [loading, lease]);

  if (loading || !lease || userLoading) {
    return <LoadingScreen loading />;
  }

  return (
    <Switch>
      <Redirect
        exact
        from={url}
        to={{
          pathname: `${url}/create`,
          search: redirectTo ? `redirectTo=${redirectTo}` : '',
        }}
      />
      <Route
        path={`${url}/create`}
        exact
        render={(ownProps) => (
          <RentalAddendumWizard {...ownProps} lease={lease} user={user} />
        )}
      />
      <Route
        path={`${url}/:addendumId`}
        render={(ownProps) => (
          <RentalAddendumWizard {...ownProps} lease={lease} user={user} />
        )}
      />
    </Switch>
  );
};

RentalAddendumRoute.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default RentalAddendumRoute;
