import React from 'react';
import { Dialog as MDialog } from 'material-ui';
import PropTypes from 'prop-types';

import * as media from '../styles/media-queries';

export default class WrappedDialog extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    contentIsTall: PropTypes.bool,
    width: PropTypes.any,
    marginTop: PropTypes.any,
    topAligned: PropTypes.bool,
    bodyStyle: PropTypes.object,
  };

  getMobileContentStyles = () => {
    if (!media.isSmallScreen()) return {};
    const styles = {
      width: '90%',
      maxWidth: 340,
      maxHeight: '100%',
    };
    if (this.props.topAligned) {
      styles.transform = '';
    }
    return styles;
  };

  getMobileBodyStyles = () => {
    if (!media.isSmallScreen()) return {};
    const defaultStyle = {
      padding: 24,
    };
    if (this.props.topAligned) return defaultStyle;
    return {
      ...defaultStyle,
      paddingTop: 40,
    };
  };

  getRootDialogMobileStyles = () =>
    media.isSmallScreen() && this.props.topAligned
      ? { paddingTop: 24, maxHeight: '95%' }
      : {};

  render = () => {
    const { bodyStyle, children, width, contentIsTall, ...muiProps } =
      this.props;

    const isSmallOrTall = media.isSmallScreen() || contentIsTall;

    const props = {
      contentStyle: {
        // the dialog outer bounds
        width: '94%',
        position: 'relative',
        maxWidth: width || 385,
        ...this.getMobileContentStyles(),
      },
      style: {
        overflow: 'scroll',
        ...this.getRootDialogMobileStyles(),
      },
      bodyStyle: {
        // the dialog content
        padding: 32,
        borderRadius: 4,
        ...this.getMobileBodyStyles(),
        ...bodyStyle,
      },
      autoDetectWindowHeight: !isSmallOrTall,
      autoScrollBodyContent: !isSmallOrTall,
      repositionOnUpdate: !isSmallOrTall,
      paperProps: { style: { borderRadius: 4 } },
      ...muiProps,
    };

    return <MDialog {...props}>{children}</MDialog>;
  };
}
