export const footerHeight = 120;

export const flex = {
  flex: 1,
  boxSizing: 'border-box',
};

export const column = {
  flexDirection: 'column',
  boxSizing: 'border-box',
  display: 'flex',
};

export const row = {
  flexDirection: 'row',
  boxSizing: 'border-box',
  display: 'flex',
};

export const flexFixed = {
  flexShrink: 0,
};

export const alignStartStart = {
  display: 'flex',
  justifyContent: 'flex-start', // might be space-between or space-around
  alignItems: 'flex-start', // same as justify but in the perpendicular direction
  alignContent: 'flex-start',
};

export const alignCenterCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  maxWidth: '100%',
};

export const spaceBetween = {
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const wrap = {
  flexWrap: 'wrap',
};

export const mainPage = {
  padding: 0,
  flex: 1,
  marginTop: 0,
  backgroundColor: '#FFF',
};

export const blockWithColorBarStyle = {
  borderBottom: '1px solid #E6E9F0',
  borderRight: '1px solid #E6E9F0',
  borderTop: '1px solid #E6E9F0',
  borderRadius: '0 4px 4px 0',
  padding: '24px 40px 24px 40px',
};

export const blockWithColorBarStyleSmallScreen = {
  borderLeft: '1px solid #E6E9F0',
  borderRadius: '0 0 4px 4px',
  padding: '24px 22px 24px 22px',
};

export const blockWithColorBarStyleXSmallScreen = {
  padding: '12px 22px 12px 22px',
};
