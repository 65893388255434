import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

let PhonePhone5 = (props) => (
  <SvgIcon {...props}>
    <path
      stroke={props.color}
      strokeWidth={props.strokeWidth || 1.25}
      fillOpacity="0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M8.586 7.902c.902-.903.902-2.368 0-3.271l-2.454-2.453c-.903-.903-2.368-.903-3.271 0l-1.345 1.345c-1.168 1.168-1.349 2.989-.439 4.366 3.909 5.91 9.124 11.125 15.034 15.034 1.375.909 3.201.728 4.365-.438l1.346-1.346c.903-.903.903-2.368 0-3.271l-2.453-2.453c-.902-.904-2.367-.904-3.271 0l-.817.817c-2.69-2.204-5.309-4.823-7.513-7.514l.818-.816z"
    />
  </SvgIcon>
);
PhonePhone5 = memo(PhonePhone5);
PhonePhone5.displayName = 'PhonePhone5';
PhonePhone5.muiName = 'SvgIcon';

export default PhonePhone5;
