import React from 'react';
import PropTypes from 'prop-types';

import { SecondaryButton } from '../Button';
import { css, withStyles } from '../styles/with-styles';

class HelpCard extends React.Component {
  render() {
    const {
      title,
      description,
      buttonLabel,
      buttonHandler,
      buttonSize,
      styles,
      key,
    } = this.props;
    return (
      <article {...css(styles.container)} key={key}>
        <div {...css(styles.title)}>{title}</div>
        <div {...css(styles.description)}>{description}</div>
        <SecondaryButton
          size={buttonSize}
          label={buttonLabel}
          onClick={buttonHandler}
        />
      </article>
    );
  }
}

HelpCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonLabel: PropTypes.string,
  buttonHandler: PropTypes.func,
  buttonSize: PropTypes.string,
  styles: PropTypes.object,
};

HelpCard.defaultProps = {
  title: 'Need help?',
  description:
    'Our support team is more than happy to help you with any questions you might have.',
  buttonLabel: 'contact support',
  buttonHandler: () =>
    window.open(`https://support.turbotenant.com/`, '_blank'),
  buttonSize: 'small',
};

export default withStyles(({ colors }) => ({
  container: {
    marginBottom: 16,
  },
  title: {
    color: colors.black,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
  },
  description: {
    color: colors.darkBlueGrey,
    fontSize: 14,
    lineHeight: '21px',
    paddingBottom: 12,
  },
}))(HelpCard);
