import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';
import { string } from 'prop-types';

let Caution = (props) => (
  <SvgIcon {...props} viewBox={props.viewBox || '0 0 76 77'}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-602.000000, -200.000000)">
        <g id="Modal" transform="translate(448.000000, 150.000000)">
          <g
            id="Icons/Graphic/Warning/Dark"
            transform="translate(144.000000, 40.000000)"
          >
            <rect id="Bounding" x="0" y="0" width="96" height="96"></rect>
            <g
              id="Error"
              strokeWidth="1"
              fillRule="evenodd"
              transform="translate(10.000000, 10.000000)"
              fill="#042238"
            >
              <path
                d="M38.1599304,25.544 C38.940087,25.6656667 39.5269391,26.2793333 39.6178087,27.072 C39.9422957,35.6106667 39.6224348,44.1616667 39.6224348,52.7063333 C39.555687,54.4646667 36.5394783,54.7263333 36.3230435,52.834 C35.9985565,44.2953333 36.318087,35.7443333 36.318087,27.1996667 C36.3554261,26.222 36.885113,25.4453333 38.1599304,25.544 Z"
                id="Fill-1"
              ></path>
              <path
                d="M38.291113,0.054 C38.7616522,0.180666667 39.0491304,0.254666667 39.4479652,0.943666667 L75.7957913,74.277 C76.2626957,75.39 75.5922435,76.6073333 74.318087,76.689 L1.62243478,76.689 C0.385286957,76.6096667 -0.322173913,75.3906667 0.144730435,74.277 L36.4925565,0.943666667 C37.0305043,0.0153333333 37.1805217,-0.0926666667 38.291113,0.054 Z M4.29565217,73.3556667 L71.6448696,73.3556667 L37.9702609,5.41566667 C26.7453913,28.0623333 15.5205217,50.709 4.29565217,73.3556667 Z"
                id="Fill-2"
              ></path>
              <path
                d="M38.139113,60.026 C39.7886435,60.1526667 41.1886957,61.545 41.2709739,63.1856667 C41.384313,65.441 38.8211304,67.339 36.6835478,66.4266667 C33.9677043,65.2673333 33.9234261,59.9183333 38.139113,60.026 Z"
                id="Fill-3"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
);

Caution = memo(Caution);

Caution.displayName = 'Caution';

Caution.muiName = 'SvgIcon';

Caution.defaultProps = {
  width: '76px',
  height: '77px',
};

Caution.propTypes = {
  viewBox: string,
};

export default Caution;
