import React from 'react';
import PropTypes from 'prop-types';

import { H2, Link, Paragraph } from '../../../../components/Typography';
import colors from '../../../../constants/colors';
import ArrowV3 from '../../../../icons/ArrowV3';
import CheckMarkFilled from '../../../../icons/CheckMarkFilled';
import RadialCircle from '../../../../icons/RadialCircle';

import styles from './AcceptInviteSidebar.module.scss';

const existingUserItems = ({ company, accountName, userName }) => [
  `You’ll be able to sign into ${
    company || accountName
  } using your own email and password.`,
  `Gain Admin access to ${
    company || accountName?.split(' ')[0]
  }’s entire account, including leases, tenants, rent payments, maintenance – and more!`,
  `See and send messages. You’ll appear as ${userName} for consistency when you send messages to tenants.`,
  `Switch to your other accounts from the main navigation at any time.`,
];

const newUserItems = ({ company, accountName, userName }) => [
  `Create an account with your own email and unique password to access ${
    company || accountName
  } Account.`,
  `Gain Admin access to ${
    company || accountName?.split(' ')[0]
  } entire account, including leases, tenants, rent payments, maintenance – and more!`,
  `See and send messages. You’ll appear as ${userName} for consistency when you send messages to tenants.`,
];

const AcceptInviteSidebar = ({ isNewUser, company, accountName, userName }) => {
  const items = isNewUser ? newUserItems : existingUserItems;
  console.log(
    'AcceptInviteSidebar props',
    isNewUser,
    company,
    accountName,
    userName,
  );
  return (
    <div className={styles.container}>
      <RadialCircle className={styles.topCircles} />
      <div className={styles.content}>
        <div className={styles.titleWrapper}>
          <H2 className={styles.title}>How it works</H2>
          <ArrowV3 className={styles.arrow} />
        </div>
        <div className={styles.list}>
          {items({ company, accountName, userName }).map((item, index) => (
            <div key={index} className={styles.item}>
              <CheckMarkFilled
                className={styles.checkMark}
                color={colors.primary}
              />
              <Paragraph className={styles.text}>{item}</Paragraph>
            </div>
          ))}
        </div>
        <Link
          className={styles.learnMore}
          href="https://support.turbotenant.com/en/articles/9690662-multi-user-access"
        >
          Learn more about being an Admin user
        </Link>
      </div>
      <RadialCircle className={styles.bottomCircles} />
    </div>
  );
};

AcceptInviteSidebar.defaultProps = {
  isNewUser: true,
  company: '',
  accountName: '',
  userName: '',
};

AcceptInviteSidebar.propTypes = {
  isNewUser: PropTypes.bool,
  company: PropTypes.string,
  accountName: PropTypes.string,
  userName: PropTypes.string,
};

export default AcceptInviteSidebar;
