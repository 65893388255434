import React from 'react';
import PropTypes from 'prop-types';

import FooterHomeIcon from '../icons/footer-home';
import { gray_dark } from '../styles/colors';
import { css, withStyles } from '../styles/with-styles';

const styles = ({ colors, typography, media }) => ({
  footerMain: {
    width: '100%',
    height: 120, //layout.footerHeight,
    textAlign: 'center',
    fontSize: 14,
    color: colors.darkBlueGrey,
    paddingTop: 20,
    ...media.smallScreen({
      height: 190,
      paddingTop: 48,
    }),
    ...media.hideOnPrint,
  },
  footerLink: {
    ...typography.linkText,
    lineHeight: '21px',
  },
  ...media.hideSmall,
  bulleted: {
    ':before': {
      // content: "\\02022",
      padding: 4,
    },
  },
  line: {
    display: 'inline-block',
    ...media.smallScreen({
      display: 'block',
      minHeight: 30,
    }),
  },
  hardLine: {
    minHeight: 40,
  },
  iconHome: {
    // color: colors.gray_dark, // need to pass it as attribute
    height: '18px',
    width: '26px',
    marginBottom: -3,
    marginLeft: 10,
    marginRight: 10,
    ...media.smallScreen({
      width: '33px',
      height: '23px',
    }),
  },
});

const Footer = (props) => {
  const s = props.styles;
  return (
    <div style={props.style}>
      <footer {...css(s.footerMain)}>
        <div {...css(s.hardLine)}>
          <span {...css(s.line)}>
            <a
              {...css(s.footerLink)}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.turbotenant.com/scams"
            >
              Avoid Scams in Rental Housing
            </a>
          </span>
          <span {...css(s.line)}>
            <FooterHomeIcon {...css(s.iconHome)} color={gray_dark} />
          </span>
          <span {...css(s.line)}>
            <a
              {...css(s.footerLink)}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.turbotenant.com/fair-housing/"
            >
              Equal Housing Opportunity
            </a>
          </span>
        </div>
        <div {...css(s.hardLine)}>
          <span {...css(s.line)}>
            TurboTenant, Inc., © {new Date().getFullYear()}
            <span {...css(s.hideSmall)}>&nbsp;&nbsp;|&nbsp;&nbsp; </span>
          </span>
          <span {...css(s.bulleted)}>
            <a
              {...css(s.footerLink)}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.turbotenant.com/terms-of-use/"
            >
              Terms of Use
            </a>
            {' & '}
            <a
              {...css(s.footerLink)}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.turbotenant.com/privacy-policy/"
            >
              Privacy Policy
            </a>
          </span>
        </div>
      </footer>
    </div>
  );
};

Footer.propTypes = {
  style: PropTypes.object,
};

export default withStyles(styles)(Footer);
