import React from 'react';
import { any, bool, func, object, string } from 'prop-types';

import LoadingScreen from '../../components/LoadingScreen';
import { PrimaryButton } from '../Button';
import Dialog from '../Dialog';
import { css, withStyles } from '../styles/with-styles';
import { ThemedStyleSheet } from '../styles/with-styles';

const { media, colors } = ThemedStyleSheet.get();

const NotificationModal = ({
  open,
  styles,
  title,
  info,
  content,
  buttonTitle,
  onClose,
  iconStyles,
  icon: Icon,
  buttonSize,
  loading,
  dontShowInfo,
}) => (
  <Dialog open={open} style={{ zIndex: 9999 }} id="notification-modal">
    <LoadingScreen loading={loading}>
      <div {...css(styles.container)}>
        <Icon
          color={colors.darkBlueGrey}
          style={{
            width: 76,
            height: 77,
            ...iconStyles,
          }}
        />
        <h3 {...css(styles.title)} id="notification-modal-title">
          {title}
        </h3>
        {!dontShowInfo && (
          <span
            {...css(styles.info)}
            dangerouslySetInnerHTML={{ __html: info }}
          ></span>
        )}
        {content}
        <PrimaryButton
          id="notification-modal-ok-btn"
          label={buttonTitle}
          onClick={onClose}
          size={buttonSize}
        />
      </div>
    </LoadingScreen>
  </Dialog>
);

NotificationModal.propTypes = {
  open: bool,
  styles: object,
  iconStyles: object,
  title: any,
  info: string,
  content: object,
  buttonTitle: string,
  buttonSize: string,
  icon: object,
  onClose: func,
  loading: bool,
  dontShowInfo: bool,
};

NotificationModal.defaultProps = {
  title: 'Notification title',
  info: 'Notification info',
  buttonTitle: 'Button title',
  onClose: Function.prototype,
  iconStyles: {},
  buttonSize: media.isSmallScreen() ? 'xsmall' : 'small',
};

export default withStyles(({ colors }) => ({
  container: {
    textAlign: 'center',
  },
  title: {
    fontSize: 24,
    margin: '13px 0 4px',
    color: colors.black,
    lineHeight: '36px',
  },
  info: {
    display: 'block',
    marginBottom: '24px',
  },
}))(NotificationModal);
