import React from 'react';
import { Tab as MTab } from 'material-ui';
import { any, object } from 'prop-types';

import { css, withStyles } from '../styles/with-styles';

export const Tab = MTab;

const Tabs = ({ children, styles, containerStyles }) => {
  return <div {...css(containerStyles || styles.container)}>{children}</div>;
};

export const TabContent = withStyles(({ media }) => ({
  container: {
    padding: media.isSmallScreen() ? 12 : '32px 40px',
    border: '1px solid #E6E9F0',
    borderRadius: '0 4px 4px 4px',
    backgroundColor: 'white',
    ...media.print({
      padding: 0,
      border: 'none',
    }),
  },
}))(Tabs);

TabContent.propTypes = {
  children: any,
  containerStyles: object,
  styles: object,
};
