import React from 'react';
import { bool, func, object, string } from 'prop-types';
import compose from 'recompose/compose';

import { Column } from '../Layout';
import { css, withStyles } from '../styles/with-styles';

const HeaderItem = ({ styles, tab: { id, label, action, active } }) => {
  return (
    <Column
      {...css(styles.itemStyles, active && styles.itemSelected)}
      onClick={action}
    >
      <span id={id}>{label}</span>
    </Column>
  );
};

HeaderItem.propTypes = {
  id: string,
  onClick: func,
  styles: object,
  active: bool,
};

export default compose(
  withStyles(({ colors, media }) => ({
    itemSelected: {
      color: colors.action3,
      fontWeight: 'bold',
      boxShadow: `0px 4px 0px 0px ${colors.action3}`,
      ...media.xSmallScreen({
        fontWeight: 600,
      }),
    },
    itemStyles: {
      'justifyContent': 'flex-end',
      'paddingBottom': 16,
      'color': '#6D757F',
      'cursor': 'pointer',
      'fontSize': 16,
      'fontWeight': 600,
      'lineHeight': '24px',
      'textAlign': 'center',
      'whiteSpace': 'nowrap',
      'textTransform': 'uppercase',
      ':not(:last-child)': {
        marginRight: 16,
      },
      ...media.xSmallScreen({
        ':not(:last-child)': {
          marginRight: 0,
        },
        'flex': 1,
        'justifyContent': 'center',
        'paddingBottom': 0,
        'fontSize': 14,
        'lineHeight': '21px',
      }),
    },
  })),
)(HeaderItem);
