import React from 'react';
import PropTypes from 'prop-types';

import propertyPlaceholderImg from '../assets/rental-property.svg';
import { Column, Row } from '../Layout';
import ImageItem from '../PhotoUploader/ImageItem';
import { css, withStyles } from '../styles/with-styles';

const BaseProperyCard = ({
  styles,
  imageUrl,
  onAction,
  rotation,
  children,
}) => (
  <Row {...css(styles.card)} onSmall="column" onClick={onAction}>
    <Column
      flex="1"
      alignItems="center"
      justifyContent="center"
      {...css(styles.imageContainer)}
    >
      <ImageItem
        autofill={!!imageUrl}
        rotation={rotation}
        imageUrl={imageUrl || propertyPlaceholderImg}
      />
    </Column>
    <Column vertical="spaced" flex="1" flexGrow={2}>
      <Column {...css(styles.childrenContainer)}>{children}</Column>
    </Column>
  </Row>
);

BaseProperyCard.propTypes = {
  imageUrl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  children: PropTypes.node,
  rotation: PropTypes.number,
  onAction: PropTypes.func,
  styles: PropTypes.object,
};

export default withStyles(({ colors, media }) => ({
  ...media.hideLarge,
  ...media.hideSmall,
  card: {
    border: '1px solid',
    borderRadius: 4,
    marginBottom: 16,
    boxSizing: 'border-box',
    cursor: 'pointer',
    borderColor: colors.lightGrey,
    backgroundColor: colors.white,
    minWidth: 500,
    minHeight: 168,
    overflow: 'hidden',
    ...media.smallScreen({
      minWidth: 240,
    }),
  },
  imageContainer: {
    position: 'relative',
    minWidth: 200,
    maxWidth: 200,
    minHeight: 163,
    width: 200,
    borderStyle: 'solid',
    borderWidth: '0 1px 0 0',
    overflow: 'hidden',
    borderColor: colors.lightGrey,
    backgroundColor: colors.grey5,
    ...media.smallScreen({
      minWidth: 280,
      maxWidth: '100%',
      width: '100%',
      height: 178,
      borderWidth: '0 0 1px 0',
    }),
  },
  childrenContainer: {
    padding: 25,
    height: '100%',
    ...media.smallScreen({
      padding: 20,
    }),
  },
}))(BaseProperyCard);
