import React from 'react';
import PropTypes from 'prop-types';

import { css, ThemedStyleSheet, withStyles } from '../styles/with-styles';

const { typography, colors } = ThemedStyleSheet.get();
class IconWithLabel extends React.Component {
  state = { isHover: false };

  static propTypes = {
    labelTitle: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.number,
    ]),
    labelStyle: PropTypes.object,
    style: PropTypes.object,
    styles: PropTypes.object,
    icon: PropTypes.object,
    iconStyle: PropTypes.object,
    iconColor: PropTypes.string,
    hoverColor: PropTypes.string,
    asRow: PropTypes.bool,
  };

  onMouseEnter = () => {
    this.setState({ isHover: true });
  };

  onMouseLeave = () => {
    this.setState({ isHover: false });
  };

  getColor() {
    const { iconColor, hoverColor } = this.props;
    const { isHover } = this.state;
    if (isHover) {
      return hoverColor || iconColor || colors.black;
    }
    return iconColor || colors.black;
  }

  render() {
    const {
      labelStyle,
      asRow,
      style,
      icon: Icon,
      labelTitle,
      label,
      styles,
      iconStyle,
    } = this.props;
    return (
      <div
        {...css(!asRow ? styles.container : styles.containerRow, style)}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <Icon
          color={this.getColor()}
          style={{ width: 40, height: 40, margin: 'auto', ...iconStyle }}
        />
        {labelTitle && (
          <p
            {...css(
              !asRow ? styles.label : styles.labelRow,
              typography.boldText,
            )}
          >
            {labelTitle}
          </p>
        )}
        <div
          {...css(
            !asRow ? styles.label : styles.labelRow,
            { margin: 'auto' },
            labelStyle,
          )}
        >
          {label}
        </div>
      </div>
    );
  }
}

export default withStyles(({ typography, colors, layout }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  containerRow: {
    display: 'flex',
    ...layout.alignCenterCenter,
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  label: {
    ...typography.regularText,
    color: colors.darkBlueGrey,
    marginTop: 10,
    width: '100%',
    textAlign: 'center',
  },
  labelRow: {
    ...typography.regularText,
    color: colors.darkBlueGrey,
    width: '100%',
    textAlign: 'left',
  },
}))(IconWithLabel);
