import * as colors from './colors';
import * as layout from './layout';
import * as media from './media-queries';
import * as shadows from './shadows';
import * as typography from './typography';

export default {
  colors,
  media,
  layout,
  typography,
  shadows,
  globals: {},
};
