import React from 'react';
import { any, bool, object, string } from 'prop-types';

import { Column, Row } from '../Layout';
import { css, withStyles } from '../styles/with-styles';

class BlockWithColorBarComponent extends React.Component {
  static propTypes = {
    children: any,
    colorBar: string,
    colorBarStyles: object,
    contentStyles: object,
    styles: object,
    barAtTop: bool,
  };

  render() {
    const {
      children,
      colorBar,
      colorBarStyles,
      contentStyles,
      styles,
      barAtTop,
      theme,
      css: ignoreCss, // do not pass css to Column or Row
      ...otherProps
    } = this.props;

    const Container = barAtTop ? Column : Row;
    return (
      <Container {...otherProps}>
        <div
          {...css(
            colorBarStyles || (barAtTop ? styles.colorBarTop : styles.colorBar),
            { backgroundColor: colorBar },
          )}
        ></div>
        <Column
          {...css(
            styles.contentBlockBase,
            contentStyles ||
              (barAtTop ? styles.containerTop : styles.container),
          )}
        >
          {children}
        </Column>
      </Container>
    );
  }
}

export default withStyles(({ media }) => ({
  ...media.hideSmall,
  colorBar: {
    width: 8,
    minWidth: 8,
    borderRadius: '4px 0 0 4px',
  },
  colorBarTop: {
    height: 8,
    minHeight: 8,
    borderRadius: '4px 4px 0 0',
  },
  contentBlockBase: {
    width: '100%',
    backgroundColor: 'white',
  },
  container: {
    padding: 12,
    borderBottom: '1px solid #E6E9F0',
    borderRight: '1px solid #E6E9F0',
    borderTop: '1px solid #E6E9F0',
    borderRadius: '0 4px 4px 0',
  },
  containerTop: {
    padding: 12,
    borderBottom: '1px solid #E6E9F0',
    borderRight: '1px solid #E6E9F0',
    borderLeft: '1px solid #E6E9F0',
    borderRadius: '0 4px 4px 0',
  },
}))(BlockWithColorBarComponent);
