import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { PREMIUM_SUBSCRIPTION_STRIPE_PRODUCT_ID } from '../../constants';
import { useUserProfile } from '../../core/TTgraphql';
import basicProfileQuery from '../../graphql/basicUserProfile.graphql';
import createPremiumPlusSubscription from '../../graphql/mutations/payments/createPremiumPlusSubscription.graphql';
import { getCookies } from '../../helpers/cookies';
import { createAndSaveIdempotencyKey } from '../../helpers/createAndSaveIdempotencyKey';
import { getGBBPremiumPlanId } from '../../helpers/getPremiumPlanId';
import { useRenderOutside } from '../../helpers/render-outside';
import { resetIdempotencyKey } from '../../helpers/resetIdempotencyKey';
import StripeElementsModal from '../../pages/common/stripe/StripeElementsModal';
import { useConfig } from '../../providers/ConfigProvider';
import { segmentTracking } from '../../services/utilities/segment';
import {
  MODAL_LOCATIONS,
  USER_EXPERRIMENTS,
} from '../PremiumSubscription/constants';
import getSegmentLocation from '../PremiumSubscription/get-premium-modal-segment-location';
import getPremiumPurchasedFrom from '../PremiumSubscription/getPremiumPurchasedFrom';
import PremiumIsActiveModal from '../PremiumSubscription/PremiumIsActivateModal';
import { useErrorToast } from '../Toast';

import CongratsModal from './CongratsModal';
import GBBPremiumModal from './GBBPremiumModal';
import usePricesFromShortCodes from './usePricesFromShortCodes';

const GBBPremiumModalWrapper = ({
  onClose,
  singlePaymentType,
  singlePaymentAction,
  onSuccess,
  refetchQueries,
  postPaymentAction,
  showFormsLink,
  hideButtons,
  benefitsType,
  openLocation,
  copy,
  segmentProperties: segmentProps = {},
  onSuccessModalDoneClicked,
  purchase_location,
  extraParams = {},
  onSubscribeClicked,
}) => {
  const [showStripe, setShowStripe] = useState(false);
  const [selectedShortCode, setSelectedShortCode] = useState();
  const errorToast = useErrorToast();
  const { user } = useUserProfile({}, false);
  const experimentValue = user.active_experiments?.find(
    (exp) => exp.name === USER_EXPERRIMENTS.GBB_MODAL_EXPERIMENT,
  )?.value;

  const renderOutside = useRenderOutside();

  const {
    GBB_SINGLE_LEASE_YEARLY_59_SHORT_CODE,
    GBB_FORMS_PACK_YEARLY_199_SHORT_CODE,
    GBB_PRO_YEARLY_119_SHORT_CODE,
    GBB_PREMIUM_YEARLY_149_SHORT_CODE,
    GBB_PREMIUM_YEARLY_179_SHORT_CODE,
    GBB_PREMIUM_YEARLY_199_SHORT_CODE,
  } = useConfig();

  const userShortCode = getGBBPremiumPlanId(user);

  const proPrice = usePricesFromShortCodes(GBB_PRO_YEARLY_119_SHORT_CODE);

  const premiumPrice = usePricesFromShortCodes(userShortCode);

  const queryParams = queryString.parse(location.search);
  const isMobileApp = queryParams?.mobile_app === 'true';
  const modalTypeDesktop = openLocation || singlePaymentType;
  const modalType = isMobileApp ? MODAL_LOCATIONS.MOBILE_APP : modalTypeDesktop;

  const { premium_referral } = getCookies();

  const segmentLocation = getSegmentLocation(modalType);

  const segmentProperties = {
    ...segmentProps,
    location: segmentLocation,
    experiment: `experiment_gbb_${experimentValue}`,
  };

  let stripeModalLabel;
  switch (selectedShortCode) {
    case GBB_PREMIUM_YEARLY_149_SHORT_CODE:
    case GBB_PREMIUM_YEARLY_179_SHORT_CODE:
    case GBB_PREMIUM_YEARLY_199_SHORT_CODE:
      stripeModalLabel = 'Premium Subscription';
      break;
    case GBB_PRO_YEARLY_119_SHORT_CODE:
      stripeModalLabel = 'Pro Subscription';
      break;
    case GBB_SINGLE_LEASE_YEARLY_59_SHORT_CODE:
      stripeModalLabel = 'Subscribe to Single Lease';
      break;
    case GBB_FORMS_PACK_YEARLY_199_SHORT_CODE:
      stripeModalLabel = 'Subscribe to Forms Pack';
      break;
  }

  const subscriptionPrice = usePricesFromShortCodes(selectedShortCode);

  const supportedStripeProductIds = [PREMIUM_SUBSCRIPTION_STRIPE_PRODUCT_ID];

  useEffect(() => {
    segmentTracking('gbb_premium_modal loaded', {
      ...segmentProperties,
    });
  }, []);

  const wasPremiumSelected = () =>
    selectedShortCode !== GBB_PRO_YEARLY_119_SHORT_CODE;

  const [createSubscription] = useMutation(createPremiumPlusSubscription, {
    refetchQueries: [
      { query: basicProfileQuery },
      ...(refetchQueries?.map((query) => ({ query })) || []),
    ],
    awaitRefetchQueries: true,
  });

  const onToken = useCallback(
    async (token, { promoCode }) => {
      let error;
      try {
        const idempotencyKey = createAndSaveIdempotencyKey();
        await createSubscription({
          variables: {
            token,
            promoCode,
            idempotencyKey,
            plan_id: selectedShortCode,
            // If not referred by any source, we track the location from where it is being bought
            referral: premium_referral || getPremiumPurchasedFrom(modalType),
            location: purchase_location,
          },
        });

        segmentTracking('purchase success', {
          ...segmentProperties,
        });

        if (wasPremiumSelected()) {
          renderOutside((done) => (
            <PremiumIsActiveModal
              onClose={() => {
                done();
                onSuccessModalDoneClicked && onSuccessModalDoneClicked();
              }}
              open
              segmentProperties={segmentProperties}
            />
          ));
        } else {
          renderOutside((done) => (
            <CongratsModal
              open
              segmentProperties={segmentProperties}
              onClose={() => {
                done();
                renderOutside((done) => (
                  <PremiumIsActiveModal
                    onClose={() => {
                      done();
                      onSuccessModalDoneClicked && onSuccessModalDoneClicked();
                    }}
                    open
                    segmentProperties={segmentProperties}
                  />
                ));
              }}
            />
          ));
        }

        if (postPaymentAction) {
          await postPaymentAction();
        }
        onSuccess();
      } catch (e) {
        const message = get(e, 'graphQLErrors[0].message', 'An error occurred');
        errorToast(message);
        error = e?.graphQLErrors;
      }

      resetIdempotencyKey(error);
    },
    [selectedShortCode],
  );

  const handleSubscribe = (plan, eventLabel) => {
    segmentTracking(`${eventLabel} clicked`, {
      ...segmentProperties,
    });

    let code;
    switch (plan) {
      case 'premium':
        code = userShortCode; // if premium we use the price set in the premium_price_signup
        break;
      case 'pro':
        code = GBB_PRO_YEARLY_119_SHORT_CODE;
        break;
      case 'single_lease':
        code = GBB_SINGLE_LEASE_YEARLY_59_SHORT_CODE;
        break;
      case 'forms_pack':
        code = GBB_FORMS_PACK_YEARLY_199_SHORT_CODE;
        break;
    }

    setSelectedShortCode(code);

    onSubscribeClicked ? onSubscribeClicked() : setShowStripe(true);
  };

  if (!user?.id) {
    return null;
  }

  if (showStripe) {
    return (
      <StripeElementsModal
        open
        onClose={() => {
          setShowStripe(false);
        }}
        onToken={onToken}
        title={stripeModalLabel}
        amount={subscriptionPrice}
        buttonLabel={`Pay $${subscriptionPrice}`}
        supportedStripeProductIds={supportedStripeProductIds}
        amountLabel="year"
        onPayClicked={() => {
          segmentTracking('get_premium_purchased clicked', {
            ...segmentProperties,
          });
        }}
      />
    );
  } else {
    return (
      <GBBPremiumModal
        {...{
          singlePaymentType,
          singlePaymentAction,
          showFormsLink,
          openLocation,
          copy,
          extraParams,
          segmentProperties,
          proPrice,
          user,
          premiumPrice,
          benefitsType,
          hideButtons,
          isMobileApp,
        }}
        onClose={() => {
          segmentTracking('close clicked', {
            ...segmentProperties,
          });
          onClose();
        }}
        onGetPremium={() => handleSubscribe('premium', 'get_premium')}
        onGetPro={() => handleSubscribe('pro', 'get_pro')}
        onSingleLeaseMembership={() =>
          handleSubscribe('single_lease', 'get_single_lease')
        }
        onFormsPackMembership={() =>
          handleSubscribe('forms_pack', 'get_forms_pack')
        }
        price={subscriptionPrice}
        variantToRender={experimentValue}
      />
    );
  }
};

GBBPremiumModalWrapper.propTypes = {
  onClose: PropTypes.func,
  singlePaymentType: PropTypes.string,
  singlePaymentAction: PropTypes.func,
  onSuccess: PropTypes.func,
  refetchQueries: PropTypes.arrayOf(PropTypes.string),
  postPaymentAction: PropTypes.func,
  showFormsLink: PropTypes.bool,
  hideButtons: PropTypes.bool,
  benefitsType: PropTypes.string,
  openLocation: PropTypes.string,
  copy: PropTypes.string,
  segmentProperties: PropTypes.object,
  onSuccessModalDoneClicked: PropTypes.func,
  purchase_location: PropTypes.string,
  extraParams: PropTypes.object,
  onSubscribeClicked: PropTypes.func,
};

export default GBBPremiumModalWrapper;
