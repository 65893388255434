import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

let DesignActionsRotateLeft = (props) => (
  <SvgIcon {...props}>
    <g
      stroke={props.color}
      strokeWidth={props.strokeWidth || 1.25}
      fillOpacity="0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    >
      <path
        strokeDasharray="2"
        d="M10.48 21.998c-4.49-.507-7.98-4.316-7.98-8.942 0-1.454.345-2.828.957-4.042"
      />
      <path d="M10.495 4.11c.33-.037.666-.055 1.005-.055 4.971 0 9 4.029 9 9 0 4.633-3.502 8.45-8.005 8.945M13.031 1.555l-3.531 2.5 3.531 2.508" />
    </g>
  </SvgIcon>
);
DesignActionsRotateLeft = memo(DesignActionsRotateLeft);
DesignActionsRotateLeft.displayName = 'DesignActionsRotateLeft';
DesignActionsRotateLeft.muiName = 'SvgIcon';

export default DesignActionsRotateLeft;
