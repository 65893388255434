import getMuiTheme from 'material-ui/styles/getMuiTheme';

import * as colors from './colors';

export default getMuiTheme({
  // spacing: spacing,
  fontFamily: "'Open Sans', sans-serif",
  palette: {
    primary1Color: colors.primary,
    primary2Color: colors.secondary,
    primary3Color: colors.tertiary,
    // accent1Color: colors.text1,
    // accent2Color: colors.text2,
    // accent3Color: colors.text3,
    // textColor: darkBlack,
    // alternateTextColor: white,
    // canvasColor: white,
    // borderColor: grey300,
    // disabledColor: fade(darkBlack, 0.3),
    // pickerHeaderColor: cyan500,
    // clockCircleColor: fade(darkBlack, 0.07),
    // shadowColor: fullBlack,
  },
});
