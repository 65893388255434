import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../../../components/Button';
import PlusCircle from '../../../../../../icons/PlusCircle';
import leasePropTypes from '../../../../../../prop-types/lease';
import ChargeCollapsed from '../../components/ChargeCollapsed';
import MonthlyChargeForm from '../MonthlyChargeForm';

import styles from './MonthlyChargesListForm.module.scss';

const MonthlyChargesListForm = ({
  onAdd,
  onEdit,
  onDelete,
  charges,
  isPremiumUser,
  bankAccountOptions,
  lease,
  closedForm = false,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [editingId, setEditingId] = useState(null);

  const initialCharge = {};

  const onChargeEdit = async (charge) => {
    await onEdit(charge);
    setEditingId(null);
  };

  const onChargeEditClick = (charge) => {
    setEditingId(charge.id);
    setShowForm(false);
  };

  useEffect(() => {
    setShowForm(charges.length === 0 && !closedForm);
  }, [charges.length]);

  return (
    <>
      {charges.map((charge, index) => (
        <section key={`monthly_charges${index}`}>
          {charge.id !== editingId && (
            <ChargeCollapsed
              charge={charge}
              key={charge.id}
              onEdit={() => onChargeEditClick(charge)}
              onDelete={() => onDelete(charge)}
              className={styles.chargeCollapsed}
            />
          )}

          {charge.id === editingId && (
            <MonthlyChargeForm
              chargeInfo={charge}
              onSubmit={onChargeEdit}
              onCancel={() => setEditingId(null)}
              formClassName={styles.editChargeForm}
              isFromNewFlow
              lease={lease}
              isPremiumUser={isPremiumUser}
              bankAccountOptions={bankAccountOptions}
            />
          )}
        </section>
      ))}

      {showForm && (
        <MonthlyChargeForm
          chargeInfo={initialCharge}
          onSubmit={onAdd}
          lease={lease}
          onCancel={() => setShowForm(false)}
          isFromNewFlow
          isPremiumUser={isPremiumUser}
          bankAccountOptions={bankAccountOptions}
        />
      )}

      {!showForm && !editingId && (
        <Button
          tertiary
          className={(styles.mybtn, styles.label)}
          type="button"
          onClick={() => setShowForm(true)}
          icon={PlusCircle}
          id="add_monthly_charge_button"
        >
          Add Monthly Charge
        </Button>
      )}
    </>
  );
};

MonthlyChargesListForm.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  charges: PropTypes.array,
  isPremiumUser: PropTypes.bool,
  bankAccountOptions: PropTypes.array,
  closedForm: PropTypes.bool,
  lease: leasePropTypes,
};

export default MonthlyChargesListForm;
