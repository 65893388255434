import React from 'react';

import { css, withStyles } from '../../styles/with-styles';

const _SectionTitle = ({ withStylesStyles, style, title }) => (
  <p {...css(withStylesStyles.sectionTitle, style)}>
    <span {...css(withStylesStyles.sectionTitleArrow)}>{'> '}</span> {title}
  </p>
);
export default withStyles(
  ({ typography, colors }) => ({
    sectionTitle: {
      marginLeft: 20,
      ...typography.sectionTitle,
      color: colors.gray2,
    },
    sectionTitleArrow: {
      fontSize: '120%',
      color: colors.action1,
    },
  }),
  { stylesPropName: 'withStylesStyles' },
)(_SectionTitle);
