import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { graphql } from '@apollo/client/react/hoc';
import get from 'lodash.get';
import { PropTypes } from 'prop-types';

import { Experiments } from '../constants/experiments';
import basicUserProfile from '../graphql/basicUserProfile.graphql';
import applicantsQuery from '../graphql/queries/applicantsQuery.graphql';
import depositsFiltersQuery from '../graphql/queries/depositsFiltersQuery.graphql';
import leasesQuery from '../graphql/queries/leasesQuery.graphql';
import listingsQuery from '../graphql/queries/listingsQuery.graphql';
import plainListingsQuery from '../graphql/queries/plainListingsQuery.graphql';
import tenantsQuery from '../graphql/queries/tenantsQuery.graphql';
import {
  getExperimentGbbCookie,
  setExperimentGbbCookie,
} from '../helpers/experimentGbb';
import getExperimentVariant from '../helpers/experiments';
import { getConfig } from '../services/configService';

import * as auth from './auth/authService';

export function graphqlErrorParser(gqlError) {
  if (gqlError) {
    return gqlError.graphQLErrors.some((err) => {
      if (err.authenticationError) {
        console.log('unauthorized');
        return true;
      }
    });
  }
}

export const TTgraphql = (configuration) => (WrappedComponent) => {
  const displayName = `TT(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  class Viewport extends React.Component {
    static displayName = displayName;
    static WrappedComponent = WrappedComponent;

    static contextTypes = { client: PropTypes.object.isRequired };

    constructor(props, context) {
      super(props, context);
      this.client = auth.getApolloClient();
      this.state = {
        loading: false,
        returnedQuery: undefined,
      };
    }

    componentDidMount() {
      this.mounted = true;
    }

    componentWillUnmount() {
      this.setState({
        loading: false,
        returnedQuery: undefined,
      });
      this.mounted = false;
    }

    query = (options) => {
      return this._query(options)
        .then((response) => response && response.data)
        .then(this._handleQueryResponse);
    };

    mutate = (options) => {
      return this._mutate(options)
        .then((response) => response && response.data)
        .then(this._handleMutationResponse);
    };

    fetchMore = (queryOptions, fetchMoreOptions) => {
      return this._query(queryOptions)
        .then((response) => {
          return fetchMoreOptions.updateQuery(this.state.returnedQuery, {
            fetchMoreResult: response,
            queryVariables: queryOptions.variables,
          });
        })
        .then(this._handleQueryResponse);
    };

    render() {
      const data = this.state.returnedQuery;
      const ownProps = this.props;
      const newProps = configuration.props({
        ownProps,
        data: {
          ...data,
          loading: this.state.loading,
          // error: false, // not using them so far
          query: this.query,
          fetchMore: this.fetchMore,
          mutate: this.mutate,
        },
      });
      const props = {
        ...ownProps,
        ...newProps,
      };
      return <WrappedComponent {...props} />;
    }

    _query = (options) => {
      this.setState({ loading: true });
      return this.client
        .query(options)
        .then(this._validateQueryResponse)
        .catch(this._handleQueryResponseError);
    };

    _mutate = (options) => {
      this.setState({ loading: true });
      return this.client
        .mutate(options)
        .then(this._validateQueryResponse)
        .catch(this._handleQueryResponseError);
    };
    _validateQueryResponse = (res) => {
      if (!this.mounted) {
        return;
      }
      if (graphqlErrorParser(res.data && res.data.error)) {
        throw new Error('Authentication failed - Unauthorized');
      }
      if (res.error) {
        console.log(res.error);
      }
      if (res.loading) {
        throw new Error('wtf is this loading');
      }
      return res;
    };
    _handleQueryResponseError = (error) => {
      if (!this.mounted) {
        return;
      }
      if (error.message.indexOf('Unauthorized') > 0) {
        auth.logout(true);
        return;
      }

      this.setState({ loading: false });
      console.log('There was an error', error);
      throw error;
    };

    _handleQueryResponse = (responseData) => {
      if (!this.mounted) {
        return;
      }
      this.setState({
        loading: false,
        returnedQuery: responseData || {},
      });
      return responseData;
    };
    _handleMutationResponse = (response) => {
      if (!this.mounted) {
        return;
      }
      this.setState({
        loading: false,
      });
      return response;
    };
  }

  return Viewport;
};

export const getUserInfoWrapper = (configuration, polling) => {
  const { IS_OWNER, USER_PROFILE_POLL_INTERVAL } = getConfig();
  if (polling === undefined && IS_OWNER) polling = USER_PROFILE_POLL_INTERVAL;
  let opts = {};

  if (polling && polling > 0) {
    opts.pollInterval = polling;
  }
  const { options, ...conf } = configuration;
  if (options) {
    opts = {
      ...opts,
      ...options,
    };
  }
  return graphql(basicUserProfile, {
    options: opts,
    ...conf,
  });
};

export const getTenantsWrapper = (configuration, polling) => {
  let opts = {
    fetchPolicy: 'network-only',
  };

  if (polling && polling > 0) {
    opts.pollInterval = polling;
    opts.context = {
      pollingEnabled: true,
    };
  }
  const { options, ...conf } = configuration;
  if (options) {
    opts = {
      ...opts,
      ...options,
    };
  }
  return graphql(tenantsQuery, {
    options: opts,
    ...conf,
  });
};

export const getApplicantsWrapper = (configuration, polling) => {
  let opts = {
    fetchPolicy: 'network-only',
  };

  if (polling && polling > 0) {
    opts.pollInterval = polling;
    opts.context = {
      pollingEnabled: true,
    };
  }
  const { options, ...conf } = configuration;
  if (options) {
    opts = {
      ...opts,
      ...options,
    };
  }
  return graphql(applicantsQuery, {
    options: opts,
    ...conf,
  });
};

export const useApplicants = (options = {}, polling) => {
  const pollInterval = polling && polling > 0 ? { pollInterval: polling } : {};
  const response = useQuery(applicantsQuery, {
    ...pollInterval,
    ...options,
    context: {
      ...(polling && polling > 0 ? { pollingEnabled: true } : {}),
      ...(options?.context || {}),
    },
  });
  return {
    response,
    loading: response.loading && response.networkStatus !== 6,
    applicants: get(response, 'data.userData.applicants.data', []),
  };
};

export const useTenants = (options = {}, polling) => {
  const pollInterval = polling && polling > 0 ? { pollInterval: polling } : {};
  const response = useQuery(tenantsQuery, {
    ...pollInterval,
    ...options,
    context: {
      ...(polling && polling > 0 ? { pollingEnabled: true } : {}),
      ...(options.context || {}),
    },
  });
  return {
    response,
    loading: response.loading && response.networkStatus !== 6,
    tenants: get(response, 'data.userData.tenants.data.list', []),
  };
};

export const useDepositsFilterData = (options = {}) => {
  const response = useQuery(depositsFiltersQuery, {
    ...options,
  });
  const { data = {} } = response;
  const { filters = {} } = data;
  const { deposits = {} } = filters;
  const { listings = [], leases = [], tenants = [], banks = [] } = deposits;

  return {
    response,
    loading: response.loading && response.networkStatus !== 6,
    listings,
    leases,
    tenants,
    banks,
  };
};

export const getLeasesWrapper = (configuration, polling) => {
  const { USER_PROFILE_POLL_INTERVAL } = getConfig();
  if (polling === undefined) polling = USER_PROFILE_POLL_INTERVAL;
  let opts = {};

  if (polling && polling > 0) {
    opts.pollInterval = polling;
    opts.context = {
      pollingEnabled: true,
    };
  }
  const { options, ...conf } = configuration;
  if (options) {
    opts = {
      ...opts,
      ...options,
    };
  }
  return graphql(leasesQuery, {
    options: opts,
    ...conf,
  });
};

export const useLeases = (options = {}, polling) => {
  const { USER_PROFILE_POLL_INTERVAL } = getConfig();
  if (polling === undefined) polling = USER_PROFILE_POLL_INTERVAL;
  const pollInterval = polling && polling > 0 ? { pollInterval: polling } : {};
  const response = useQuery(leasesQuery, {
    ...pollInterval,
    ...options,
    context: {
      ...(polling && polling > 0 ? { pollingEnabled: true } : {}),
      ...(options.context || {}),
    },
  });
  return {
    response,
    loading: response.loading && response.networkStatus !== 6,
    leases: get(response, 'data.userData.leases.data', []),
  };
};

export const getListingsWrapper = (configuration, fullListingQuery) => {
  let opts = {};
  const { options, ...conf } = configuration;
  if (options) {
    opts = {
      ...opts,
      ...options,
    };
  }
  const query = fullListingQuery ? listingsQuery : plainListingsQuery;
  return graphql(query, {
    options: opts,
    ...conf,
  });
};

export const useListings = (options = {}, fullListingQuery, customQuery) => {
  const query =
    customQuery || (fullListingQuery ? listingsQuery : plainListingsQuery);
  const response = useQuery(query, options);
  const pageInfo = get(response, 'data.userData.listings.data.pageInfo', {});
  let requestSent = false;
  return {
    response,
    loading: response.loading && response.networkStatus !== 6,
    listings: get(response, 'data.userData.listings.data.edges', []).map(
      (edge) => edge.node,
    ),
    error: response.error,
    totalListings: get(response, 'data.userData.listings.data.total', 0),
    pageInfo,
    fetchMoreListings: () => {
      if (!pageInfo.hasNextPage || !pageInfo.endCursor) return;
      if (requestSent) return;
      requestSent = true;
      response.fetchMore({
        variables: {
          after: pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const prevData = get(prev, 'userData.listings.data', {});
          const newData = get(fetchMoreResult, 'userData.listings.data', {});
          return {
            ...prev,
            userData: {
              ...prev.userData,
              listings: {
                ...(prev.userData && prev.userData.listings),
                data: {
                  ...newData,
                  edges: [...prevData.edges, ...newData.edges],
                },
              },
            },
          };
        },
      });
    },
  };
};

export const useUserFields = (fields = [], options) => {
  const response = useQuery(
    gql`
    query {
      userData {
        basicProfile {
          data {
            ... on OwnerProfile {
              id
              ${fields.join('\n')}
            }
          }
        }
      }
    }
  `,
    options,
  );
  const error = get(response, 'error', null);
  const networkStatus = get(response, 'networkStatus', '');
  return {
    response,
    loading: response.loading && response.networkStatus !== 6,
    user: get(response, 'data.userData.basicProfile.data', {}),
    failedNetworkRequest: networkStatus === 8 || !!error,
    error,
  };
};

/**
 *  You can pass useUserProfile({}, false) === useUserProfile({ polling: false })
 *
 * @param {*} options
 * @param {*} polling
 * @returns
 */
export const useUserProfile = (options = {}, polling) => {
  const { IS_OWNER, USER_PROFILE_POLL_INTERVAL } = getConfig();
  if (polling === undefined && IS_OWNER) polling = USER_PROFILE_POLL_INTERVAL;
  /**
   * NOTICE:
   *
   * This two lines remove the need to pass an empty json
   * when we want to disable the polling (i.e.: useUserProfile({}, false)),
   * while maintaining the backward compatibility.
   *
   * useUserProfile({}, false) === useUserProfile({ polling: false })
   */
  polling = options?.polling != null ? options.polling : polling;
  if (options != null && options.polling != null) delete options.polling;

  const pollInterval = polling && polling > 0 ? { pollInterval: polling } : {};
  const response = useQuery(basicUserProfile, {
    ...pollInterval,
    ...options,
    context: {
      ...(polling && polling > 0 ? { pollingEnabled: true } : {}),
      ...(options.context || {}),
    },
    skip: options.skip,
  });

  if (options.skip) {
    return {
      loading: false,
      user: null,
    };
  }

  const error = get(response, 'error', null);
  const networkStatus = get(response, 'networkStatus', '');
  const user = get(response, 'data.userData.basicProfile.data', {});
  const fraudStatus = get(user, 'payments_status.fraud');
  const subscriptionEnabled = get(
    user,
    'premium_subscription_subscribed',
    false,
  );

  if (!response.loading && !response.networkStatus !== 6 && user.id) {
    const experimentGbb = getExperimentVariant(
      user,
      Experiments.GoodBetterBest.name,
    );

    const hasExperimentGbbCookie = getExperimentGbbCookie();

    if (experimentGbb && !hasExperimentGbbCookie) {
      setExperimentGbbCookie(experimentGbb);
    }
  }

  return {
    response,
    user,
    fraudStatus,
    subscriptionEnabled,
    loading: response.loading && response.networkStatus !== 6,
    features: get(response, 'data.viewer.features', []),
    failedNetworkRequest: networkStatus === 8 || !!error,
    error,
    refetch: response.refetch,
  };
};
