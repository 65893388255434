import React from 'react';
import { any, array, bool, object, oneOfType, string } from 'prop-types';

import { css, withStyles } from '../../styles/with-styles';

import Tab from './tab.component';

class Tabs extends React.Component {
  renderTab = ({ label, url, count, id }, index, array) => {
    const { chevron, navItemStyle } = this.props;
    return (
      <Tab
        to={url}
        count={count}
        label={label}
        first={index === 0}
        last={index === array.length - 1}
        key={`key-of-${label}-tab`}
        chevron={chevron}
        id={id}
        navItemStyle={navItemStyle}
      />
    );
  };

  render = () => {
    const {
      children,
      styles,
      tabs,
      tabsContentStyles,
      containerStyle = {},
      navContainerStyle,
    } = this.props;
    return (
      <div style={containerStyle}>
        <nav {...css(styles.container, navContainerStyle)}>
          {tabs.map(this.renderTab)}
        </nav>
        <main {...css(styles.tabsContent, tabsContentStyles)}>{children}</main>
      </div>
    );
  };
}

Tabs.propTypes = {
  chevron: bool,
  children: any,
  styles: object,
  tabs: oneOfType([string, object, array]),
  tabsContentStyles: object,
  containerStyle: object,
};

Tabs.defaultProps = {
  tabsContentStyles: {},
};

export default withStyles(({ colors, media }) => ({
  container: {
    top: 6,
    position: 'relative',
    display: 'inline-block',
    color: '#777c82',
    backgroundColor: colors.lightGrey,
    borderTop: `1px solid ${colors.lightGrey}`,
    borderRight: `1px solid ${colors.lightGrey}`,
    borderLeft: `1px solid ${colors.lightGrey}`,
    ...media.smallScreen({
      width: '100%',
      display: 'flex',
      overflow: 'hidden',
    }),
  },
  tabsContent: {
    padding: '20px',
    backgroundColor: 'white',
    border: '1px solid #E6E9F0',
  },
}))(Tabs);
