import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Documents = ({ width, height, color }) => (
  <svg width={width} height={height}>
    <path
      fill={color}
      fillRule="nonzero"
      d="M13.122.667c.073 0 .143.01.21.027l.01.003a.8.8 0 0 1 .183.075l.02.013a.778.778 0 0 1 .155.12l6.53 6.531a.816.816 0 0 1 .24.578v18.503c0 .45-.366.816-.817.816H1.15a.816.816 0 0 1-.817-.816V1.483c0-.45.366-.816.817-.816h11.972Zm-.817 1.632H1.966v23.402h16.87V8.83h-5.714a.816.816 0 0 1-.816-.816V2.299ZM15.3 21.347a.816.816 0 1 1 0 1.633H5.503a.816.816 0 1 1 0-1.633H15.3Zm0-3.265a.816.816 0 1 1 0 1.632H5.503a.816.816 0 1 1 0-1.632H15.3Zm0-3.266a.816.816 0 0 1 0 1.633H5.503a.816.816 0 1 1 0-1.633H15.3Zm0-3.265a.816.816 0 1 1 0 1.633H5.503a.816.816 0 0 1 0-1.633H15.3Zm-4.898-3.265a.816.816 0 1 1 0 1.632H5.503a.816.816 0 1 1 0-1.632h4.898Zm3.537-4.832v3.743h3.743l-3.743-3.743Z"
    />
  </svg>
);

Documents.defaultProps = {
  width: 21,
  height: 28,
  color: colors.text,
};

Documents.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Documents);
