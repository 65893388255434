import React from 'react';
import PropTypes from 'prop-types';

import ChooseAccountCard from '../../../components/ChooseAccountCard';
import { routes } from '../../../helpers/routes';
import TurboTenantLargeLogo from '../../../icons/TurboTenantLargeLogo';

import styles from './SelectAccount.module.scss';

const SelectAccount = ({ history }) => {
  return (
    <div className={styles.container}>
      <TurboTenantLargeLogo className={styles.logo} />
      <ChooseAccountCard
        className={styles.card}
        // TODO CONNECT REAL DATA
        user={{
          first_name: 'Jane',
          last_name: 'Smith',
          email: 'janesmith@example.com',
        }}
        users={[
          {
            first_name: 'John',
            last_name: 'Doe',
            id: '1',
          },
          {
            first_name: 'Jane',
            last_name: 'Smith',
            id: '2',
          },
          {
            first_name: 'Michael',
            last_name: 'Johnson',
            id: '3',
          },
          {
            first_name:
              'Moelajdskfljsdklfjdskfjksdljfklsdjflsdjfskldfjlksjfksjfdlks',
            last_name:
              'Shmoealsjdfkljsdflkjslfjslkjflksjflksjdflksjdflkjsdlfjsdlfjsldfjsdjflksdjf',
            id: '4',
          },
          {
            first_name: 'David',
            last_name: 'Wilson',
            id: '5',
          },
        ]}
        onSubmit={() => {
          history.push({
            pathname: routes.dashboard,
          });
        }}
        onLogout={() => {
          console.log('logout');
          history.push({
            pathname: routes.logout,
          });
        }}
      ></ChooseAccountCard>
    </div>
  );
};

SelectAccount.propTypes = {
  history: PropTypes.object,
};

export default SelectAccount;
