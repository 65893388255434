import React from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton, SecondaryButton } from '../Button';
import { css } from '../styles/with-styles';
import { ThemedStyleSheet } from '../styles/with-styles';

const theme = ThemedStyleSheet.get();

const CenteredCard = (props) => {
  const {
    renderCardBorder,
    iconStyles,
    titleStyles,
    id,
    buttonSize = 'large',
    noPadding,
    'data-qa': dataQa,
    button1DataQa,
    button2DataQa,
    descriptionStyles,
    button1style,
    button1LabelStyle,
    iconColor,
  } = props;
  const Icon = props.icon;
  const largeCardStyles = {
    minWidth: 240,
    padding: noPadding ? 0 : theme.media.isSmallScreen() ? 16 : 32,
    paddingBottom: noPadding ? 0 : 40,
    paddingTop: noPadding ? 0 : theme.media.isSmallScreen() ? 40 : 32,
    border:
      renderCardBorder || renderCardBorder === undefined ? '1px solid' : null,
    borderRadius: 4,
    boxSizing: 'border-box',
    borderColor: theme.colors.lightGrey,
    backgroundColor: theme.colors.white,
    textAlign: 'center',
  };

  const textStyles = {
    marginTop: 12,
    ...props.textStyles,
  };

  const buttonsDivStyles = {
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 0,
  };
  const divAttrs = {};
  if (id) {
    divAttrs.id = id;
  }
  return (
    <div
      data-qa={dataQa}
      {...divAttrs}
      {...css({ ...largeCardStyles, ...props.style })}
    >
      {props.icon && (
        <Icon
          color={iconColor || theme.colors.darkBlueGrey}
          style={{
            width: 84,
            height: 84,
            ...iconStyles,
          }}
        />
      )}
      <div {...css(textStyles)}>
        <p
          {...css({
            ...theme.typography.largeCardTitle,
            marginBottom: props.subtitle ? 2 : 4,
            ...titleStyles,
          })}
          id={props.titleId}
        >
          {props.title}
        </p>
        {props.subtitle && (
          <p
            {...css({
              color: theme.colors.grey,
              marginBottom: 2,
            })}
          >
            {props.subtitle}
          </p>
        )}
        <div
          {...css({
            color: theme.colors.darkBlueGrey,
            ...descriptionStyles,
          })}
        >
          {props.description}
        </div>
      </div>
      {props.button1Label && (
        <div {...css(buttonsDivStyles)}>
          <PrimaryButton
            disabled={props.button1Disabled}
            label={props.button1Label}
            labelStyle={button1LabelStyle}
            onClick={props.button1Action}
            size={buttonSize}
            id={props.button1Id}
            data-qa={button1DataQa}
            style={button1style}
          />
        </div>
      )}
      {props.button2Label && (
        <div {...css(buttonsDivStyles)}>
          <SecondaryButton
            size={buttonSize}
            id={props.button2Id}
            disabled={props.button2Disabled}
            label={props.button2Label}
            onClick={props.button2Action}
            data-qa={button2DataQa}
          />
        </div>
      )}
    </div>
  );
};

CenteredCard.defaultProps = {
  iconStyles: {},
  titleStyles: {},
  textStyles: {},
};

CenteredCard.propTypes = {
  'id': PropTypes.string,
  'title': PropTypes.string,
  'titleId': PropTypes.string,
  'subtitle': PropTypes.any,
  'description': PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  'descriptionStyles': PropTypes.object,
  'button1Label': PropTypes.string,
  'button1style': PropTypes.object,
  'button1LabelStyle': PropTypes.object,
  'button1Action': PropTypes.func,
  'button1Id': PropTypes.string,
  'button2Label': PropTypes.string,
  'button2Action': PropTypes.func,
  'button2Id': PropTypes.string,
  'buttonSize': PropTypes.string,
  'button1Disabled': PropTypes.bool,
  'button2Disabled': PropTypes.bool,
  'style': PropTypes.object,
  'iconStyles': PropTypes.object,
  'titleStyles': PropTypes.object,
  'textStyles': PropTypes.object,
  'icon': PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  'iconColor': PropTypes.string,
  'renderCardBorder': PropTypes.bool,
  'data-qa': PropTypes.string,
  'button1DataQa': PropTypes.string,
  'button2DataQa': PropTypes.string,
};

export default CenteredCard;
