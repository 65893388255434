import React from 'react';
import PropTypes from 'prop-types';

import { ThemedStyleSheet } from '../styles/with-styles';

const theme = ThemedStyleSheet.get();

export class Layout extends React.Component {
  static propTypes = {
    componentRef: PropTypes.func,
    style: PropTypes.object,

    column: PropTypes.bool,
    rowReverse: PropTypes.bool,
    columnReverse: PropTypes.bool,

    justifyContent: PropTypes.oneOf([
      'start',
      'flex-start',
      'center',
      'end',
      'flex-end',
      'spaced',
      'space-between',
      'around',
      'space-around',
    ]),

    alignItems: PropTypes.oneOf([
      'start',
      'center',
      'end',
      'stretch',
      'baseline',
    ]),

    alignSelf: PropTypes.oneOf([
      'start',
      'center',
      'end',
      'stretch',
      'baseline',
    ]),

    alignContent: PropTypes.oneOf([
      'start',
      'flex-start',
      'center',
      'end',
      'flex-end',
      'spaced',
      'space-between',
      'around',
      'space-around',
    ]),

    wrap: PropTypes.bool,
    wrapReverse: PropTypes.bool,

    flexGrow: PropTypes.number,

    flexShrink: PropTypes.number,

    flexBasis: PropTypes.string,

    onXSmall: PropTypes.oneOf([
      'column',
      'column-reverse',
      'row',
      'row-reverse',
    ]),

    onSmall: PropTypes.oneOf([
      'column',
      'column-reverse',
      'row',
      'row-reverse',
    ]),

    onMedium: PropTypes.oneOf([
      'column',
      'column-reverse',
      'row',
      'row-reverse',
    ]),

    children: PropTypes.node.isRequired,
  };

  getMainAxisAlign = (value) => {
    switch (value) {
      case 'start':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'end':
        return 'flex-end';
      case 'space-between':
      case 'spaced':
        return 'space-between';
      case 'space-around':
      case 'around':
        return 'space-around';
      default:
        return 'flex-start';
    }
  };

  getCrossAxisAlign = (value) => {
    switch (value) {
      case 'start':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'end':
        return 'flex-end';
      case 'stretch':
        return 'stretch';
      case 'baseline':
        return 'baseline';
      default:
        return 'stretch';
    }
  };

  render() {
    const {
      componentRef,
      style,
      column = false,
      rowReverse = false,
      columnReverse = false,

      // Main Axis
      justifyContent,

      // Cross Axis
      alignItems,
      alignSelf,
      alignContent,

      // Wrap
      wrap = false,
      wrapReverse = false,

      flexGrow,
      flexShrink,
      flexBasis,

      onXSmall,
      onSmall,
      onMedium,
      noWrap, // not used, but some users send it (it has the desired effect of noWrap)

      ...ownProps
    } = this.props;

    let direction = { flexDirection: 'row' }; // default row
    if (column) {
      direction = { flexDirection: 'column' };
    }

    if (rowReverse) {
      direction = { flexDirection: 'row-reverse' };
    } else if (columnReverse) {
      direction = { flexDirection: 'column-reverse' };
    }

    let flexWrap = { flexWrap: 'noWrap' };
    if (wrap) {
      flexWrap = { flexWrap: 'wrap' };
    } else if (wrapReverse) {
      flexWrap = { flexWrap: 'wrap-reverse' };
    }

    const justifyContentStyle =
      (justifyContent && {
        justifyContent: this.getMainAxisAlign(justifyContent),
      }) ||
      {};

    const alignItemsStyle =
      (alignItems && { alignItems: this.getCrossAxisAlign(alignItems) }) || {};

    const alignSelfStyle =
      (alignSelf && { alignSelf: this.getCrossAxisAlign(alignSelf) }) || {};

    const alignContentStyle =
      (alignContent && { alignContent: this.getMainAxisAlign(alignContent) }) ||
      {};

    const onXSmallStyle =
      (onXSmall &&
        theme.media.isXSmallScreen() && { flexDirection: onXSmall }) ||
      {};
    const onSmallStyle =
      (onSmall && theme.media.isSmallScreen() && { flexDirection: onSmall }) ||
      {};
    const onMediumStyle =
      (onMedium &&
        theme.media.isMediumScreen() && { flexDirection: onMedium }) ||
      {};

    const flexGrowStyle = (flexGrow && { flexGrow: flexGrow }) || {};
    const flexShrinkStyle = (flexShrink && { flexShrink }) || {};
    const flexBasisStyle = (flexBasis && { flexBasis }) || {};

    const layoutStyles = {
      display: 'flex',
      ...direction,
      ...justifyContentStyle,
      ...alignItemsStyle,
      ...alignSelfStyle,
      ...alignContentStyle,
      ...flexWrap,
      ...flexGrowStyle,
      ...flexShrinkStyle,
      ...flexBasisStyle,
      ...onXSmallStyle,
      ...onSmallStyle,
      ...onMediumStyle,
      ...style,
    };

    return (
      <div style={layoutStyles} {...ownProps} ref={componentRef}>
        {this.props.children}
      </div>
    );
  }
}

export class Row extends React.Component {
  static propTypes = {
    reverse: PropTypes.bool,
    vertical: PropTypes.oneOf([
      'start',
      'center',
      'end',
      'spaced',
      'space-between',
      'around',
      'space-around',
    ]),
    horizontal: PropTypes.oneOf([
      'start',
      'center',
      'end',
      'spaced',
      'space-between',
      'around',
      'space-around',
    ]),

    justifyContent: PropTypes.oneOf([
      'start',
      'flex-start',
      'center',
      'end',
      'flex-end',
      'spaced',
      'space-between',
      'around',
      'space-around',
    ]),
    alignItems: PropTypes.oneOf([
      'start',
      'center',
      'end',
      'stretch',
      'baseline',
    ]),
    alignSelf: PropTypes.oneOf([
      'start',
      'center',
      'end',
      'stretch',
      'baseline',
    ]),
    alignContent: PropTypes.oneOf([
      'start',
      'flex-start',
      'center',
      'end',
      'flex-end',
      'spaced',
      'space-between',
      'around',
      'space-around',
    ]),

    flex: PropTypes.string,
    flexGrow: PropTypes.number,
    flexShrink: PropTypes.number,
    flexBasis: PropTypes.string,
    children: PropTypes.node.isRequired,
  };

  render() {
    const {
      reverse = false,
      flex,
      flexGrow,
      flexShrink,
      flexBasis,
      vertical,
      horizontal,
      justifyContent,
      alignItems,
      alignSelf,
      alignContent,
      ...ownProps
    } = this.props;

    const rowReverse = reverse;

    return (
      <Layout
        rowReverse={rowReverse}
        flex={flex}
        flexGrow={flexGrow}
        flexBasis={flexBasis}
        flexShrink={flexShrink}
        alignItems={vertical || alignItems}
        justifyContent={horizontal || justifyContent}
        alignSelf={alignSelf}
        alignContent={alignContent}
        {...ownProps}
      >
        {this.props.children}
      </Layout>
    );
  }
}

export class Column extends React.Component {
  static propTypes = {
    reverse: PropTypes.bool,
    horizontal: PropTypes.oneOf([
      'start',
      'center',
      'end',
      'spaced',
      'space-between',
      'around',
      'space-around',
    ]),
    vertical: PropTypes.oneOf([
      'start',
      'center',
      'end',
      'spaced',
      'space-between',
      'around',
      'space-around',
    ]),

    justifyContent: PropTypes.oneOf([
      'start',
      'flex-start',
      'center',
      'end',
      'flex-end',
      'spaced',
      'space-between',
      'around',
      'space-around',
    ]),
    alignItems: PropTypes.oneOf([
      'start',
      'center',
      'end',
      'stretch',
      'baseline',
    ]),
    alignSelf: PropTypes.oneOf([
      'start',
      'center',
      'end',
      'stretch',
      'baseline',
    ]),
    alignContent: PropTypes.oneOf([
      'start',
      'flex-start',
      'center',
      'end',
      'flex-end',
      'spaced',
      'space-between',
      'around',
      'space-around',
    ]),

    flex: PropTypes.string,
    flexGrow: PropTypes.number,
    flexShrink: PropTypes.number,
    flexBasis: PropTypes.string,
    children: PropTypes.node.isRequired,
  };

  render() {
    const {
      reverse = false,
      flex,
      flexGrow,
      flexShrink,
      flexBasis,
      vertical,
      horizontal,
      justifyContent,
      alignItems,
      alignSelf,
      alignContent,
      ...ownProps
    } = this.props;

    const columnReverse = reverse;

    return (
      <Layout
        column
        columnReverse={columnReverse}
        flex={flex}
        flexGrow={flexGrow}
        flexBasis={flexBasis}
        flexShrink={flexShrink}
        alignItems={horizontal || alignItems}
        justifyContent={vertical || justifyContent}
        alignSelf={alignSelf}
        alignContent={alignContent}
        {...ownProps}
      >
        {this.props.children}
      </Layout>
    );
  }
}
