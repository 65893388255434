import { isAdminLogin } from '../../core/auth/authService';
import { getConfig } from '../configService';

export function segmentTracking(event, properties) {
  const loggedInAsAdmin = isAdminLogin();
  if (!window.analytics) {
    return;
  }
  if (!loggedInAsAdmin) {
    window.analytics.track(event, properties);
  }
}

export function segmentIdentify(globalId, intercomHash, traits = {}) {
  const { IS_OWNER } = getConfig();
  const loggedInAsAdmin = isAdminLogin();
  if (window.analytics && !loggedInAsAdmin) {
    const decodedId = atob(globalId).split(':').pop();
    window.analytics.ready(function () {
      window.analytics.identify(
        `${IS_OWNER ? 'Owner' : 'Renter'}:${decodedId}`,
        { ...traits },
        {
          ...(intercomHash && {
            integrations: {
              Intercom: {
                user_hash: intercomHash,
              },
            },
          }),
        },
        () => window.analytics.page(),
      );
    });
  }
}
