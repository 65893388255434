import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import colors from '../../../constants/colors';
import SUBSCRIPTION_STATUS from '../../../constants/stripe_subscriptions/subscription_status';
import { useUserProfile } from '../../../core/TTgraphql';
import ownerSettingsQueryGQL from '../../../graphql/queries/ownerSettingsQuery.graphql';
import Academy from '../../../icons/Academy';
import AccountingIcon from '../../../icons/Accounting';
import AccountIcon from '../../../icons/AccountNav';
import ApplicantIcon from '../../../icons/ApplicantNav';
import Bullet from '../../../icons/Bullet';
import DashboardIcon from '../../../icons/DashboardNav';
import ExpensesIcon from '../../../icons/ExpensesNav';
import FormIcon from '../../../icons/FormsNav';
import GiftIcon from '../../../icons/GiftNav';
import HelpIcon from '../../../icons/HelpNav';
import LeadsIcon from '../../../icons/LeadsNav';
import LeasesIcon from '../../../icons/LeaseNav';
import LogoutIcon from '../../../icons/LogoutNav';
import MaintenanceIcon from '../../../icons/MaintenanceAltThick';
import MessagesIcon from '../../../icons/MessagesNav';
import PaymentsIcon from '../../../icons/PaymentsNav';
import PropertyIcon from '../../../icons/PropertyNav';
import SettingsIcon from '../../../icons/SettingsNav';
import TenantsIcon from '../../../icons/TenantsNav';
import ToolboxNav from '../../../icons/ToolboxNav';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';
import ReiHubLoadingModal, {
  useOpenREIHub,
} from '../../ReiHubSubscription/ReiHubLoadingModal';
import useMessagingNotifications from '../useMessagingNotifications';

import DesktopNavigation from './DesktopNavigation';

const DesktopNavigationWrapper = () => {
  const location = useLocation();
  const accountSubItemsRef = useRef(null);
  const accountItemRef = useRef(null);
  const config = useConfig();

  const { user, loading: userLoading } = useUserProfile({}, false);
  const { data: ownerSettingsData } = useQuery(ownerSettingsQueryGQL);

  const { unreadMessagesCount: newMessagingCount } = useMessagingNotifications(
    user?.id,
  );

  const { premiumPlusSubscriptionInfo, reiHubSubscriptionInfo } =
    ownerSettingsData?.userData?.settingsAndBilling?.data || {};
  const premiumStatus = premiumPlusSubscriptionInfo?.status || null;
  const reiHubStatus = reiHubSubscriptionInfo?.status || null;
  const showSubscriptionAlert =
    premiumStatus === SUBSCRIPTION_STATUS.PAST_DUE ||
    reiHubStatus === SUBSCRIPTION_STATUS.PAST_DUE;

  const isPremiumUser = user?.premium_subscription_subscribed;

  const [isModalOpen, setOpenREIHub] = useOpenREIHub({
    isDemoMode: !user.reihub_subscription_subscribed,
  });

  const loginToReiHub = async () => {
    if (user.reihub_subscription_subscribed) {
      return setOpenREIHub();
    }

    return null;
  };

  const OWNER_NAV_ITEMS = {
    '': [
      {
        label: 'Dashboard',
        id: 'dashboard',
        isActiveFilter: 'owners/dashboard',
        to: `${config.PRIVATE_BASE_PATH}dashboard`,
        Icon: DashboardIcon,
        onClick: () => {
          segmentTracking('dashboard clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Messages',
        id: 'messages',
        isActiveFilter: 'owners/messages',
        to: `${config.PRIVATE_BASE_PATH}messages`,
        Icon: MessagesIcon,
        onClick: () => {
          segmentTracking('messages clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Properties',
        id: 'properties',
        isActiveFilter: 'owners/properties',
        to: `${config.PRIVATE_BASE_PATH}properties`,
        Icon: PropertyIcon,
        onClick: () => {
          segmentTracking('properties clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Maintenance',
        id: 'maintenance',
        isActiveFilter: 'owners/maintenance',
        to: `${config.PRIVATE_BASE_PATH}maintenance/maintenance-requests`,
        Icon: MaintenanceIcon,
        onClick: () => {
          segmentTracking('maintenance clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Leases',
        id: 'leases',
        isActiveFilter: 'owners/leases',
        to: `${config.PRIVATE_BASE_PATH}leases`,
        Icon: LeasesIcon,
        onClick: () => {
          segmentTracking('leases clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Forms',
        id: 'forms',
        isActiveFilter: 'owners/forms',
        to: `${config.PRIVATE_BASE_PATH}forms`,
        Icon: FormIcon,
        onClick: () => {
          segmentTracking('forms clicked', {
            location: 'Nav Bar',
          });
        },
      },
    ],
    'renters': [
      {
        label: 'Leads',
        id: 'leads',
        isActiveFilter: 'owners/renters/leads',
        to: `${config.PRIVATE_BASE_PATH}renters/leads`,
        Icon: LeadsIcon,
        onClick: () => {
          segmentTracking('leads clicked', {
            location: 'Nav Bar',
          });
        },
      },

      {
        label: 'Applicants',
        id: 'applicants',
        isActiveFilter: 'owners/renters/applicants',
        to: `${config.PRIVATE_BASE_PATH}renters/applicants`,
        Icon: ApplicantIcon,
        onClick: () => {
          segmentTracking('applicants clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Tenants',
        id: 'tenants',
        isActiveFilter: 'owners/renters/tenants',
        to: `${config.PRIVATE_BASE_PATH}renters/tenants`,
        Icon: TenantsIcon,
        onClick: () => {
          segmentTracking('tenants clicked', {
            location: 'Nav Bar',
          });
        },
      },
    ],
    'financial': [
      {
        label: 'Payments',
        id: 'payments',
        isActiveFilter: 'owners/payments',
        isInactiveFilter: 'payments/expenses,payments/accounting',
        to: `${config.PRIVATE_BASE_PATH}payments`,
        Icon: PaymentsIcon,
        onClick: () => {
          segmentTracking('payments clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Expenses',
        id: 'expenses',
        isActiveFilter: 'owners/payments/expenses',
        to: `${config.PRIVATE_BASE_PATH}payments/expenses`,
        Icon: ExpensesIcon,
        onClick: () => {
          segmentTracking('expenses clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Accounting',
        id: 'accounting',
        Icon: AccountingIcon,
        ...(user.reihub_subscription_subscribed
          ? {}
          : {
              isActiveFilter: 'owners/payments/accounting',
              to: `${config.PRIVATE_BASE_PATH}payments/accounting`,
            }),
        onClick: () => {
          segmentTracking('accounting clicked', {
            location: 'Nav Bar',
          });
          loginToReiHub();
        },
      },
    ],
    'RESOURCES': [
      {
        label: 'Toolbox',
        id: 'toolbox',
        isActiveFilter: 'toolbox',
        to: config.PRIVATE_BASE_PATH + 'toolbox',
        Icon: ToolboxNav,
        onClick: () => {
          segmentTracking('toolbox clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Education',
        id: 'academy',
        isActiveFilter: 'academy',
        to: `${config.PRIVATE_BASE_PATH}academy`,
        Icon: Academy,
        onClick: () => {
          segmentTracking('education clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Need Help?',
        id: 'help',
        isActiveFilter: 'owners/help_center',
        to: `${config.PRIVATE_BASE_PATH}help_center`,
        Icon: HelpIcon,
        onClick: () => {
          segmentTracking('need help clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: isPremiumUser ? 'Referrals' : 'Give $25. Get $25.',
        id: 'referrals',
        isActiveFilter: 'owners/referrals',
        to: `${config.PRIVATE_BASE_PATH}referrals`,
        Icon: GiftIcon,
        onClick: () => {
          segmentTracking('referrals clicked', {
            location: 'Nav Bar',
          });
        },
      },
    ],
  };

  const OWNER_BOTTOM_NAV_ITEMS = [
    {
      label: 'Account',
      id: 'account',
      isActiveFilter: 'owners/settings',
      subMenuRef: {
        ref: accountSubItemsRef,
        itemRef: accountItemRef,
      },
      Icon: AccountIcon,
      SecondaryIcon: Bullet,
      SecondaryIconProps: {
        color: colors.white,
      },
      subItems: [
        {
          label: 'Settings',
          id: 'settings',
          to: `${config.PRIVATE_BASE_PATH}settings`,
          Icon: SettingsIcon,
          onClick: () => {
            segmentTracking('settings clicked', {
              location: 'Nav Bar',
            });
          },
        },
        {
          label: 'Logout',
          id: 'logout',
          Icon: LogoutIcon,
          to: '/logout',
          onClick: () => {
            segmentTracking('logout clicked', {
              location: 'Nav Bar',
            });
          },
        },
      ],
    },
  ];

  const RENTER_NAV_ITEMS = {
    '': [
      {
        label: 'Dashboard',
        id: 'dashboard',
        isActiveFilter: 'renters/dashboard',
        to: `${config.PRIVATE_BASE_PATH}dashboard`,
        Icon: DashboardIcon,
      },
      {
        label: 'Messages',
        id: 'messages',
        isActiveFilter: 'renters/messages',
        to: `${config.PRIVATE_BASE_PATH}messages`,
        Icon: MessagesIcon,
        onClick: () => {
          segmentTracking('messages clicked', {
            location: 'Nav Bar',
          });
        },
      },
      {
        label: 'Toolbox',
        id: 'toolbox',
        href: 'https://www.turbotenant.com/renter-toolbox/',
        Icon: ToolboxNav,
        onClick: () => {
          segmentTracking('toolbox clicked', {
            location: 'Tenants Nav Bar',
          });
        },
      },
    ],
  };

  const RENTER_BOTTOM_NAV_ITEMS = [
    {
      label: 'Settings',
      id: 'settings',
      isActiveFilter: 'renters/settings',
      to: `${config.PRIVATE_BASE_PATH}settings`,
      Icon: SettingsIcon,
    },
    {
      label: 'Need Help?',
      id: 'help',
      isActiveFilter: 'renters/help_center',
      to: `${config.PRIVATE_BASE_PATH}help_center`,
      Icon: HelpIcon,
    },
    {
      label: 'Logout',
      id: 'logout',
      Icon: LogoutIcon,
      to: '/logout',
    },
  ];

  let navItems = {};
  let navBottomItems = [];

  if (config.IS_OWNER) {
    navItems = OWNER_NAV_ITEMS;
    navBottomItems = OWNER_BOTTOM_NAV_ITEMS;
  } else {
    navItems = RENTER_NAV_ITEMS;
    navBottomItems = RENTER_BOTTOM_NAV_ITEMS;
  }

  return (
    <>
      <DesktopNavigation
        user={user}
        userDataLoaded={userLoading}
        location={location}
        isOwner={config.IS_OWNER}
        navItems={navItems}
        navBottomItems={navBottomItems}
        unreadMessagesCount={newMessagingCount}
        isUserSubscribedToREIHub={user.reihub_subscription_subscribed}
        showSubscriptionAlert={showSubscriptionAlert}
        isPremiumUser={isPremiumUser}
      />
      <ReiHubLoadingModal open={isModalOpen} />
    </>
  );
};

export default DesktopNavigationWrapper;
