import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useDropdownMenuContext } from '../../context';

import styles from './Content.module.scss';

const Content = ({ className, children }) => {
  const { isOpen, refs, floatingStyles, getFloatingProps } =
    useDropdownMenuContext();

  if (!isOpen) return null;

  return (
    <div
      ref={refs.setFloating}
      className={cx(styles.floating, className)}
      style={floatingStyles}
      {...getFloatingProps()}
    >
      {children}
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Content;
