import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../Button';
import Card from '../Card';
import FlatButton from '../FlatButton';
import ProfilePicture from '../ProfilePicture';
import RadioGroupBox from '../RadioGroupBox';
import { Tooltip } from '../Tooltip';
import { H3, Paragraph } from '../Typography';

import styles from './ChooseAccountCard.module.scss';

const ChooseAccountCard = ({
  user: currentUser,
  users,
  onSubmit,
  onLogout,
  className,
}) => {
  const [selectedUser, setSelectedUser] = useState(users?.[0]?.id);

  const options = users.map((user) => {
    const label = user.company || `${user.first_name} ${user.last_name}`;
    return {
      value: user.id,
      label: (
        <>
          <Tooltip
            className={styles.tooltip}
            id={`account-name-tip-${user.id}`}
            place="right"
          >
            {label}
          </Tooltip>
          <div
            data-tooltip-id={`account-name-tip-${user.id}`}
            className={styles.userOption}
          >
            <ProfilePicture
              isMUA
              {...(user.company
                ? {
                    firstName: user.company,
                    profilePictureURL: user.custom_logo,
                  }
                : {
                    firstName: user.first_name,
                    lastName: user.last_name,
                  })}
              size="small"
              className={styles.profilePicture}
            />
            <Paragraph size="large" weight="semi" className={styles.name}>
              {label}
            </Paragraph>
          </div>
        </>
      ),
    };
  });

  return (
    <Card className={cx(styles.card, className)}>
      <H3 className={styles.title}>Choose an Account</H3>
      <div className={styles.infoWrapper}>
        <ProfilePicture
          firstName={currentUser.first_name}
          lastName={currentUser.last_name}
          size="xsmall"
          className={styles.profilePicture}
        />
        <Paragraph className={styles.email}>{currentUser.email}</Paragraph>
      </div>
      <RadioGroupBox
        options={options}
        className={styles.elementWrapper}
        containerClassName={styles.radioContainer}
        radioProps={{
          contentClassName: styles.radioContent,
        }}
        input={{
          value: selectedUser,
          onChange: setSelectedUser,
        }}
      />
      <Button
        className={styles.button}
        onClick={() => onSubmit(selectedUser)}
        disabled={!selectedUser}
      >
        Continue
      </Button>
      <FlatButton className={styles.flatButton} onClick={onLogout}>
        Log out
      </FlatButton>
    </Card>
  );
};

ChooseAccountCard.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }).isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      company: PropTypes.string,
      custom_logo: PropTypes.string,
      email: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default ChooseAccountCard;
