import React, { useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import reihubTTLogo from '../../../assets/reihub/reihub-tt-logos.svg';
import reiHubSsoQuery from '../../../graphql/queries/partner_sso/getReiHubSSO.graphql';
import SpiningLoader from '../../../icons/SpiningLoader';
import Modal from '../../Modal';
import { useErrorToast } from '../../Toast';

import styles from './ReiHubLoadingModal.module.scss';

const ReiHubLoadingModal = ({ open, isDemoMode }) => {
  return (
    <Modal open={open} className={styles.modal}>
      <>
        <div className={styles.header}>
          <img src={reihubTTLogo} alt={'reihub-turbotenant-logos'} />
        </div>
        <SpiningLoader style={styles.spinnerContainer} />
        {isDemoMode && (
          <>
            <h3 className={styles.modalTitle}>
              Creating a{' '}
              <span className={styles.modalTitleHighlight}>
                demo REI Hub account
              </span>{' '}
              for you to try.
            </h3>
            <p className={styles.modalMessage}>
              One moment! We will take you to REI Hub once the demo account is
              ready.
            </p>
          </>
        )}
        {isDemoMode || (
          <h3 className={styles.modalTitle}>
            Going to <span className={styles.modalTitleHighlight}>REI Hub</span>{' '}
            for accounting...
          </h3>
        )}
      </>
    </Modal>
  );
};

export const useOpenREIHub = ({ show = false, isDemoMode }) => {
  const DEFAULT_REI_HUB_URL = 'https://app.reihub.net/turbotenant';
  const [isOpen, setIsOpen] = useState(show);
  const showErrorMessage = useErrorToast();

  const openUrl = useCallback(
    (url) => {
      const newWindow = window.open(url, '_blank');
      if (!newWindow) {
        // A popup blocker prevented the window from opening
        window.location.href = url;
      }
      setIsOpen(false);
      setTimeout(() => document.activeElement.blur(), 1);
    },
    [isOpen, setIsOpen],
  );

  const [getSSOUrl] = useLazyQuery(reiHubSsoQuery, {
    //without this if you hit the query several times in a row it stucks and never returns the data
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.reiHub?.rei_hub_sso) openUrl(data?.reiHub?.rei_hub_sso);
    },
    onError: (e) => {
      try {
        const errorObj = JSON.parse(e.message);
        showErrorMessage(errorObj.message);
      } catch {
        showErrorMessage(e.message);
      }
      window.atatus &&
        window.atatus.notify({
          message: 'An error occurred while getting REI Hub SSO Url',
          data: { isDemoMode },
        });
      setIsOpen(false);
    },
  });

  const openREIHub = useCallback(() => {
    setIsOpen(true);

    const timeout = setTimeout(() => {
      if (!isDemoMode) getSSOUrl();
      else openUrl(DEFAULT_REI_HUB_URL);

      clearTimeout(timeout);
    }, 2000);
  }, [isDemoMode]);

  return [isOpen, openREIHub];
};

export default ReiHubLoadingModal;
