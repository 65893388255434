import React, { useState } from 'react';
import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Arrow from './components/Arrow';
import Content from './components/Content';
import Item from './components/Item';
import Trigger from './components/Trigger';
import { DropdownMenuContext } from './context';

import styles from './DropdownMenu.module.scss';

const DropdownMenu = ({
  className,
  children,
  placement = 'bottom-start',
  isOpen = false,
  closeOnClick = true,
}) => {
  const [open, setOpen] = useState(isOpen);
  const { refs, floatingStyles, context } = useFloating({
    open,
    whileElementsMounted: autoUpdate,
    onOpenChange: setOpen,
    middleware: [
      offset(4),
      flip({ fallbackAxisSideDirection: 'end' }),
      shift(),
    ],
    placement,
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const value = {
    isOpen: open,
    closeOnClick,
    refs,
    floatingStyles,
    context,
    getReferenceProps,
    getFloatingProps,
    setOpen,
  };

  return (
    <DropdownMenuContext.Provider value={value}>
      <div className={cx(styles.wrapper, className)}>{children}</div>
    </DropdownMenuContext.Provider>
  );
};

DropdownMenu.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  placement: PropTypes.string,
  closeOnClick: PropTypes.bool,
};

DropdownMenu.Trigger = Trigger;
DropdownMenu.Content = Content;
DropdownMenu.Item = Item;
DropdownMenu.Arrow = Arrow;

export default DropdownMenu;
