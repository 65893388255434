import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';

import styles from './Typography.module.scss';

const nonHtmlProps = [
  'italic',
  'muted',
  'color',
  'weight',
  'size',
  'underline',
  'striked',
  'uppercase',
  'ellipsis',
];
const defaultProps = {
  color: 'text',
  size: 'normal',
};
const typographyPropTypes = {
  muted: PropTypes.bool,
  color: PropTypes.oneOf([
    'text',
    'primary',
    'secondary',
    'tertiary',
    'danger',
    'green',
    'lightGrey',
    'light',
    'white',
  ]),
  weight: PropTypes.oneOf(['normal', 'semi', 'bold']),
  size: PropTypes.oneOf(['extraSmall', 'small', 'normal', 'large', 'larger']),
  align: PropTypes.oneOf([
    'left',
    'center',
    'right',
    'justify',
    'start',
    'end',
  ]),
  italic: PropTypes.bool,
  underline: PropTypes.bool,
  striked: PropTypes.bool,
  uppercase: PropTypes.bool,
  ellipsis: PropTypes.bool,
  className: PropTypes.string,
};

const propsToClassNames = (styleToUse, options) =>
  cx(styleToUse, {
    [styles.muted]: options.muted,
    [styles.italic]: options.italic,
    [styles.underline]: options.underline,
    [styles.uppercase]: options.uppercase,
    [styles.striked]: options.striked,
    [styles.ellipsis]: options.ellipsis,
    [styles[`color-${options.color}`]]: !!options.color,
    [styles[`weight-${options.weight}`]]: !!options.weight,
    [styles[`size-${options.size}`]]: !!options.size,
    [styles[`align-${options.align}`]]: !!options.align,
  });

const cleanUpNonHTMLProps = (props) => omit(props, nonHtmlProps);

export const Paragraph = ({ className, ...rest }) => {
  return (
    <p
      className={propsToClassNames([styles.text, className], rest)}
      {...cleanUpNonHTMLProps(rest)}
    />
  );
};

Paragraph.propTypes = typographyPropTypes;
Paragraph.displayName = 'Paragraph';
Paragraph.defaultProps = defaultProps;

export const Span = ({ className, ...rest }) => {
  return (
    <span
      className={propsToClassNames([styles.text, className], rest)}
      {...cleanUpNonHTMLProps(rest)}
    />
  );
};

Span.propTypes = typographyPropTypes;
Span.displayName = 'Span';
Span.defaultProps = defaultProps;

export const HTitle = ({ className, ...rest }) => (
  <h1
    className={propsToClassNames([styles.title, className], rest)}
    {...cleanUpNonHTMLProps(rest)}
  />
);
HTitle.displayName = 'Header Title';
HTitle.propTypes = typographyPropTypes;

export const H1 = ({ className, ...rest }) => (
  <h1
    className={propsToClassNames([styles.h1, className], rest)}
    {...cleanUpNonHTMLProps(rest)}
  />
);
H1.displayName = 'H1';
H1.propTypes = typographyPropTypes;

export const H2 = ({ className, ...rest }) => (
  <h2
    className={propsToClassNames([styles.h2, className], rest)}
    {...cleanUpNonHTMLProps(rest)}
  />
);
H2.displayName = 'H2';
H2.propTypes = typographyPropTypes;

export const H3 = ({ className, ...rest }) => (
  <h3
    className={propsToClassNames([styles.h3, className], rest)}
    {...cleanUpNonHTMLProps(rest)}
  />
);
H3.displayName = 'H3';
H3.propTypes = typographyPropTypes;

export const H4 = ({ className, ...rest }) => (
  <h4
    className={propsToClassNames([styles.h4, className], rest)}
    {...cleanUpNonHTMLProps(rest)}
  />
);
H4.displayName = 'H4';
H4.propTypes = typographyPropTypes;

export const H5 = ({ className, ...rest }) => (
  <h5
    className={propsToClassNames([styles.h5, className], rest)}
    {...cleanUpNonHTMLProps(rest)}
  />
);
H5.displayName = 'H5';
H5.propTypes = typographyPropTypes;

export const Link = ({ className, as: As = 'a', ...rest }) => {
  let Component = As;
  if (rest.to && As === 'a') {
    Component = RouterLink;
  }
  return (
    <Component
      className={propsToClassNames([styles.link, className], rest)}
      role="link"
      {...cleanUpNonHTMLProps(rest)}
    />
  );
};
Link.displayName = 'Link';
Link.propTypes = {
  as: PropTypes.oneOfType([PropTypes.oneOf(['a', 'span']), PropTypes.object]),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ...typographyPropTypes,
};
