import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { isSegmentAllowedRoute } from '../services/utilities';

const SegmentSetup = () => {
  const history = useHistory();

  const registerPageEvent = useCallback((pathname) => {
    if (isSegmentAllowedRoute(pathname)) {
      window.analytics && window.analytics.page();
    }
  }, []);

  useEffect(() => {
    // register first time page loads
    registerPageEvent(location.pathname);

    // register each time page change
    const unlisten = history.listen(() => {
      if (window.analytics) {
        registerPageEvent(location.pathname);
      }
    });

    return () => {
      unlisten();
    };
  }, []);

  return <></>;
};

export default SegmentSetup;
