import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import {
  profileColorTextPair,
  profilePictureColors,
} from '../../constants/colors';
import Portfolio from '../../icons/streamline/Portfolio';

import styles from './ProfilePicture.module.scss';

const calculateColor = (email) => {
  let sum = 0;
  for (const index in email) {
    sum += email.charCodeAt(index);
  }
  return sum % profilePictureColors.length;
};

const ProfilePicture = ({
  firstName,
  lastName,
  profilePictureURL,
  size,
  className,
  isMUA,
}) => {
  const backgroundColor =
    profilePictureColors[calculateColor(`${firstName} ${lastName}`)];
  const textColor = profileColorTextPair[backgroundColor];

  if (profilePictureURL)
    return (
      <img
        className={cx(
          styles.circle,
          {
            [styles.large]: size === 'large',
            [styles.medium]: size === 'medium',
            [styles.small]: size === 'small',
            [styles.xsmall]: size === 'xsmall',
          },
          className,
        )}
        alt="Profile logo"
        src={profilePictureURL}
      />
    );

  return (
    <div
      className={cx(
        styles.circle,
        {
          [styles.large]: size === 'large',
          [styles.medium]: size === 'medium',
          [styles.small]: size === 'small',
          [styles.xsmall]: size === 'xsmall',
        },
        className,
      )}
      style={{
        backgroundColor,
        color: textColor,
      }}
    >
      {isMUA ? (
        <Portfolio />
      ) : (
        <>
          {firstName ? firstName[0] : ''}
          {lastName ? lastName[0] : ''}
        </>
      )}
    </div>
  );
};

const conditionalPropType = (condition, message) => {
  if (typeof condition !== 'function')
    return new Error(
      "Wrong argument type 'condition' supplied to 'conditionalPropType'",
    );
  return function (props, propName, componentName) {
    if (condition(props, propName, componentName)) {
      return new Error(
        `Invalid prop '${propName}' '${props[propName]}' supplied to '${componentName}'. ${message}`,
      );
    }
  };
};

const oneOfStringCondition = (props, propName) =>
  (props['firstName'] === undefined &&
    props['lastName'] === undefined &&
    props['profilePictureURL'] === undefined) ||
  typeof props[propName] !== 'string';

ProfilePicture.propTypes = {
  firstName: conditionalPropType(
    oneOfStringCondition,
    'Property must be a string if properties profilePictureURL and lastName are not specified.',
  ),
  lastName: conditionalPropType(
    oneOfStringCondition,
    'Property must be a string if properties profilePictureURL and firstName are not specified.',
  ),
  profilePictureURL: conditionalPropType(
    oneOfStringCondition,
    'Property must be a string if property firstName and lastName are not specified.',
  ),
  className: PropTypes.string,
  isMUA: PropTypes.bool,
};

ProfilePicture.defaultProps = {
  profilePictureURL: '',
  size: 'medium',
};

export default ProfilePicture;
