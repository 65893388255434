import React from 'react';
import { bool, string } from 'prop-types';

import BulletIcon from '../icons/videos/control-play';
import { css, ThemedStyleSheet } from '../styles/with-styles';

const theme = ThemedStyleSheet.get();

export class ItemWithBullet extends React.Component {
  static propTypes = {
    text: string,
    hideBullets: bool,
  };
  render() {
    const { text, hideBullets } = this.props;
    return (
      <div style={{ width: '100%', marginBottom: 10 }}>
        {!hideBullets && (
          <BulletIcon
            color={theme.colors.action2}
            fill={theme.colors.action2}
            style={{ width: 8, height: 10 }}
          />
        )}
        <span
          {...css({
            ...theme.typography.defaultText,
            marginLeft: hideBullets ? 0 : 8,
          })}
        >
          {text}
        </span>
      </div>
    );
  }
}
