import React from 'react';
import PropTypes from 'prop-types';

import TurboTenantSmallLogo from '../../icons/TurboTenantSmallLogo';
import InlineAlert from '../InlineAlert';

import links from './late-fee-support-articles.json';
import LateFeeSupportArticleLink from './LateFeeSupportArticleLink';

import styles from './LateFeeNotice.module.scss';

const LateFeeNotice = ({ state, city }) => {
  if (links[state] == null) {
    return null;
  }

  return (
    <InlineAlert icon={TurboTenantSmallLogo} title="Late Fee Laws">
      <p className={styles.text}>
        If you need help in determining the appropriate late fee policy, in
        alignment with State, City, or County laws,{' '}
        <LateFeeSupportArticleLink state={state} city={city}>
          see our support article on late fees
        </LateFeeSupportArticleLink>
        .
      </p>
    </InlineAlert>
  );
};

LateFeeNotice.propTypes = {
  state: PropTypes.string,
  city: PropTypes.string,
};

export default LateFeeNotice;
