import React from 'react';
import PropTypes from 'prop-types';

import Logo from '../Logo/LogoComponent';
import { isSmallScreen } from '../styles/media-queries';
import { css, withStyles } from '../styles/with-styles';
import { ThemedStyleSheet } from '../styles/with-styles';
import SuccessNotification from '../SuccessNotification';

const ctColors = ThemedStyleSheet.get().colors;
const { typography } = ThemedStyleSheet.get();

const barStyles = {
  backgroundColor: ctColors.white,
  position: 'fixed', // or absolute
  top: 0,
  right: 0,
  width: 'inherit',
  left: 0,
  zIndex: 900,
  borderBottom: '1px solid rgb(231, 235, 240)',
};

class Header extends React.Component {
  static propTypes = {
    styles: PropTypes.object,
    pathname: PropTypes.string,
    mobile: PropTypes.bool,
    hasWhiteBackground: PropTypes.bool,
    customLogo: PropTypes.string,
    loading: PropTypes.bool,
    children: PropTypes.any,
    additionalHeader: PropTypes.object,
    onlyLogo: PropTypes.bool,
    successNotification: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);
    this.state = { stepIndex: 1 };
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowDimensionsUpdated);
  }
  componentDidMount() {
    window.addEventListener('resize', this.onWindowDimensionsUpdated);
  }

  onWindowDimensionsUpdated = () => {
    this.forceUpdate(); // all that uses the isSmallScreen - please don't copy/paste this code much. Try to use withStyles
  };

  isMobile() {
    const { mobile } = this.props;
    return mobile || isSmallScreen();
  }

  getTitleComponent = () => {
    const { loading, styles, customLogo, pathname } = this.props;
    let text;

    const inAppRoutes = [
      'applications/profile',
      'applications/demo',
      'upload-application-documents',
    ].some((route) => pathname.indexOf(route) !== -1);

    const isDummyApp = ['applications/demo'].some(
      (route) => pathname.indexOf(route) !== -1,
    );

    const mobile = this.isMobile();

    if (inAppRoutes) {
      if (isDummyApp) {
        text = (
          <span {...css(styles.dummyAppText)}>
            {mobile ? 'DEMO APPLICATION' : 'THIS IS A DEMO APPLICATION'}
          </span>
        );
      } else if (!customLogo) {
        text = (
          <span {...css(styles.secondText)}>
            Fast. Easy. Secure Rental Applications
          </span>
        );
      }
    }

    if (!loading) {
      return (
        <div>
          <Logo
            customLogo={customLogo}
            maxWidth={mobile ? 125 : 150}
            maxHeight={mobile ? 40 : 80}
          />
          {text}
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    const mobile = this.isMobile();
    const myStyle = {
      ...barStyles,
      boxShadow: 'none',
      position: mobile ? 'absolute' : 'fixed',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: mobile ? '0px 10px 0px 10px' : '0px',
    };
    const {
      styles,
      children,
      additionalHeader,
      onlyLogo,
      successNotification,
    } = this.props;
    if (mobile || this.props.hasWhiteBackground) {
      myStyle.borderBottom = `1px solid ${ctColors.lightGrey}`;
    }

    const barChildren = typeof children === 'function' ? children() : children;
    return (
      <div {...css(styles.hideOnPrint)}>
        <div style={myStyle}>
          {successNotification && (
            <SuccessNotification type={successNotification} />
          )}
          <div {...css(styles.header)}>
            {this.getTitleComponent()}
            {!onlyLogo && barChildren}
          </div>
        </div>
        {/* <AppBar
          style={myStyle}
          titleStyle={mobile ? null : { marginLeft: 50 }}
          title={this.getTitleComponent()}
          showMenuIconButton={false}
          iconStyleRight={{ margin: 0 }}
        >
          {!onlyLogo && barChildren}
        </AppBar> */}
        {!onlyLogo && additionalHeader}
      </div>
    );
  }
}

export default withStyles(({ layout, media }) => ({
  item: {
    padding: 0,
    borderLeftColor: 'black',
    borderLeftStyle: 'solid',
    borderLeftWidth: 1,
  },
  header: {
    maxWidth: 960,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
  },
  headerItemContainer: {
    height: 80,
    marginRight: 80,
    ...layout.row,
  },
  headerItemMobileContainer: { position: 'relative', top: 30 },
  hideOnPrint: {
    ...media.hideOnPrint,
    color: 'orange',
  },
  secondText: {
    ...typography.blockTitle,
    fontStyle: 'italic',
    marginLeft: '170px',
    bottom: '10px',
    position: 'absolute',
    ...media.smallScreen({
      display: 'none',
    }),
  },
  dummyAppText: {
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: '0px',
    lineHeight: '27px',
    textAlign: 'center',
    position: 'absolute',
    color: '#033A6D',
    bottom: '17px',
    right: '40%',
    ...media.smallScreen({
      right: '16px',
    }),
  },
}))(Header);
