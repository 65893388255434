import React from 'react';
import { any, bool, func, object, string } from 'prop-types';

import LoadingScreen from '../../components/LoadingScreen';
import { TextButton } from '../Button';
import BackArrow from '../icons/arrows/arrow-left-7';
import { css, withStyles } from '../styles/with-styles';
class LargeCardCenter extends React.Component {
  static propTypes = {
    styles: object,
    children: any,
    secondaryContent: object,
    topContentContainerStyles: object,
    ttBlockStyles: object,
    topContent: object,
    backButtonStyles: object,
    containerStyles: object,
    loading: bool,
    size: string,
    backButtonLabel: string,
    backButtonAction: func,
    mediumWidth: bool,
  };

  static defaultProps = {
    ttBlockStyles: {},
    backButtonStyles: {},
    containerStyles: {},
    topContentContainerStyles: {},
  };

  render() {
    const {
      mediumWidth,
      styles,
      loading,
      children,
      backButtonLabel,
      backButtonAction,
      size,
      secondaryContent,
      topContent,
      ttBlockStyles,
      topContentContainerStyles,
      backButtonStyles,
      containerStyles,
      id,
    } = this.props;
    return (
      <div
        id={id}
        {...css(
          styles.mainContainer,
          mediumWidth && styles.mediumWidth,
          containerStyles,
        )}
      >
        <div
          {...css(
            backButtonLabel
              ? styles.topContentContainer
              : styles.topContentContainerNoBack,
            topContentContainerStyles,
          )}
        >
          {backButtonLabel && (
            <TextButton
              id="back-button"
              icon={BackArrow}
              label={backButtonLabel}
              onClick={backButtonAction}
              {...css(styles.backButton, backButtonStyles)}
            />
          )}
          {topContent && (
            <div style={{ marginRight: 10, marginBottom: 15 }}>
              {topContent}
            </div>
          )}
        </div>

        <div {...css(styles.contentContainer)}>
          {secondaryContent && (
            <div {...css(styles.secondaryContentContainer)}>
              {secondaryContent}
            </div>
          )}
          <div
            {...css(
              styles.mainContentContainer,
              size === 'large' && styles.largeCardContainer,
            )}
          >
            <LoadingScreen
              loading={loading}
              style={{ minHeight: '50vh', ...ttBlockStyles }}
            >
              {children}
            </LoadingScreen>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(({ layout, media }) => ({
  ...media.hideSmall,
  mainContainer: {
    position: 'relative',
    ...layout.column,
    minHeight: '100%',
    overflow: 'auto',
    margin: 'auto',
  },
  mediumWidth: {
    ...media.mediumScreen({
      minWidth: 704,
      maxWidth: 785,
    }),
  },
  contentContainer: {
    paddingTop: 0,
    width: '100%',
    margin: 'auto',
    marginTop: 0, // dont center
    ...layout.row,
  },
  mainContentContainer: {
    flex: 1,
    margin: 'auto',
  },
  secondaryContentContainer: {
    display: 'none',
    ...media.largeScreen({
      display: 'block',
      flex: 1,
    }),
  },
  topContentContainer: {
    display: 'flex',
    ...media.smallScreen({
      justifyContent: 'space-between',
    }),
    ...media.largeScreen({
      justifyContent: 'flex-start',
    }),
  },
  topContentContainerNoBack: {
    display: 'flex',
    ...media.smallScreen({
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
    }),
    ...media.mediumScreen({
      justifyContent: 'space-around',
    }),
  },
  largeCardContainer: {
    maxWidth: '100%',
  },
  backButton: {
    width: 78,
    marginTop: 2,
    marginLeft: 10,
    marginBottom: 15,
  },
}))(LargeCardCenter);
