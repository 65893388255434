import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import injectTtAccountIdScript from '../helpers/injectTtAccountIdScript';
import updateAnchorTags from '../helpers/updateAnchorTags';

import { useConfig } from './ConfigProvider';

export const TT_ACCOUNT_ID_PARAM = 'ttAccountId';
export const TT_ACCOUNT_ID_HEADER = 'tt-account-id';

const TTAccountContext = React.createContext({});

export const useTTAccountId = () => {
  const context = useContext(TTAccountContext);
  if (!context) {
    throw new Error('useTTAccountId must be used within the provider');
  }
  return context;
};

const TTAccountProvider = ({ children }) => {
  const history = useHistory();
  const { OWNERS_URL } = useConfig();

  const [ttAccountId, setTTAccountId] = useState(
    new URLSearchParams(history?.location?.search).get(TT_ACCOUNT_ID_PARAM),
  );

  // TODO User access control
  // This provider could hold information on the user and not just the ID.
  // Maybe a "userQuery" that returns email, name and role capabilities?
  // That would allow us to both showsome information on the logged in user, and tailor the UI based on the user's role.

  useEffect(() => {
    if (ttAccountId) {
      injectTtAccountIdScript(ttAccountId, OWNERS_URL);
      updateAnchorTags(ttAccountId, OWNERS_URL);
    }

    const observer = new MutationObserver(() => {
      if (ttAccountId) {
        updateAnchorTags(ttAccountId, OWNERS_URL);
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    const unlisten = history.listen((location) => {
      if (location.pathname.indexOf('/logout') !== -1) {
        setTTAccountId(null);
        return;
      }

      const newSearchParams = new URLSearchParams(location.search);

      const destinationAccountId = newSearchParams.get(TT_ACCOUNT_ID_PARAM);

      if (ttAccountId && !destinationAccountId) {
        newSearchParams.set(TT_ACCOUNT_ID_PARAM, ttAccountId);
        return history.replace(
          `${location.pathname}?${newSearchParams.toString()}`,
        );
      }

      if (
        ttAccountId &&
        destinationAccountId &&
        ttAccountId !== destinationAccountId
      ) {
        setTTAccountId(destinationAccountId);
        newSearchParams.set(TT_ACCOUNT_ID_PARAM, destinationAccountId);
        return history.replace(
          `${location.pathname}?${newSearchParams.toString()}`,
        );
      }
    });

    return () => {
      observer.disconnect();
      unlisten();
    };
  }, [history, ttAccountId]);

  return (
    <TTAccountContext.Provider value={{ ttAccountId, setTTAccountId }}>
      {children}
    </TTAccountContext.Provider>
  );
};

export default TTAccountProvider;
