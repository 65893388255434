import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

let LeftArrow = (props) => (
  <SvgIcon {...props} viewBox={props.viewBox || '0 0 8 12'}>
    <g
      id="Landlords"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="translate(-553.000000, -1036.000000)"
    >
      <g
        id="Pagination"
        transform="translate(168.000000, 1022.000000)"
        fill="#033A6D"
        fillRule="nonzero"
      >
        <g id="Right" transform="translate(370.000000, 0.000000)">
          <path d="M15.7603665,23.8841728 L19.9446694,20 L15.7603665,16.1158272 C15.3709738,15.7543647 15.3483318,15.1456762 15.7097944,14.7562834 C15.7262564,14.7385494 15.7433817,14.7214427 15.7611336,14.705 L15.7611336,14.705 C16.1905953,14.3072111 16.8539391,14.3072111 17.2834008,14.705 L22.2079437,19.2663579 C22.6131231,19.6416552 22.6373472,20.2743567 22.2620498,20.679536 C22.2447113,20.6982551 22.2266627,20.7163036 22.2079437,20.7336421 L17.2834008,25.295 C16.8539391,25.6927889 16.1905953,25.6927889 15.7611336,25.295 L15.7611336,25.295 C15.371348,24.9339611 15.3480442,24.3252976 15.7090831,23.935512 C15.7255258,23.91776 15.7426325,23.9006348 15.7603665,23.8841728 Z"></path>
        </g>
      </g>
    </g>
  </SvgIcon>
);

LeftArrow = memo(LeftArrow);

LeftArrow.displayName = 'LeftArrow';

LeftArrow.muiName = 'SvgIcon';

export default LeftArrow;
