import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import useWindowSize from '@rooks/use-window-size';
import cx from 'classnames';
import get from 'lodash.get';

import colors from '../../constants/colors';
import { smallScreenWidth as MOBILE_BREAKPOINT } from '../../constants/media-breakpoints';
import { decodeToken, getCurrentToken } from '../../core/auth/authService';
import basicUserProfile from '../../graphql/basicUserProfile.graphql';
import Close from '../../icons/Close';
import StarFilled from '../../icons/StarFilled';
import TurboTenantSmallLogo from '../../icons/TurboTenantSmallLogo';
import { useConfig } from '../../providers/ConfigProvider';
import { segmentTracking } from '../../services/utilities';

import styles from './MobileAppBanner.module.scss';

const DISMISS_DURATION_MS = 7 * 24 * 60 * 60 * 1000; // 1 week

const MobileAppBanner = () => {
  const { IS_OWNER } = useConfig();
  const showRenterApp = !IS_OWNER;
  const [getUser, { data, loading }] = useLazyQuery(basicUserProfile);
  const user = get(data, 'userData.basicProfile.data', {});
  const [hidden, setHidden] = useState(true);
  const { innerWidth } = useWindowSize();
  const isMobile = innerWidth < MOBILE_BREAKPOINT;
  const starsNumber = 5;
  const renterDeepLink =
    'https://turbo-tenant-renter.app.link/top-renterr-dashboard-banner';
  const landlordDeepLink =
    'https://turbo-tenant.app.link/top-landlord-dashboard-banner';
  const segmentLocation = `Mobile Download Top Banner - ${
    showRenterApp ? 'Renter' : 'Landlord'
  } Dashboard`;
  const BANNER_DISMISSED_KEY = `MOBILE_APP_DASHBOARD_BANNER_${
    showRenterApp ? 'RENTER' : 'LANDLORD'
  }_${user?.id}`;

  const authToken = decodeToken(getCurrentToken());
  const isRoleAllowed = ['ADMIN', 'USER', 'OWNER', 'RENTER'].includes(
    authToken.role,
  );

  useEffect(() => {
    if (isRoleAllowed) {
      if (!user?.id && !loading) {
        getUser();
      } else if (user?.id) {
        const hasUsedApp = showRenterApp
          ? user.last_active_mobile_app
          : user.has_mobile_app;
        const dismissedTimestamp = localStorage.getItem(BANNER_DISMISSED_KEY);
        const bannerDismissedRecently =
          dismissedTimestamp &&
          Date.now() - dismissedTimestamp < DISMISS_DURATION_MS;

        if (!bannerDismissedRecently && !hasUsedApp) {
          setHidden(false);
        }
      }
    }
  }, [user, authToken]);

  const openDeepLink = () => {
    segmentTracking('get_app clicked', { location: segmentLocation });
    window.location.href = showRenterApp ? renterDeepLink : landlordDeepLink;
  };

  const onDismissBanner = () => {
    segmentTracking('dismiss clicked', { location: segmentLocation });
    localStorage.setItem(BANNER_DISMISSED_KEY, Date.now().toString());
    setHidden(true);
  };

  if (!isMobile || hidden) {
    return null;
  }

  return (
    <div
      className={cx(styles.mobileAppBanner, {
        [styles.invertedColors]: showRenterApp,
      })}
    >
      <Close
        className={styles.closeIcon}
        color={showRenterApp ? colors.greyLight : colors.darkGray}
        onClick={onDismissBanner}
      />
      <div
        className={cx(styles.logoIconWrapper, {
          [styles.invertedColors]: showRenterApp,
        })}
      >
        <TurboTenantSmallLogo
          color={showRenterApp ? colors.primary : colors.white}
          width={35}
          height={35}
        />
      </div>
      <div className={styles.textWrapper}>
        <div
          className={cx(styles.title, {
            [styles.invertedColors]: showRenterApp,
          })}
        >
          TurboTenant
        </div>
        <div
          className={cx(styles.subtitle, {
            [styles.invertedColors]: showRenterApp,
          })}
        >
          {showRenterApp ? 'Rent On The Go' : 'All-in-one Mobile App'}
        </div>
        {!showRenterApp && (
          <div className={styles.starsWrapper}>
            {Array.from({ length: starsNumber }).map((_, index) => (
              <StarFilled
                key={index}
                width={12}
                height={12}
                color={colors.labelYellow}
              />
            ))}
          </div>
        )}
      </div>
      <div
        className={cx(styles.getAppButton, {
          [styles.invertedColors]: showRenterApp,
        })}
        onClick={openDeepLink}
      >
        {showRenterApp ? 'GET THE APP' : 'GET APP'}
      </div>
    </div>
  );
};

export default MobileAppBanner;
