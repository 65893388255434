import React, { useEffect, useState } from 'react';

import { TextButton } from '../Button';
import BackArrow from '../icons/arrows/arrow-left-7';
import ForwardArrow from '../icons/arrows/arrow-right-12';
import { Column, Row } from '../Layout';
import { isSmallScreen } from '../styles/media-queries.js';
import { css, withStyles } from '../styles/with-styles';

const TabsResponsiveNewComponent = ({
  containerStyles,
  currentTab: currentTabProp,
  onlyDesktop,
  onTabChange,
  styles,
  tabs = [],
  tabsStyles,
  showBack,
}) => {
  const [currentTab, setCurrentTab] = useState(currentTabProp);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (currentTabProp !== currentTab) {
      let tab;
      const parsed = Number(currentTabProp);
      if (Number.isNaN(parsed)) {
        tab = tabs.findIndex((t) => t.id === currentTabProp);
      } else {
        tab = parsed;
      }
      if (tab < 0) {
        tab = isSmallScreen() ? undefined : 0;
      }
      if (tab !== currentTab) {
        setCurrentTab(tab);
      }
    } else if (!isSmallScreen() && currentTabProp < 0) {
      setCurrentTab(0);
    }
  }, [currentTabProp]);

  const handleTabChange = (selectedTab) => {
    const parsed = Number(selectedTab);
    let tab;
    if (Number.isNaN(parsed)) {
      tab = tabs.findIndex((t) => t.id === selectedTab);
    } else {
      tab = parsed;
    }
    if (tab < 0) {
      tab = isSmallScreen() ? undefined : 0;
    }
    setCurrentTab(tab);
    onTabChange && onTabChange(tabs[tab].id || tab, false);
  };

  const handleBack = () => {
    setCurrentTab(undefined);
    onTabChange && onTabChange('', true);
  };

  const getCurrentTabContent = () => {
    if (
      currentTab === undefined ||
      currentTab < 0 ||
      currentTab >= tabs.length
    ) {
      return <div>NO CONTENT</div>;
    }
    return tabs[currentTab].content;
  };

  const renderDesktop = () => (
    <Column flexGrow={1}>
      <Row>
        {tabs.map((tab, index) => {
          const isActive =
            index === Number(currentTab) || tab.id === currentTab;
          return (
            <Column
              key={`tab-${index}`}
              vertical="center"
              horizontal="center"
              {...css(
                styles.tab,
                tabsStyles,
                isActive ? styles.activeTab : styles.inactiveTab,
              )}
              onClick={() => handleTabChange(index)}
              id={tab.id}
            >
              {tab.title}
            </Column>
          );
        })}
      </Row>
      <div {...css(containerStyles || styles.contentContainer)}>
        {getCurrentTabContent()}
      </div>
    </Column>
  );

  const renderMobile = () => {
    const renderContent =
      currentTab !== undefined && currentTab !== -1 ? true : false;
    if (renderContent) {
      return (
        <Column {...css(styles.mobileContentContainer)}>
          {showBack !== false && (
            <TextButton
              onClick={handleBack}
              label="BACK"
              style={{ width: 78 }}
              icon={BackArrow}
            />
          )}
          <div {...css(styles.contentContainer, styles.contentMobileRow)}>
            {getCurrentTabContent()}
          </div>
        </Column>
      );
    }

    return (
      <div style={{ width: '100%' }}>
        <Column {...css(styles.mobileTabsContainer)}>
          {tabs.map((tab) => (
            <Row
              horizontal="spaced"
              vertical="center"
              {...css(styles.mobileTab, tabsStyles)}
              onClick={() => handleTabChange(tab.id)}
              key={tab.id}
              id={tab.id}
            >
              <span>{tab.title}</span>
              <ForwardArrow color="#033A6D" style={{ width: 16, height: 16 }} />
            </Row>
          ))}
        </Column>
      </div>
    );
  };

  return !onlyDesktop && isSmallScreen() ? renderMobile() : renderDesktop();
};

export const TabsResponsiveNew = withStyles(({ colors, media }) => ({
  ...media.hideXSmall,
  tab: {
    height: 51,
    minWidth: 184,
    maxWidth: 184,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '21px',
    textAlign: 'center',
    border: '1px solid #E6E9F0',
    borderBottom: 'none',
    cursor: 'pointer',
    marginBottom: -2,
    textTransform: 'uppercase',
    ...media.xSmallScreen({
      minWidth: 100,
      maxWidth: 200,
      flexGrow: 1,
    }),
  },
  activeTab: {
    color: '#033A6D',
    backgroundColor: 'white',
    zIndex: 1000,
  },
  inactiveTab: {
    color: colors.grey,
    backgroundColor: '#E6E9F0',
  },
  contentContainer: {
    border: '1px solid #E6E9F0',
    backgroundColor: 'white',
  },
  mobileContentContainer: {
    padding: 6,
    paddingTop: 0,
    width: '100%',
  },
  mobileTabsContainer: {
    width: '100%',
    marginTop: 20,
    backgroundColor: '#FFFFFF;',
    borderTop: `1px solid ${colors.lightGrey};`,
    borderRight: `1px solid ${colors.lightGrey};`,
    borderLeft: `1px solid ${colors.lightGrey};`,
  },
  contentMobileRow: {
    marginTop: 12,
  },
  mobileTab: {
    color: `${colors.primary};`,
    padding: '17px;',
    cursor: 'pointer;',
    textTransform: 'uppercase;',
    borderBottom: `1px solid ${colors.lightGrey};`,
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '21px',
  },
}))(TabsResponsiveNewComponent);
