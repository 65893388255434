import React, { forwardRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import DownArrow from '../../../../icons/DownArrow';
import { useDropdownMenuContext } from '../../context';

import styles from './Arrow.module.scss';

const Arrow = forwardRef(({ className, ...rest }, ref) => {
  const { isOpen } = useDropdownMenuContext();

  return (
    <div
      className={cx(className, {
        [styles.open]: isOpen,
      })}
      ref={ref}
      {...rest}
    >
      <DownArrow color="currentColor" className={styles.arrow} />
    </div>
  );
});

Arrow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Arrow;
