import React, { memo } from 'react';
import PropTypes from 'prop-types';

const HomesLogo = (props) => (
  <svg {...props} viewBox="0 0 116 22">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#ED8322"
      d="M13.7 4.2L8.5 10 .4 5.8v2.5l1.1.6v8.6l8.6 4.4 8.6-4.4V9.2l1.1.5V7.3l-6.1-3.1zm3.5 11.7l-7 3.5-6.5-3.2V9.9L9 12.6l5.2-5.7 2.9 1.5.1 7.5z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#ED8322"
      d="M4.1 4.3V1.8L5.6.2l2.3 1.1v2.2L6.2 5.4zM13 18.1V14l2.1-1v4zM80.7 16.8c0 .6-.5 1.1-1.1 1.1-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1.6-.1 1.1.4 1.1 1.1"
    />
    <path
      fill="#035FA7"
      d="M23.2 4.9h2.2V10h5.8V4.9h2.2v12.8h-2.2v-5.8h-5.8v5.8h-2.2zM34.5 13c0-2.8 1.7-4.8 4.6-4.8s4.6 2 4.6 4.8-1.7 4.9-4.6 4.9-4.6-2-4.6-4.9m7.3 0c0-1.6-.8-3.2-2.6-3.2s-2.6 1.7-2.6 3.2.8 3.3 2.6 3.3c1.7 0 2.6-1.7 2.6-3.3M44.9 8.5h1.9v1.3h.1c.6-.9 1.4-1.5 2.8-1.5 1.1 0 2.2.5 2.6 1.5.7-.9 1.6-1.5 2.9-1.5 1.9 0 3.1.8 3.1 3.1v6.4h-2v-5.4c0-1.5-.1-2.5-1.7-2.5-1.4 0-2 .9-2 2.5v5.4h-2v-5.9c0-1.3-.4-1.9-1.6-1.9-1.1 0-2 .8-2 2.4v5.5h-2l-.1-9.4zM61.5 13.6c0 1.4.8 2.7 2.5 2.7 1.2 0 1.9-.5 2.2-1.5h1.9c-.4 2-2.2 3.1-4.2 3.1-2.9 0-4.5-2-4.5-4.8 0-2.6 1.7-4.9 4.5-4.9 2.9 0 4.7 2.6 4.3 5.4h-6.7zm4.7-1.4c-.1-1.3-.9-2.4-2.3-2.4s-2.4 1.1-2.4 2.4h4.7zM70.8 14.7c.1 1.2 1 1.6 2.1 1.6.8 0 2.1-.2 2.1-1.2-.1-1.1-1.5-1.2-3-1.5-1.5-.3-3-.8-3-2.7 0-2 2.2-2.6 3.8-2.6 1.9 0 3.6.8 3.8 2.8h-2.1c-.2-1-1-1.2-1.9-1.2-.6-.1-1.6 0-1.6.8 0 1 1.5 1.1 3 1.5 1.5.3 3 .9 3 2.7 0 2.2-2.2 3-4.1 3-2.3 0-4-1-4.1-3.2h2zM88.8 11.4c-.3-1.4-1.1-2.2-2.6-2.2-2.2 0-3.2 2-3.2 3.9 0 2 1.1 3.9 3.2 3.9 1.4 0 2.5-1.1 2.7-2.6H90c-.3 2.2-1.7 3.6-3.8 3.6-2.8 0-4.3-2.2-4.3-4.9 0-2.6 1.5-4.9 4.3-4.9 2 0 3.5 1.1 3.7 3.1h-1.1zM99.3 13.1c0 2.6-1.5 4.9-4.3 4.9s-4.3-2.2-4.3-4.9c0-2.6 1.5-4.9 4.3-4.9 2.7 0 4.3 2.2 4.3 4.9m-7.6 0c0 2 1.1 3.9 3.2 3.9 2.2 0 3.2-2 3.2-3.9 0-2-1.1-3.9-3.2-3.9-2.1-.1-3.2 1.9-3.2 3.9M100.4 8.5h1V10h.1c.6-1.1 1.6-1.8 3-1.8 1.2 0 2.3.6 2.6 1.8.6-1.2 1.7-1.8 2.9-1.8 2 0 3 1.1 3 3.2v6.2h-1v-6.2c0-1.5-.6-2.3-2.2-2.3-1.9 0-2.5 1.6-2.5 3.3v5.2h-1.1v-6.2c0-1.2-.5-2.3-1.9-2.3-1.9 0-2.7 1.5-2.7 3.4v5.1h-1.1l-.1-9.1zM115.6 8.2c0 .6-.5 1.1-1.1 1.1-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.2-1.1.5 0 1 .5 1 1.1m-2 0c0 .5.4.9.9.9s.8-.4.8-.9-.4-.9-.9-.9c-.4 0-.8.4-.8.9m.7.6h-.3V7.7h.4c.2 0 .3 0 .4.1.1 0 .1.1.1.2s-.1.2-.2.3c.1 0 .2.1.2.3 0 .2.1.2.1.3h-.3s-.1-.1-.1-.3c0-.1-.1-.2-.2-.2h-.1v.4zm0-.6h.1c.1 0 .2 0 .2-.2 0-.1-.1-.2-.2-.2h-.1v.4z"
    />
  </svg>
);

HomesLogo.defaultProps = {
  width: 116,
  height: 22,
};

HomesLogo.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(HomesLogo);
