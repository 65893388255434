import get from 'lodash.get';

import { getActiveListingForRenter } from '../../commons/services/utilities';

import { getActiveLeaseForRenter } from './getActiveLeaseForRenter';

/**
 * This method checks if the feature should be visible for the renter based on the feature flag
 * and conditions specified by the product.
 * Later on, each section could add extra conditions to determine if that specific part of the feature
 * should be shown or not.
 * @param {*} user
 * @param {*} features
 * @returns
 */
export const shouldShowRentReporting = (user) => {
  const activeListing = getActiveListingForRenter(user);
  const relationshipType = get(activeListing, 'relationship_type', 'LEAD');
  const isTenant = (relationshipType || '').endsWith('TENANT');
  const activeLease = getActiveLeaseForRenter(user);

  return !!(
    (isTenant && activeLease?.renter_rent_payments_request) ||
    user?.has_payment_request_rules
  );
};
