import React, { memo } from 'react';
import SvgIcon from 'material-ui/SvgIcon';

let RemoveAddAddCircle1 = (props) => (
  <SvgIcon {...props}>
    <g
      stroke={props.color}
      strokeWidth={2}
      fillOpacity="0"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
    >
      <circle cx="11.5" cy="11.5" r="10.5" />
      <path d="M11.5 5.5v12M17.5 11.5h-12" />
    </g>
  </SvgIcon>
);
RemoveAddAddCircle1 = memo(RemoveAddAddCircle1);
RemoveAddAddCircle1.displayName = 'RemoveAddAddCircle1';
RemoveAddAddCircle1.muiName = 'SvgIcon';

export default RemoveAddAddCircle1;
