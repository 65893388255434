// @TODO: rename, make all this make more sense

export const action1 = '#00C8FF';
export const action2 = '#2370A3';
export const action3 = '#033A6D';
export const action3on70 = '#6a9dbf';
export const action4 = '#4caf50';

export const text1 = '#204369';

export const gray_gray = '#CECECE';
export const gray_dark = '#666';
export const gray2 = '#183956';
export const gray5 = '#F0F5F8';
export const gray6 = '#516A80';
export const gray7 = '#050615';
export const gray8 = '#677D98';
export const lightGray = '#FBF3F2';

export const info = action3;

export const headline1 = action3;

export const blackTransparent = 'rgba(0, 0, 0, 0.870588)';
export const transparent = 'rgba(0, 0, 0, 0)';

//new designs
export const primary = action3;
export const secondary = action2;
export const tertiary = action1;
export const hoveredLink = '#1c5a84';
export const white = '#FFFFFF';
export const black = '#21282A';
export const darkBlueGrey = '#042238';
export const grey = '#516A80';
export const error = '#F44336';
export const lightBlueGrey = '#ACB9C8';
export const medBlueGrey = '#516A80';
export const background = '#F5F6F8';
export const lightGrey = '#E6E9F0';
export const link = '#2370A3';
export const grey2 = '#D8D8D8';
export const grey5 = '#F5F6F8';
export const green = '#43A047';
export const lightGreen = '#66BB6A';
export const pink = '#E91E63';
export const red = '#FF3B3B';
export const modalBlueBackground = '#E7F8FD';
export const helpCardBackground = '#A9F3DF';

export const green2 = '#FF9250';

export const defaultText = darkBlueGrey;
export const lightRedBackground = '#FEEFEF';
export const darkGray = '#687A88';
export const darkGreen = '#00805F';

export const successToastGreen = '#E6F9F4';
export const warningToastOrange = '#ff9f2a';
export const warningToastOrangeBackground = '#fff8e3';
