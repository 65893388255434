import React from 'react';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import muiTheme from 'tt-commons/styles/mui.theme';

import browserHistory from '../browserHistory';
import { TTInitializer } from '../core/initialization';
import { useConfig } from '../providers/ConfigProvider';

import MainApplication from './pages/MainApplication';

import './main.scss';

const initializer = new TTInitializer();

const App = () => {
  const config = useConfig();
  const client = initializer.getApolloClient(config);

  return (
    <ApolloProvider client={client}>
      <MuiThemeProvider muiTheme={muiTheme}>
        <Router history={browserHistory}>
          <MainApplication />
        </Router>
      </MuiThemeProvider>
    </ApolloProvider>
  );
};

export default App;
